import {
  GET_DASHBOARD,GET_GUARD,SET_FILTER_TYPE
} from '../constants/';

const INIT_STATE = {
  dashboardData:[],
  supervisors: [],
  clients: [],
  districtmanagers: [],
  districts: [],
  guards: [],
  dashboardData:{},
  filterType: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD:
            return getDashboard(state, action.payload);
        case GET_GUARD:
            return getGuard(state, action.payload);
      case SET_FILTER_TYPE:
            return setFilterType(state, action.payload);
      default:
            return state;
    }

};

function getDashboard(state, payload) {
  // localStorage.setItem('access_token', payload.token);
  return {
    ...state, dashboardData: true,
  }
}

function getGuard(state,payload){
  return {
    ...state, dashboardData: true,
  }
}

function setFilterType(state,payload){
  return {
    ...state, filterType: payload,
  }
}


