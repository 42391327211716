import React, {Component }from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
    CardText,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row,Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import classnames from 'classnames';
import avatar from '../../assets/images/users/3.jpg'
// import locationAvatar from '../../assets/images/img4.jpg'
import locationAvatar from '../../assets/images/img5.jpg'
import Chart from "react-apexcharts";
import ApexPieA from '../../components/apexpie-a'
import { auth } from '../../Api';
import { getName } from 'country-list';

var tzOffset = require("tz-offset")
class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
            guardData:[],

            pastDutyData:[],
			midConfirmed:5,
            midMissed:5,
            startConfirmed:5,
            startMissed:5,
            endConfirmed:5,
            endMissed:5,
            
            data: data.makeData(),
			
            activeTab: '1',
			options: {},
			series: [44, 55, 41, 17, 15],
			labels: ['A', 'B', 'C', 'D', 'E'],
			guard:null,
			location:null,
            companyName:''
		};
		this.toggle2 = this.toggle2.bind(this);
        this.toggle = this.toggle.bind(this);
        this.alertToggle = this.alertToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount(){
		console.log("this.props.match.params:",this.props.match.params);
		const {location} = this.props.match.params
        var company_id = localStorage.getItem('companyId')
        var companyName = localStorage.getItem('companyName')
        this.setState({
            company_id:company_id,
            companyName:companyName,
            location:location
        })
        var reqguard={}
        reqguard.id=location
        
        auth.getLocation(reqguard)
            .then((res)=>{
                if(res.data.code==200){
                    this.setState({
                        guardData:res.data.message.users
                    })
                }
            })
        var req={}
        req.location_id = location
            auth.getDuty(req)
                .then((res)=>{
                    if(res.data.code==200){
                        this.setState({
                            jsonData:res.data.message.users,
                            midConfirmed:res.data.message.mid_confirmed,
                            midMissed:res.data.message.mid_missed,
                            startConfirmed:res.data.message.start_confirmed,
                            startMissed:res.data.message.start_missed,
                            endConfirmed:res.data.message.end_confirmed,
                            endMissed:res.data.message.end_missed
                        })
                    }
                })
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}
	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
    toggle2(tab) {
        if (this.state.activeTab !== tab) {
            if(tab=='2')
                {
                    var req={}
                    req.is_past=true
                    req.location_id = this.state.location
                    auth.getDuty(req)
                        .then((res)=>{
                            if(res.data.code==200){
                                this.setState({
                                    pastDutyData:res.data.message.users
                                })
                            }
                    })
            }
            this.setState({
                'activeTab': tab
              });
        }
      }
    handleChange (index){
        let {jsonData} = this.state; 
        jsonData[index].active=!jsonData[index].active
        console.log(jsonData[index].active)
        this.setState({ jsonData });
    }

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		let location = event.target.location.value;
		let age = event.target.age.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name, designation, location, age];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal
		})
	}

    displayDate(value){
        var endtime = new Date(value)
        return endtime.getFullYear()+'-'+(endtime.getMonth()+1)+'-'+endtime.getDate()
            +' '+(endtime.getHours()<10?"0"+endtime.getHours():endtime.getHours())
            + ":" + (endtime.getMinutes()<10?"0"+endtime.getMinutes():endtime.getMinutes())+":"+"00";
    }

    deleteRow () {
		let {obj} = this.state;
        let req = {}
        req.id = obj.id
		auth.deleteDuty(obj)
			.then((res)=>{
				console.log("res after delete:",res)
			})
		this.setState({
			alertModal:!this.state.alertModal
		})
		this.componentDidMount()
	}
    

	render() {
		const { obj,location,guardData,midConfirmed,midMissed,startConfirmed,
            startMissed,endConfirmed,endMissed,companyName } = this.state;
        const data2 = this.state.jsonData.map((prop, key) => {
            console.log("prop:",prop.timezone);
            if(prop.timezone!=undefined)
                console.log(tzOffset.offsetOf(prop.timezone));
			return {
				id: key,
				name: (
					<a onClick={()=>this.props.history.push('/duty-detail/'+prop.id)} style={{cursor:"pointer"}}>{prop.name}</a>
					),
                client_name: prop.client_name,
                location_name: prop.location_name,
                supervisor_name: prop.supervisor_name,
                guard_name: prop.guard_name,
                starttime: <div>{prop.timezone!=undefined? this.displayDate(parseInt(prop.starttime)):prop.timezone}</div>,
                duration: prop.duration,
                nomissedalert: prop.nomissedalert,
                statestart: prop.statestart,
                stateend: prop.stateend,
				actions: (
					<div className="text-center">
						<Switch onChange={()=>this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active} />
					</div>
				),
				edit:(
					<div className="justify-content-center">
						{/* <Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
						}}><i className="mdi mdi-lead-pencil"  style={{color:"white"}}/></Button> */}
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				),
				linkPage:(
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
							<a onClick={function(){
									console.log(this.state.open)
									this.setState({open:!this.state.open})}.bind(this)} ><i className="fas fa-plus"></i>View</a>
							</h6>
						</div>
						<div className={this.state.open? "panel-collapse": "panel-collapse panel-close"}>
							<ul className="list-group">
								<li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
								<li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-settings mr-10"></i>Districts Managers</li>
								<li className="list-group-item"><i className="icon-shield mr-10"></i>Guards</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-alert mr-10"></i>Supervisors</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>Clients</li>
								<li className="list-group-item"><i className="icon-bell mr-10"></i>Alerts Template</li>
								<li className="list-group-item"><i className="ti-align-left mr-10"></i>Duties</li>
								<li className="list-group-item"><i className="icon-chart mr-10"></i>Billing</li>
							</ul>
						</div>
					</div>
				)
			};
		});
        const data3 = this.state.pastDutyData.map((prop, key) => {
            console.log("prop:",prop.timezone);
            if(prop.timezone!=undefined)
                console.log(tzOffset.offsetOf(prop.timezone));
			return {
				id: key,
				name: (
					<a onClick={()=>this.props.history.push('/duty-detail/'+prop.id)} style={{cursor:"pointer"}}>{prop.name}</a>
					),
                client_name: prop.client_name,
                location_name: prop.location_name,
                supervisor_name: prop.supervisor_name,
                guard_name: prop.guard_name,
                starttime: <div>{prop.timezone!=undefined? this.displayDate(parseInt(prop.starttime)-tzOffset.offsetOf(prop.timezone)*60*1000):prop.timezone}</div>,
                duration: prop.duration,
                nomissedalert: prop.nomissedalert,
                statestart: prop.statestart,
                stateend: prop.stateend,
				actions: (
					<div className="text-center">
						<Switch onChange={()=>this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active} />
					</div>
				),
				edit:(
					<div className="justify-content-center">
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				)
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]
		
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		return <div>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Duty Edit</h3></ModalHeader>
				{
				obj=={}?<div></div>:<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
						<FormGroup>
							<Label for="name">Duty Name</Label>
							<Input type="text" name="name" id="name" defaultValue={obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="name">StartTime</Label>
							<Input type="text" name="name" id="name" defaultValue={obj.name} />
						</FormGroup>
                        <FormGroup>
							<Label for="name">Duration</Label>
							<Input type="text" name="name" id="name" defaultValue={obj.name} />
						</FormGroup>
						<FormGroup style={{textAlign:"center"}}>
							<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
							<Button color="secondary"className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this Duty?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary"  className="modal-btn mr-1" onClick={()=>this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
            <CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
			<Card>
				<CardTitle className="mb-0 p-3 border-bottom bg-light">
				<Row className="toolbar up-card-title">
                    <div className="b-title"><i className="mdi mdi-buffer mr-10"></i>&nbsp;General Information</div>
                    {/* <div className="b-title-end">Setup User Accounts&nbsp;<i className="mr-2 mdi mdi-account-settings"></i></div> */}
                </Row>
                <Row className="d-flex mr-0 ml-0" style={{alignItems:"center"}}>
                    {guardData.length!=0?<Row className="toolbar up-card-box">
                        <div><img src = {locationAvatar} className="img-company"></img></div>
                        <div>
                            <Row className="company-text-area">
                                <div className="company-text-key">Name</div>
                                <div className="company-text-value">{guardData[0].name}</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">Address</div>
                                <div className="company-text-value">{guardData[0].address}</div>
                            </Row>
                            
                            <Row className="company-text-area">
                                <div className="company-text-key">Active Company</div>
                                <div className="company-text-value">{guardData[0].active?"True":"False"}</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">Country</div>
                                <div className="company-text-value">{getName(guardData[0].countrycode)}</div>
                            </Row>
                        </div>
                    </Row>:<Row className="toolbar up-card-box">
                        <div><img src = {avatar} className="img-company"></img></div>
                        <div>
                            <Row className="company-text-area">
                                <div className="company-text-key">Name</div>
                                <div className="company-text-value">Trial Trial</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">Phone</div>
                                <div className="company-text-value">+1234567890</div>
                            </Row>
                            
                            <Row className="company-text-area">
                                <div className="company-text-key">Active Company</div>
                                <div className="company-text-value">True</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">Country</div>
                                <div className="company-text-value">Kenya</div>
                            </Row>
                        </div>
                    </Row>}
                    <Row>
                        <ApexPieA className="col-md-3" title="Confirmed/Missed Alert" confirmed={midConfirmed} missed={midMissed}></ApexPieA>
                        <ApexPieA className="col-md-3" title="Missed StartAlert" confirmed={startConfirmed} missed={startMissed}></ApexPieA>
                        <ApexPieA className="col-md-3" title="Missed EndAlert" confirmed={endConfirmed} missed={endMissed}></ApexPieA>
                    </Row>
                </Row>
				</CardTitle>
				<CardBody>
					<Row className="table-header">
                        <div className="table-title"><i className="ti-align-left"></i><span> Location Duties - {companyName}</span></div>
                            <div style={{ width: 400 }} className="auto-complete">
                        <ReactSearchAutocomplete
                            items={items}
                            onSearch ={handleOnSearch}
                            onSelect ={handleOnSelect}
                            onFocus ={handleOnFocus}
                            autoFocus
                        />
                        </div>
					</Row>
                    <div className="nav-header-cus">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({
                                    'active': this.state.activeTab === '1'
                                })}
                                onClick={() => {
                                    this.toggle2('1');
                                }}
                                >
                                Current/Future Duties
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({
                                    'active': this.state.activeTab === '2'
                                })}
                                onClick={() => {
                                    this.toggle2('2');
                                }}
                                >
                                Past Duties
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    
                    <TabContent activeTab={this.state.activeTab}>
                    	<TabPane tabId="1">
                            <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "name"
                                    },
									{
                                        Header: "Client",
                                        accessor: "client_name"
                                    },
									{
                                        Header: "Location",
                                        accessor: "location_name"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "guard_name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "supervisor_name"
                                    },
                                    {
                                        Header: "Start Date & Time",
                                        accessor: "starttime"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "duration"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "nomissedalert"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "statestart"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "stateend"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor:"edit",
                                        id: "row",
                                        maxWidth: 100,
                                        filterable: false,
                                        
                                    },							
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                data={data2}
                                // filterable
                                />
                        </TabPane>
                        <TabPane tabId="2">
                        	<ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "name"
                                    },
									{
                                        Header: "Client",
                                        accessor: "client_name"
                                    },
									{
                                        Header: "Location",
                                        accessor: "location_name"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "guard_name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "supervisor_name"
                                    },
                                    {
                                        Header: "Start Date & Time",
                                        accessor: "starttime"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "duration"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "nomissedalert"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "statestart"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "stateend"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor:"edit",
                                        id: "row",
                                        maxWidth: 100,
                                        filterable: false,
                                    },							
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                data={data3}
                                // filterable
                            />
						</TabPane>
					</TabContent>
					<div className="add-button">
						<a onClick={()=>this.props.history.push('/duty-edit/null')}><Button className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Duty
						</Button></a>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}
export default ReSellers;
