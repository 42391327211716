import React, { useState } from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	Button, Modal, ModalBody, Form, FormGroup, Row,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list'
// import { SelectTimezone } from 'react-select-timezone';
import ImageUploader from 'react-images-upload';
import { baseImgURL } from '../../Api/BaseApi';
import { auth } from '../../Api'
import { getName } from 'country-list';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

export function CountryFlag(props) {
	return (
		<span
			className={"flag-icon flag-icon-" + props.code}
			style={{ fontSize: props.size || "30px" }}
		/>
	);
}

export const CountryFlagSelectOption = props => {
	return (
		<components.Option {...props}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
				{props.label}
			</div>
		</components.Option>
	);
};

export const CountryFlagValueContainer = ({ children, ...props }) => {
	const code = (props.hasValue && props.getValue()[0].value) || false;

	return (
		<div style={{ display: "flex", flexGrow: 1 }}>
			{(code && <CountryFlag code={code.toLowerCase()} />) || null}
			<components.ValueContainer {...props}>
				{children}
			</components.ValueContainer>
		</div>
	);
};

const styles = {
	valueContainer: (base, state) => {
		const height = "35px";
		return { ...base, height };
	}
};

class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: data.dataTable,
			data: data.makeData(),
			treedata: data.treedata,
			options: countryList().getData(),
			nameValue: null,
			snameValue: null,
			phoneValue: "",
			timeValue: '',
			countryValue: null,
			emailValue: null,
			avatarUrl: 'avatar.jpg',
			center: {
				lat: 59.95,
				lng: 30.33
			},
			zoom: 11,
			districtLst: [],
			districtOption: null,
			managerLst: [],
			managerOption: null,
			supervisorLst: [],
			supervisorOption: null,
			clientLst: [],
			clientOption: null,
			showAlert: false,
			showResult: false,
			pictures: [],
			clientId: "null"
		};
		this.toggle = this.toggle.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	toggle() {
		this.setState({
			showAlert: !this.state.showAlert
		});
	}

	componentDidMount() {
		const { id } = this.props.match.params
		let companyId = localStorage.getItem('companyId')
		let { districtLst } = this.state
		this.setState({
			clientId: id,
			company_id: companyId
		})
		var req = {}
		req.company_id = companyId
		auth.getDistrict(req)
			.then((res) => {
				districtLst = []
				if (res.data.code == 200) {
					res.data.message.users.forEach(element => {
						districtLst.push({ value: element.id.toString(), label: element.name })
					});
					this.setState({ districtLst })
				}
			})
		if (id != "null") {
			req = {}
			req.id = id
			auth.getClient(req)
				.then((res) => {
					if (res.data.code == 200) {
						let clientData = res.data.message.users[0]
						console.log("res:", res)
						this.setState({
							avatarUrl: clientData.avatar,
							nameValue: clientData.name,
							snameValue: clientData.sname,
							phoneValue: clientData.phone,
							emailValue: clientData.email,
							poboxValue: clientData.pobox,
							countryValue: { value: clientData.countrycode, label: getName(clientData.countrycode) },
							districtOption: { value: clientData.district_id, label: clientData.districtName },
							managerOption: { value: clientData.districtmanager_id, label: clientData.managerName },
							supervisorOption: { value: clientData.supervisor_id, label: clientData.supervisorName }
						})
					}
				})
		}
	}

	changeCountry = value => {
		this.setState({ countryValue: value });
	};
	changeTimezone = value => {
		console.log(["timevalue:", value]);
		this.setState({ timeValue: value });
	}
	districtChange = (e) => {
		let { managerLst } = this.state
		this.setState({
			districtOption: e,
		})
		console.log("e.distirct:", e);
		var district_id = e.value
		var req = {}
		req.district_id = district_id

		auth.getDistrictManager(req)
			.then((res) => {
				managerLst = []
				if (res.data.code == 200) {
					res.data.message.users.forEach(element => {
						managerLst.push({ value: element.id, label: element.name })
					})
				}
				this.setState({ managerLst })
			})
	}
	managerChange = (e) => {
		let { supervisorLst } = this.state
		this.setState({
			managerOption: e,
		})
		var districtmanager_id = e.value
		var req = {}
		req.districtmanager_id = districtmanager_id

		auth.getSupervisor(req)
			.then((res) => {
				supervisorLst = []
				if (res.data.code == 200) {
					res.data.message.users.forEach(element => {
						supervisorLst.push({ value: element.id, label: element.firstname })
					})
				}
				this.setState({ supervisorLst })
			})
		console.log("e.managerID:", e)
	}
	supervisorChange = (e) => {
		this.setState({
			supervisorOption: e,
		})
	}
	handleChange = (event) => {
		console.log(["event.valu:", event.target.value, event.target.name])
		switch (event.target.name) {
			case "firstName":
				this.setState({
					nameValue: event.target.value
				})
				break;
			case "secondName":
				this.setState({
					snameValue: event.target.value
				})
				break;
			case "email":
				this.setState({
					emailValue: event.target.value
				})
				break;
			case "phone":
				this.setState({
					phoneValue: event.target.value
				})
				break;
			case "pobox":
				this.setState({
					poboxValue: event.target.value
				})
				break;
		}
	}
	onSave = (event) => {
		let { nameValue, snameValue, emailValue, phoneValue, countryValue,
			clientId,
			avatarUrl, company_id, poboxValue,
			districtOption, managerOption, supervisorOption
		} = this.state
		this.setState({ showResult: false });
		var req = {}
		req.name = nameValue
		req.sname = snameValue
		req.phone = phoneValue
		req.email = emailValue
		req.pobox = poboxValue
		req.company_id = company_id
		if (avatarUrl != "")
			req.avatar = avatarUrl
		req.countrycode = 'CN'
		if (districtOption != null) {
			req.district = districtOption.label
			req.district_id = districtOption.value
		} else {
			req.district_id = -1;
		}
		if (managerOption != null) {
			req.districtmanager_id = managerOption.value
		} else {
			req.districtmanager_id = -1;
		}
		if (supervisorOption != null) {
			req.supervisor_id = supervisorOption.value
		} else {
			req.supervisor_id = -1;
		}
		var that = this;
		let alertMessage = "";
		if (districtOption == null) {
			alertMessage = 'Please select District'
			that.setState({
				showAlert: true,
				showResult: false,
				alertMessage: alertMessage
			})
		}
		else if (supervisorOption == null) {
			alertMessage = 'Please select Supervisor'
			that.setState({
				showAlert: true,
				showResult: false,
				alertMessage: alertMessage
			})
		}
		else if (managerOption == null) {
			alertMessage = 'Please select District Manager'
			that.setState({
				showAlert: true,
				showResult: false,
				alertMessage: alertMessage
			})
		}
		else {
			if (clientId != "null") {
				req.id = clientId
				auth.updateClient(req)
					.then((res) => {
						if (res.data.code = 201) {
							console.log(["res.data.message:", res.data.message]);
							alertMessage = 'Updated Successfully'
							that.setState({
								showAlert: true,
								showResult: true,
								alertMessage: alertMessage
							})
						}
					})
			}
			else
				auth.registerClient(req)
					.then((res) => {
						console.log("auth.register's res:", res);
						if (res.data.message != null) {
							if (res.data.code == 400) {
								console.log("res.data.message.email:", res.data.message.email, res.data.message.email != null);
								if (res.data.message.email != null) {
									alertMessage += 'The email already exists.\n'
								}
								if (res.data.message.phone != null) {
									alertMessage += 'This phone number already exists\n'
								}
								console.log(["res.data.message:", res.data.message, alertMessage]);
								that.setState({
									showAlert: true,
									showResult: false,
									alertMessage: alertMessage
								})
								console.log(["res.data.message:", alertMessage, that.state.alertMessage]);
							}
							else if (res.data.code = 201) {
								console.log(["res.data.message:", res.data.message]);
								alertMessage = 'Created Successfully'
								that.setState({
									showAlert: true,
									showResult: true,
									alertMessage: alertMessage
								})
							}
						}
					});
		}
		event.preventDefault();
	}

	onDrop(picture) {
		var req = {}
		req.file = picture[0]
		console.log("req:", req);
		var that = this;
		auth.upload(req)
			.then((res) => {
				if (res.data.code == 201) {
					var avatarL = res.data.url.split("/")
					that.setState({
						avatarUrl: avatarL[avatarL.length - 1]
					})
				}
			})
		this.setState({
			pictures: this.state.pictures.concat(picture),
		});
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		this.setState({
			phoneValue: event
		})
	}

	render() {
		const { avatarUrl, districtOption, districtLst, managerLst, managerOption,
			supervisorLst, supervisorOption, clientLst, clientOption } = this.state;

		const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		}

		const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		}

		const handleOnFocus = () => {
			console.log('Focused')
		}

		return <div>
			<Card>
				<CompanyMenu></CompanyMenu>
				<Row className="toolbar up-card-title">
					<div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Client Information</div>
					{/* <a href="/duty">
                        <div className="b-title-end">View Location Duties&nbsp;<i className="ti-align-left"></i></div>
                    </a> */}
				</Row>
				<Row className="toolbar up-card-box justify-content-center">
					<div className="col-md-3" style={{ textAlign: "center" }}>
						<img src={baseImgURL + avatarUrl} className="img-company"></img>
						<Form>
							<FormGroup>
								{/* <Input type="file" name="file" id="exampleFile" onChange={this.onDrop} /> */}
								<ImageUploader
									withIcon={false}
									buttonText='Choose images'
									onChange={this.onDrop}
									imgExtension={['.jpg', '.gif', '.png', '.gif']}
									maxFileSize={5242880}
									singleImage={true}
								/>
							</FormGroup>
						</Form>
					</div>
					<form className="col-md-7" name="myForm" onSubmit={this.onSave}>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">First Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="firstName" type="text" value={this.state.nameValue} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Second Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="secondName" type="text" value={this.state.snameValue} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Phone</label>
							<div className="col-md-9">
								<PhoneInput
									placeholder="Enter phone number"
									value={this.state.phoneValue}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Email</label>
							<div className="col-md-9">
								<input className="location-text-input" name="email" type="text" value={this.state.emailValue} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Address</label>
							<div className="col-md-9">
								<input className="location-text-input" name="pobox" type="text" value={this.state.poboxValue} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label className="col-md-3 control-label">District</label>
							<div className="col-md-9">
								<Select name="district_id" options={districtLst} value={districtOption}
									onChange={this.districtChange} required />
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">District Manager</label>
							<div className="col-md-9">
								<Select options={managerLst} value={managerOption} onChange={this.managerChange}></Select>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Supervisor</label>
							<div className="col-md-9">
								<Select options={supervisorLst} value={supervisorOption} onChange={this.supervisorChange}></Select>
							</div>
						</Row>
						<div className="height-10"></div>
						<Row className="d-flex justify-content-center">
							<Button type="submit" className="saveButton">Save Change</Button>
						</Row>
						<div className="height-10"></div>
					</form>
				</Row>
			</Card>
			<Modal isOpen={this.state.showAlert} toggle={this.toggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
		</div>
	}
}

export default CompanyView;
