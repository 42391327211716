import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row
} from 'reactstrap';
class ContentModal extends React.Component{
    constructor(props) {
		super(props);
		this.state = {
		};
	}
    render(){
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalBody className="alert-body">
            <i className="mdi mdi-alert-circle ft-60"></i>
            <h2>{this.props.message01}</h2>
            <div>{this.props.message02}</div>
            <div className="height-10"></div>
            <FormGroup>
                <Button color="secondary" className="modal-btn ml-1" onClick={this.props.toggle}>Cancel</Button>
                <span>&nbsp;&nbsp;</span>
                <Button color="primary" className="modal-btn mr-1" onClick={this.props.oK}>Yes, Delete It!</Button>
            </FormGroup>
        </ModalBody>
    </Modal>
    }
}
export default ContentModal;