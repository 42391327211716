import React from 'react';
import { Card, CardBody, CardTitle, Row, Col, Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SalesSummary, EmailCampaign, ActiveVisitors, Stats, Projects, RecentComment, Chat, PartData, ActivityOverview }
    from '../../components/dashboard';
import { Doughnut, Line, Bar, Radar, Pie, Polar } from 'react-chartjs-2';
// import * as d from './chart/chartjs-data';
import * as d from '../charts/chartjs-data'
import Chart from 'react-c3-component';
import CompanyMenu from './company-menu'
import img1 from '../../assets/images/users/1.jpg';
import { locationsAreEqual } from 'history';
import { auth } from '../../Api';
import { Select } from 'antd';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
var tzOffset = require("tz-offset")

class DashboardTabMap extends React.Component {
    constructor(props) {
        super(props);
        var points = localStorage.getItem('points')
        if (points != null) {
            points = JSON.parse(points)
        } else {
            points = []
        }
        var selectType = localStorage.getItem('selectType')
        var whichCompany = localStorage.getItem('whichCompany')
        let companyId = localStorage.getItem('companyId')

        if (whichCompany != null && companyId != null) {
            if (whichCompany != companyId) {
                localStorage.setItem('whichCompany', companyId)
                selectType = 'company:company:' + companyId
                localStorage.setItem('selectType', selectType)
            } else {
                if (selectType == null) {
                    selectType = 'company:company:' + companyId
                }
            }
        }
        if (whichCompany == null && companyId != null) {
            localStorage.setItem('whichCompany', companyId)
            selectType = 'company:company:' + companyId
        }
        let refreshGuard = localStorage.getItem('refreshGuard')
        this.state = {
            color: ["#00264d", "#0066cc", "#4da6ff", "#004d99", "#0080ff", "#99ccff"],
            jsonData: {},
            markers: [],
            activeMarker: {},
            selectedPlace: {},
            showInfoWindow: false,
            selectType: selectType,
            options: [],
            activeMarker: null,
            selectedPlace: null,
            mapCenter: {
                lat: -1.052,
                lng: 37.098
            },
            points: points,
            refreshGuard: refreshGuard,
            companyId: companyId,
            selectedTab: 0
        }
    }

    mapRef = React.createRef();

    onMarkerClick = (props, marker) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showInfoWindow: true,
        });
    };

    handleMouseOver = (props, marker, e) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
        });
    };

    handleMouseOut = () => {
        this.setState({
            activeMarker: null,
            selectedPlace: null,
        });
    };

    onCloseInfoWindow = () => {
        this.setState({
            activeMarker: {},
            selectedPlace: {},
            showInfoWindow: false,
        });
    };

    onSecondCityChange = (value) => {
        console.log(value)
        this.setState({ selectType: value });
        localStorage.setItem('selectType', value);
        localStorage.setItem('whichCompany', this.state.companyId);
        var req = {}
        req.type = value.split(":")[0]
        req.id = value.split(":")[2]
        this.getGuard(req)
    };

    onWheel = (event) => {
        console.log("mouse event by wheel")
        console.log("wheel event", event)
    }

    componentDidMount() {
        let { selectType, companyId } = this.state
        var req = {}
        req.type = selectType.split(":")[0]
        req.id = selectType.split(":")[2]
        this.getGuard(req)

        var req = {}
        req.company_id = companyId

        auth.getDashboard(req)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        jsonData: res.data.message,
                        supervisors: res.data.message.supervisors,
                        clients: res.data.message.clients,
                        districtmanagers: res.data.message.districtmanagers,
                        districts: res.data.message.districts,
                        guards: res.data.message.guards,
                    })
                    var districtsOption = []
                    res.data.message.districts.forEach(element => {
                        districtsOption.push({
                            label: element.name,
                            value: 'district' + ':' + element.name + ":" + element.id,
                        })
                    });
                    var districtsCover = {
                        label: 'Districts',
                        options: districtsOption
                    }
                    var districtsManagerOption = []
                    res.data.message.districtmanagers.forEach(element => {
                        districtsManagerOption.push({
                            label: element.name,
                            value: 'districtmanager' + ':' + element.name + ":" + element.id,
                        })
                    })
                    var districtsManagerCover = {
                        label: 'DistrictManagers',
                        options: districtsManagerOption
                    }

                    var clientsOption = []
                    res.data.message.clients.forEach(element => {
                        clientsOption.push({
                            label: element.name,
                            value: 'client' + ':' + element.name + ":" + element.id,
                        })
                    });
                    var clientsCover = {
                        label: 'Clients',
                        options: clientsOption
                    }

                    var supervisorsOption = []
                    res.data.message.supervisors.forEach(element => {
                        supervisorsOption.push({
                            label: element.firstname + " " + element.secondname,
                            value: 'supervisor' + ':' + element.firstname + " " + element.secondname + ":" + element.id,
                        })
                    });
                    var supervisorsCover = {
                        label: 'Supervisors',
                        options: supervisorsOption
                    }
                    this.setState({
                        options: [
                            {
                                label: 'Company',
                                value: 'company' + ':' + "company:" + companyId
                            },
                            districtsCover,
                            districtsManagerCover,
                            clientsCover,
                            supervisorsCover
                        ]
                    })
                }
            })
    }

    getGuard(req) {
        if (this.state.refreshGuard != null) {
            window.clearTimeout(this.state.refreshGuard)
        }
        auth.getGuards(req)
            .then((res) => {
                if (res.data.message.guards.length > 0) {
                    var markers = [];
                    var points = [];
                    var currentMilli = new Date().getTime()
                    var companyInfo = JSON.parse(localStorage.getItem('companyInfo'))
                    var dutyTimeOffset = tzOffset.offsetOf(companyInfo.timezone)
                    let currentOffset = (new Date()).getTimezoneOffset()
                    let targetOffset = currentOffset - dutyTimeOffset

                    res.data.message.guards.forEach(element => {
                        if (element.locations != null) {
                            var online = currentMilli - element.locations.time > 240 * 1000 ? "red" : "blue"
                            var iconUrl = "https://laravel.mobiliis.com/public/storage/mapuser-" + online + ".png"
                            var missed_alert = "count:" + res.data.message["guards" + element.id].length.toString()
                            var label = " "
                            if (res.data.message["guards" + element.id].length > 0) {
                                var missed_alert = "count:" + res.data.message["guards" + element.id].length.toString()
                                res.data.message["guards" + element.id].map((prop, key) => {
                                    var time = new Date(parseInt(targetOffset != null ? prop.DateTime + targetOffset * 60 * 1000 : prop.DateTime))
                                    var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                                        + ":"
                                        + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
                                    missed_alert = missed_alert + "\n    " + strTime
                                })
                                label = res.data.message["guards" + element.id].length.toString()
                            }
                            markers.push({
                                id: element.id, lat: parseFloat(element.locations.latitude),
                                lng: parseFloat(element.locations.longitude),
                                title: "Name: " + element.firstname + " " + element.secondname +
                                    // "\nLocation: " + "lat--"+element.locations.latitude + " long--" + element.locations.longitude +
                                    "\nLocation: " + element.location_name +
                                    "\nSupervisor: " + element.s_fname + " " + element.s_sname +
                                    "\nMissed alert: " + missed_alert,
                                label: label,
                                email: element.email,
                                phone: element.phone,
                                iconUrl: iconUrl,
                            })
                            points.push({
                                lat: parseFloat(element.locations.latitude),
                                lng: parseFloat(element.locations.longitude)
                            })
                        }
                    })
                    var refresh = window.setTimeout(() => {
                        this.getGuard(req)
                    }, 60 * 1000)
                    localStorage.setItem("points", JSON.stringify(points))
                    localStorage.setItem('refreshGuard', refresh)
                    this.setState({
                        markers: markers,
                        points: points,
                        refreshGuard: refresh
                    })
                }
            })

    }

    onWheel = (event) => {
        console.log("mouse wheel dashboard")
        console.log("wheel dashboard", event)
    }

    render() {
        const { markers, google } = this.props;
        const { activeMarker, selectedPlace, selectedTab } = this.state;
        let { options, selectType, points } = this.state
        var bounds = new this.props.google.maps.LatLngBounds();
        if (points.length == 0) {
            points = [
                {
                    lat: -1.2359261173160285, lng: 36.88208275644852
                }, {
                    lat: -1.3502234592717233, lng: 36.75059014668507
                }]
        }
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }

        const labelSize = { width: 220 };
        const labelPadding = 8;

        return (
            <div>
                <CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
                <div className='tablink-cover'>
                    <button className="tablink" onClick={() => { this.setState({ selectedTab: 0 }) }}>Map</button>
                    <button className="tablink" onClick={() => { this.setState({ selectedTab: 1 }) }}>Duties</button>
                    <button className="tablink" onClick={() => { this.setState({ selectedTab: 2 }) }}>Graphics</button>
                    <button className="tablink" onClick={() => { this.setState({ selectedTab: 3 }) }}>Report</button>
                </div>
                <div style={{ height: 'calc(100vh - 280px)', width: '100%', position: 'relative', display: selectedTab == 0 ? 'block' : 'none' }} id="MapTab">
                    <div style={{ 'bottom': '10px', 'left': '10px', 'zIndex': '15', position: 'absolute' }}>
                        <Select
                            style={{ width: 140 }}
                            value={selectType.split(":")[1]}
                            onChange={this.onSecondCityChange}
                            options={options}
                        />
                    </div>
                    <Map
                        google={this.props.google}
                        style={{ width: "100%", margin: "auto" }}
                        className="company-map"
                        ref={this.mapRef}
                        initialCenter={{
                            lat: -1.052,
                            lng: 37.098
                        }}
                        bounds={bounds}
                        onWheel={this.wheel}
                    >
                        {this.state.markers.map((marker, index) => (

                            <Marker
                                icon={{
                                    // url: "https://laravel.mobiliis.com/public/storage/mapuser-"+marker.online==1?"blue.png":"red.png",
                                    url: marker.iconUrl,
                                    labelOrigin: new google.maps.Point(18, -10),
                                    scaledSize: new google.maps.Size(36, 36)
                                }}
                                key={index}
                                label={{
                                    text: `${marker.label}`,
                                    color: 'black',
                                    fontSize: '22px',
                                    fontWeight: '900',
                                }}
                                title={marker.title}
                                // title={{text:marker.title,className:'marker-label'}}
                                name={marker.name}
                                email={marker.email}
                                phone={marker.phone}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                onMouseover={() => { console.log("hover mouse in marker:", marker.title) }}
                                labelClass='marker-label'

                            // labelAnchor={new google.maps.Point(35, 27)}
                            />
                        ))}
                        <InfoWindow
                            marker={activeMarker}
                            visible={!!activeMarker}
                            onClose={this.handleMouseOut}
                        >
                            <div>
                                <h4>{selectedPlace && selectedPlace.name}</h4>
                                <h5>{selectedPlace && selectedPlace.email}</h5>
                                <h5>{selectedPlace && selectedPlace.phone}</h5>
                            </div>
                        </InfoWindow>
                    </Map>
                </div>
                <div style={{ height: 'calc(100vh - 280px)', width: '100%', position: 'relative', display: selectedTab == 1 ? 'block' : 'none' }} id="DutiesTab">
                    <DashboardDutiesTab />
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAKXMyJn1D2_AnCvqiAt7JKj77ZTD7N4Ys"
})(DashboardTabMap);
