import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	Button, Modal, ModalBody, FormGroup,Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import CompanyMenu from '../companies/company-menu'
import Select, {components} from 'react-select'
import countryList from 'react-select-country-list'
import TimezoneSelect,{ i18nTimezones }  from 'react-timezone-select'
import './client-location-edit.scss'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { auth } from '../../Api';
import { getName } from 'country-list';

export function CountryFlag(props) {
    return (
      <span
        className={"flag-icon flag-icon-" + props.code}
        style={{ fontSize: props.size || "30px" }}
      />
    );
  }
  
  export const CountryFlagSelectOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
          {props.label}
        </div>
      </components.Option>
    );
  };
  
  export const CountryFlagValueContainer = ({ children, ...props }) => {
    const code = (props.hasValue && props.getValue()[0].value) || false;
  
    return (
      <div style={{ display: "flex", flexGrow: 1 }}>
        {(code && <CountryFlag code={code.toLowerCase()} />) || null}
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </div>
    );
  };
  
class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			data: data.makeData(),
			treedata: data.treedata,
      options: countryList().getData(),
      timeOptions:[],
      nameValue: "",
      timeValue: '',
      countryValue:null,
      latitude:-0.28,
      longitude:37.35,
      address:'',
      zoom: 1,
      locationId:null,
      
      showAlert:false,
			showResult:false,

      timezoneLst:{...i18nTimezones},
      markers: [
        {
          title: "This location is center of Duty",
          name: "SOMA",
          position: { lat: -0.28, lng: 37.35 }
        }
      ]
		};
    this.toggle = this.toggle.bind(this);
    this.onPick = this.onPick.bind(this);
	}
  componentDidMount(){
    const {id} = this.props.match.params
    this.setState({
      locationId:id
    })
    let companyId = localStorage.getItem('companyId')
    var req = {}
		req.id = companyId
    auth.getCompany(req)
        .then((res) => {
          if (res.data.code == 200) {
            let companyData = res.data.message.users[0];
            console.log("compmnayData:", companyData)
            let timeValue = {value:companyData.timezone}
            this.setState({
              timeValue:timeValue,
            })
          }
        });

    if(id!='null'){
      let timeValue=""
      let req = {}
      req.id = id
      auth.getLocation(req)
        .then((res)=>{
          console.log("res:",res)
          let jsonData=res.data.message.users[0]
          // timeValue = {value:jsonData.timezone,label:jsonData.timezone}
          timeValue = {value:jsonData.timezone}
          this.setState({
            locationId: id,
            nameValue:jsonData.name,
            address:jsonData.address,
            latitude:jsonData.latitude,
            longitude:jsonData.longitude,
            countryValue:{value:jsonData.countrycode,label:getName(jsonData.countrycode)},
            timeValue:timeValue,
            markers: [
              {
                title: "This location is center of Duty",
                name: "HOME",
                position: { lat: jsonData.latitude, lng: jsonData.longitude}
              }
            ]
          })
        })
    }

  }

    onPick(t, map, coord) {
      
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.setState({
        latitude:lat,
        longitude:lng
      })

      this.setState(previousState => {
        return {
          markers: [
            // ...previousState.markers,
            {
              title: "",
              name: "",
              position: { lat, lng }
            }
          ]
        };
      });
    }

    toggle() {
		this.setState({
		  showAlert: !this.state.showAlert
		});
	  }

    changeCountry = value => {
      console.log("countryValue:",value);
        this.setState({
          countryValue: value,
      });
    };
    changeTimezone = value =>{
      console.log("timezone:",value)
      this.setState({timeValue:value});
    }
    onSave=(event)=>{
      let {nameValue,address,latitude,longitude,timeValue,locationId} = this.state
      var client_id = localStorage.getItem('clientId')
      var req = {}
      req.name = nameValue
      req.address = address
      req.latitude = latitude.toString()
      req.longitude = longitude.toString()
      req.countrycode = 'CN'
      req.timezone = timeValue.value
      req.client_id = client_id
      var that = this;
      let alertMessage = "";
      if(locationId=="null")
      auth.registerLocation(req)
        .then((res)=>{
          console.log("res:",res);
          if(res.data.message!=null){
            if(res.data.code==400)
            {
              if(res.data.message.name!=null){
              alertMessage+='The name already registered.\n'
              }
              if(res.data.message.phone!=null){
              alertMessage+='This phone number already exists\n'
              }
              that.setState({
              showAlert:true,
              showResult:false,
              alertMessage:alertMessage
              })
            }
            else if(res.data.code=201)
            {
              alertMessage='Created Successfully'
              that.setState({
              showAlert:true,
              showResult:true,
              alertMessage:alertMessage
            })
            }
          }
        });
      else 
        {
          req.id = locationId
          auth.updateLocation(req)
            .then((res)=>{
              console.log(res)
              that.setState({
                showAlert:true,
                showResult:false,
                alertMessage:"Updated Successfully"
                })
            })
        }
      event.preventDefault();
    }
    
    handleChange = (event)=>{
      console.log(["event.valu:",event.target.value, event.target.name])
      switch(event.target.name){
          case "name":
          this.setState({
          nameValue:event.target.value
          })
          break;
        case "address":
          this.setState({
          address:event.target.value
          })
          break;
        case "latitude":
          this.setState({
          latitude:event.target.value
          })
          break;
        case "longitude":
          this.setState({
          longitude:event.target.value
          })
          break;
        }
      }

	render() {
		const { latitude,longitude,address,locationId } = this.state;
    
		return <div>
			<Card>
          <CompanyMenu></CompanyMenu>
          <Row className="toolbar up-card-title">
              <div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Location Details</div>
              {locationId!="null"?
                <a onClick={()=>this.props.history.push('/duty-location/'+locationId)}>
                <div className="b-title-end">View Location Duties&nbsp;<i className="ti-align-left"></i></div>
              </a>:<div></div>}
          </Row>
          <Row className="toolbar up-card-box">
              <div className="col-md-5">
                  <form name="myForm" onSubmit={this.onSave}>
                      <Row className="company-text-area">
                        <label htmlFor="address" className="col-md-3 control-label">Name</label>
                          <div className="col-md-9">
                              <input className="location-text-input" name="name" type="text" value={this.state.nameValue} onChange={this.handleChange} required></input>
                          </div>
                      </Row>
                      <Row className="company-text-area">
                        <label htmlFor="address" className="col-md-3 control-label">timezone</label>
                          <div className="col-md-9">
                            <TimezoneSelect
                                value={this.state.timeValue}
                                // onChange={(value) => this.changeTimezone(value)}
                              />
                          </div>
                      </Row>
                      <Row className="company-text-area">
                        <label htmlFor="address" className="col-sm-3 control-label">Address</label>
                        <div className="col-md-9">
                            <textarea rows="3" className="form-control" id="address" name="address" value = {address} onChange={this.handleChange} ></textarea>
                        </div>
                      </Row>
                      <Row className="company-text-area">
                        <label htmlFor="address" className="col-md-3 control-label">Latitude</label>
                          <div className="col-md-9">
                              <input className="location-text-input" type="text" name="latitude" value= {latitude} onChange={this.handleChange} required></input>
                          </div>
                      </Row>
                      <Row className="company-text-area">
                        <label htmlFor="address" className="col-md-3 control-label">Longitude</label>
                          <div className="col-md-9">
                              <input className="location-text-input" name="longitude" type="text" value= {longitude} onChange={this.handleChange} required></input>
                          </div>
                      </Row>
                      <div className="height-10"></div>
                      <Row className="d-flex justify-content-center">
                        <Button type = "submit" className = "saveButton">Save Change</Button>
                      </Row>
                  </form>
              </div>
              <div className="col-md-7 pd-20">
              <div style={{ height: '500px', width: '100%' ,position:'relative'}}>
                <Map
                  google={this.props.google}
                  style={{ width: "90%", margin: "auto" }}
                  className={"map"}
                  zoom={6}
                  center={{
                    lat:latitude,
                    lng:longitude
                  }}
                  onClick={this.onPick}
                >
                  {this.state.markers.map((marker, index) => (
                    <Marker
                      key={index}
                      title={marker.title}
                      name={marker.name}
                      position={marker.position}
                    />
                  ))}
                </Map>
              </div>
              </div>
          </Row>
      </Card>
      <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
			{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{width:'100px'}} className="ml-1" onClick={this.toggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{width:'100px'}} onClick={this.toggle}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
           
		</div>
	}
}

export default GoogleApiWrapper({
  apiKey:"AIzaSyAKXMyJn1D2_AnCvqiAt7JKj77ZTD7N4Ys"
})(CompanyView);
