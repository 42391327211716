import React from 'react';
import { db } from '../../firebase';
import validators from './validators';
import {
  Input,
  CustomInput,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
  Button,Modal,ModalBody
} from 'reactstrap';
import img2 from '../../assets/images/big/auth-bg.jpg';
import './style.scss'
import { auth } from '../../Api'
import Loading from '../ui-components/loading'

const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
};

class Register2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      error: null,
      showAlert:false,
      alertMessage:"",
      loading:false
    };
    this.validators = validators;
    this.onInputChange = this.onInputChange.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.formValidators = this.formValidators.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
		this.setState({
		  showAlert: !this.state.showAlert
		});
	  }
  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    this.formValidators([event.target.name], event.target.value);
  }
  formValidators(fieldName, value) {
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach(rule => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === 'function') {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }
  isFormValid() {
    let status = true;
    Object.keys(this.validators).forEach(field => {
      if (!this.validators['email'].valid) {
        status = false;
      }
    });
    return status;
  }
  showErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = '';
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });

      return <div className="error mb-2">{errors}</div>;
    }
    return result;
  }
  doRegister(event) {
    const { username, email, password } = this.state;
    auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState(() => ({
              username: username,
              email: email,
              password: password
            }));
            this.props.history.push('/authentication/login2');
          })
          .catch(error => {
            alert('Something went wrong!');
          });
      })
      .catch(error => {
        alert('Something went wrong!');
      });
    event.preventDefault();
  }

  sendMail=(event)=>{
    let { email } = this.state;
    var that = this
    var req = {}
    req.email = email
    this.setState({loading:true})
    auth.forgotPassword(req)
      .then(res => {
          console.log(res.data)
          if(res.data.code==201){
            that.setState({
              loading:false,
              showAlert:true,
              alertMessage:"Please check MailBox"
            })
          }    
          else
          that.setState({
            loading:false,
            showAlert:true,
            alertMessage:"Email doesnt' register for this site.\nPlease contact support team"
          })
      })
      .catch(error => {
        alert('Something went wrong!');
      });
    event.preventDefault();
  }
  render() {
    let {loading} = this.state
    return (
      <div
        className="auth-wrapper  align-items-center d-flex"
        style={sidebarBackground}
      >
        {/*--------------------------------------------------------------------------------*/}
        {/*Login2 Cards*/}
        {/*--------------------------------------------------------------------------------*/}
        <div className="container">
          
            <div>
            <Row className="no-gutters justify-content-center">
              <Col md="6" lg="6" className="bg-dark text-white align-center">
                <div className="p-4">
                  <h2 className="display-5">
                    <span className="text-cyan font-bold">Thanks using Our Site</span>
                  </h2>
                  <p className="op-5 mt-4">
                    To reset password, you have to input email address input field and click "Send Email" button.
                  </p>
                  <p className="op-5 mt-4">
                    If you don't login until now, You can login using default password : 123456.
                  </p>
                </div>
              </Col>
              <Col md="6" lg="6" className="bg-white align-center">
                <div className="p-4 mt-5 mb-5">
                  <h3 className="font-medium mb-3" style={{textAlign:"center"}}>Reset Password</h3>
                  <Form className="mt-3" id="loginform"  onSubmit={e => e.preventDefault()}>
                    <FormGroup className="mb-3" style={{width:"300px"}}>
                      {/* <Label for="email" className="font-medium">
                        Email
                      </Label> */}
                      <Input
                        type="email"
                        value={this.state.email}
                        onChange={this.onInputChange}
                        name="email"
                        id="email"
                        placeholder="Email"
                        bsSize="lg"
                      />
                    </FormGroup>
                    {this.showErrors('email')}
                    <Row className="mb-3 mt-3">
                      <Col xs="12" className="align-center">
                        {loading?Loading({type:"balls",color:"#80bfff",height:50,width:300}):<Button
                          onClick={this.sendMail}
                          className={`text-uppercase ${
                            this.isFormValid() ? '' : 'disabled'
                            }`}
                          color="primary"
                          size="lg"
                          block
                          type="submit"
                        >
                          Send Email
                        </Button>}
                      </Col>
                    </Row>
                    <div className="text-center">
                      
                      <a
                        onClick={()=>{this.props.history.push("/authentication/login2")}}
                        className="text-info ml-1" style={{cursor:"pointer"}}
                      >
                        <b>Sign In</b>
                      </a>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          
        </div>
        <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{width:'100px'}} className="ml-1" onClick={this.toggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{width:'100px'}} onClick={this.toggle}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
      </div>
    );
  }
}

export default Register2;
