import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import {withRouter, Link} from "react-router-dom"
import { auth } from '../../Api'
import { AutoComplete, Input as AInput } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			originData: [],
			data: data.makeData(),
			treedata: data.treedata,
			companyName:''
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')
		var req={}
		req.company_id = companyId 
		auth.getDistrictManager(req)
			.then((res)=>{
				if(res.data.code==200){
					console.log("districtmanager:",res.data.message.users)
					this.setState({
						companyName:companyName,
						jsonData:res.data.message.users,
						originData: res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phonenumber = event
	}

	handleSubmit = (event) => {
		let {obj} = this.state;
		event.preventDefault();
		obj.name = event.target.name.value;
		obj.email = event.target.email.value;
		obj.phone = obj.phonenumber;
		auth.updateDistrictManager(obj)
			.then((res)=>{
				console.log("res after update:",res)
			})
		this.setState({
			modal: !this.state.modal
		})
		this.componentDidMount()
	}

	deleteRow () {
		let {obj} = this.state;
		auth.deleteDistrictManager(obj)
			.then((res)=>{
				console.log("res after delete:",res)
			})
		this.setState({
			alertModal:!this.state.alertModal
		})
		this.componentDidMount()
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.name.toLowerCase().includes(value.toLowerCase())
				|| prop.sname.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	  };

	render() {
		let { jsonData,obj,companyName } = this.state;
		const data2 = jsonData.map((prop, key) => {
			return {
				id: key,
				name: (
					<a onClick={()=>{this.props.history.push('/district-manager-info/'+prop.id)}} style={{cursor:"pointer"}}>{prop.name+" "+prop.sname}</a>
					),
				email: prop.email,
				phone: prop.phonenumber,
				age: prop[3],
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit:(
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"white"}}/></Button>
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				),
				
				linkPage:(
					<div className="panel panel-default">
						<div className="panel-heading">
							<p className="panel-title">
							<a onClick={function(){
									console.log(this.state.open)
									this.setState({open:!this.state.open})}.bind(this)} ><i className="fas fa-plus"></i>View District Manager</a>
							</p>
						</div>
						<div className={this.state.open? "panel-collapse": "panel-collapse panel-close"}>
							<ul className="list-group">
								<li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
								<li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
								
							</ul>
						</div>
					</div>
				)
			};
		});

		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Client Edit</h3></ModalHeader>
				{
				obj=={}?<div></div>:<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" defaultValue={obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="name">Email</Label>
							<Input type="text" name="email" id="email" defaultValue={obj.email} />
						</FormGroup>
						<FormGroup>
							<Label for="age">Phone</Label>
							<PhoneInput
									placeholder="Enter phone number"
									value={obj.phonenumber}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
						</FormGroup>
						<FormGroup style={{textAlign:"center"}}>
							<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
							<Button color="secondary"className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this client?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary"  className="modal-btn mr-1" onClick={()=>this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
					<div className="table-title"><i className="fas fa-users"></i><span> District Manager - {companyName}</span></div>
					<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={[]}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search"/>
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "Name",
								accessor: "name"
							},
							{
								Header: "Email",
								accessor: "email"
							},
							{
								Header: "PHONE",
								accessor: "phone"
							},
							// {
							// 	Header: "Districts",
							// 	accessor:"linkPage",
							// 	// id: "row",
							// 	maxWidth: 150,
							// 	filterable: false,
								
							// },		
							{
								Header: "",
								accessor:"edit",
								id: "row",
								maxWidth: 100,
								filterable: false,
								
							},							
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					<div className="add-button">
						<Button onClick={()=>{this.props.history.push('/district-manager-edit/null')}}  className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New District Manager
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default withRouter(ReSellers);
