import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import { auth } from "../../Api";
import { getCode, getName } from 'country-list';
import { AutoComplete, Input as AInput } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			originData: [],
			alertModal: false,
			contentModal: false,
			data: data.makeData(),
			treedata: data.treedata,
			companyName: '',
			contentMessage: "",
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.contentToggle = this.contentToggle.bind(this);
	}

	componentDidMount() {
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')

		var req = {}
		req.company_id = companyId
		auth.getDistrict(req)
			.then((res) => {
				if (res.data.code == 200) {
					console.log("supervisor:", res.data.message.users)
					this.setState({
						companyName: companyName,
						jsonData: res.data.message.users,
						originData: res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}

	contentToggle() {
		this.setState({
			contentModal: !this.state.contentModal
		});
	}

	deleteRow() {
		console.log("clicked deletedRow")
		let { obj } = this.state;
		auth.deleteDistrict(obj)
			.then((res) => {
				console.log("res after delete:", res)
				if (res.status == 200) {
					if (res.data.code == 200) {
						var index = this.state.jsonData.findIndex(x => x.id == obj.id)
						let tmpData = [...this.state.jsonData]
						if (index != -1) {
							tmpData.splice(index, 1)
						}
						this.setState({
							jsonData: tmpData,
							alertModal: false,
							contentModal: true,
							contentMessage: "Deleted the district successfully"
						})
					} else {
						this.setState({
							alertModal: false,
							contentMessage: res.data.message,
							contentModal: true
						})
					}
				} else {
					this.setState({
						alertModal: false,
						contentMessage: "Server relation error",
						contentModal: true
					})
				}

			})
		this.setState({})
	}
	
	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phone = event
	}

	handleChange = (checked) => {
		console.log("checked:", checked)
		this.setState({ checked });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let { jsonData, obj } = this.state;
		// obj.id = event.target.id.value;
		obj.name = event.target.name.value;
		obj.countrycode = getCode(event.target.country.value);
		obj.location = getCode(event.target.location.value);
		auth.updateDistrict(obj)
			.then((res) => {
				console.log("res:", res);
			})
		this.setState({
			modal: !this.state.modal
		})
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.name.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	};

	render() {
		const { data, obj, companyName } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				name: (
					<a onClick={() => { this.props.history.push('/district-info/' + prop.id) }} style={{ cursor: "pointer" }}>{prop.name}</a>
				),
				phone: prop.phone,
				location: prop.location,
				actions: (
					<div className="text-center">
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit: (
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-2" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "white" }} /></Button>
						<Button className="btn-table-remove ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
					</div>
				),
				linkPage: (
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
								<a onClick={function () {
									console.log(this.state.open)
									if (key == this.state.openKey)
										this.setState({ openKey: -1 })
									else
										this.setState({ openKey: key })
								}.bind(this)} ><i className="fas fa-plus"></i>&nbsp;View</a>
							</h6>
						</div>
						<div className={this.state.openKey == key ? "panel-collapse" : "panel-collapse panel-close"}>
							<ul className="list-group">
								{
									prop.district_manager.map((element, key) => {
										return (<li className="list-group-item" key={key}>{element.name}</li>)
									})
								}
								{/* <li className="list-group-item">Trial districtor</li>*/}
							</ul>
						</div>
					</div>
				)
			};
		});

		return <div>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Distrtict Edit</h3></ModalHeader>
				{
					obj == {} ? <div></div> : <ModalBody>
						<Form onSubmit={this.handleSubmit}>
							{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
							<FormGroup>
								<Label for="name">Distrtict Name</Label>
								<Input type="text" name="name" id="name" defaultValue={obj.name} />
							</FormGroup>
							<FormGroup>
								<Label for="location">Location</Label>
								<Input type="text" name="location" id="location" defaultValue={obj.location} />
							</FormGroup>
							<FormGroup>
								<Label for="age">Phone</Label>
								<PhoneInput
									placeholder="Enter phone number"
									value={obj.phone}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</FormGroup>
							<FormGroup style={{ textAlign: "center" }}>
								<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
								<Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this District?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={this.deleteRow}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.contentModal} toggle={this.contentToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.contentMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.contentToggle}>OK</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<CompanyMenu></CompanyMenu>
			<div className="height-10"></div>
			<Card>
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Districts - {companyName}</span></div>
						<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={[]}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search" />
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "District",
								accessor: "name"
							},
							{
								Header: "OPS Room Phone Number",
								accessor: "phone"
							},
							{
								Header: "Office Location",
								accessor: "location"
							},
							{
								Header: "District Manager",
								accessor: "linkPage",
								// id: "row",
								maxWidth: 150,
								filterable: false,
							},
							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 120,
								filterable: false,
							},
						]}
						defaultPageSize={10}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<Button onClick={() => this.props.history.push('/district-edit/null')} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Districts
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
