import React, { useEffect, useState, useRef } from 'react'
import ReactTable from "react-table"
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { Select } from 'antd';
import { auth } from '../../Api';
import classnames from 'classnames';
import { CirclesWithBar } from 'react-loader-spinner'
import { Pagination } from 'antd';

class DashboardTabDuties extends React.Component {
    constructor(props) {
        super(props);

        var periodType = localStorage.getItem('periodType')
        if (periodType == null) {
            periodType = 'month'
        }

        var selectType = localStorage.getItem('selectType')
        var whichCompany = localStorage.getItem('whichCompany')
        let companyId = localStorage.getItem('companyId')

        if (whichCompany != null && companyId != null) {
            if (whichCompany != companyId) {
                localStorage.setItem('whichCompany', companyId)
                selectType = 'company:company:' + companyId
                localStorage.setItem('periodType', selectType)
            } else {
                if (selectType == null) {
                    selectType = 'company:company:' + companyId
                }
            }
        }

        this.state = {
            selectOptions: props.selectOptions,
            periodOptions: props.periodOptions,
            activeTab: "1",
            selectType: selectType,
            companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
            periodType: periodType,
            currentGuards: [],
            pastDutyData:[],
            showTable: true,
            currentPage: 1,
            perPage: 5,
            totalCount: 500,
        }
    }

    componentDidMount() {
        let { selectType } = this.state

        console.log("select type in duty tab:", selectType)
        var req = {}
        req.type = selectType.split(":")[0]
        req.id = selectType.split(":")[2]
        this.getGuard(req)
    }

    changeTab(value){
        let {selectType, periodType,perPage} = this.state
        if(value!=this.state.activeTab){
            this.setState({activeTab:value})
            if(value=="1"){
                var req = {}
                req.type = selectType.split(":")[0]
                req.id = selectType.split(":")[2]
                this.props.setFilterType(req)
                this.getGuard(req)
            }else if(value=="2"){
                if(!this.state.pastDutyData.length>0)
                this.getPastDutyOnePage(1,perPage,selectType, periodType)
            }
        }
    }

    onSecondCityChange =(value) =>{
        let { periodType, activeTab,perPage } = this.state
        localStorage.setItem('selectType', value);
        this.setState({selectType:value})
        var req = {}
        req.type = value.split(":")[0]
        req.id = value.split(":")[2]
        if(activeTab=="1"){
            this.getGuard(req)
        }else{
            this.getPastDutyOnePage(1, perPage,value, periodType)
        }
    };

    onChangePeriod =(value) =>{

        let { selectType, perPage } = this.state
        console.log(value)
        localStorage.setItem('periodType', value);
        this.setState({ periodType: value,currentPage: 1 });
        this.getPastDutyOnePage(1, perPage,selectType, value)
    };

    changePageSize(current, size) {
        let {selectType, periodType} = this.state
        this.setState({
            perPage: size,
            showTable: false
        })
        if (current == 0) {
            this.getPastDutyOnePage(1, size,selectType, periodType)
            this.setState({ currentPage: 1 })
        } else {
            this.getPastDutyOnePage(current, size,selectType, periodType)
        }
        setTimeout(() => this.setState({ showTable: true }), 0)
    }

    getGuard(req) {
        auth.getGuards(req)
            .then((res) => {
                if (res.data.message.guards.length > 0) {
                    var guards = res.data.message.guards
                    var updateGuards=[]
                    guards.forEach(element => {
                        var missed_alert = res.data.message["guards" + element.id].length
                        element.missed_alert = missed_alert;
                        updateGuards.push(element)
                    });
                    this.setState({
                        currentGuards: updateGuards
                    })
                } else {
                    this.setState({
                        currentGuards: []
                    })
                }
            })

    }

    displayDate(value) {
        var dateValue = new Date(value)
        return dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1) + '-' + dateValue.getDate()
            + ' ' + (dateValue.getHours() < 10 ? "0" + dateValue.getHours() : dateValue.getHours()) + ":" + (dateValue.getMinutes() < 10 ? "0" + dateValue.getMinutes() : dateValue.getMinutes()) + ":" + "00";
    }

    changePagination(page, pageSize) {
        let { selectType, periodType } = this.state
        this.getPastDutyOnePage(page, pageSize,selectType, periodType)
    }

    changePageSize(current, size) {
        let { selectType, periodType } = this.state
        this.setState({
            perPage: size,
            showTable: false
        })
        if (current == 0) {
            this.getPastDutyOnePage(1, size,selectType, periodType)
            this.setState({ currentPage: 1 })
        } else {
            this.getPastDutyOnePage(current, size,selectType, periodType)
        }
        setTimeout(() => this.setState({ showTable: true }), 0)
    }

    getPastDutyOnePage(current, size,selectType, periodType ) {
        this.setState({
            loadingData: true
        })
        console.log("selecttype==+++:",selectType)
        console.log("periodType==+++:",periodType)
        var req = {}
        req.is_past = true
        req.guard_id = this.state.guard
        req.current_page = current
        req.per_page = size
        req.periodType = periodType,
        req.type = selectType.split(":")[0]
        req.id = selectType.split(":")[2]
        auth.getPastGuards(req)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        pastDutyData: res.data.message.users,
                        loadingData: false,
                        currentPage: current,
                        totalCount: res.data.message.totalCount
                    })
                }
            })
    }

    render() {

        let {
            currentGuards, pastDutyData, companyInfo, showTable, totalCount, currentPage, perPage,
            activeTab, periodOptions, selectOptions, selectType, periodType } = this.state

                    
        let date = new Date()
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone: companyInfo.timezone }));
        let locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
        let currentOffset = (new Date()).getTimezoneOffset()
        let targetOffset = currentOffset - locationOffset
        const data2 = currentGuards.map((prop, key) => {
            return {
                id: key,
                name: (
                    <a onClick={() => this.props.history.push('/duty-detail/' + prop.id)} style={{ cursor: "pointer" }}>{prop.name}</a>
                ),
                dutyname: prop.dutyname,
                phone: prop.phone,
                location_name: prop.location_name,
                supervisor_name: prop.s_fname+" " + prop.s_sname,
                guard_name: prop.firstname + " " +prop.secondname,
                starttime: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime)) : prop.timezone}</div>,
                starttime_locationzone: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime + targetOffset * 60 * 1000)) : prop.timezone}</div>,
                missed_alert: prop.missed_alert,
                statestart: prop.statestart,
                stateend: prop.stateend,
            };
        });

        const data3 = pastDutyData.map((prop, key) => {
            console.log("pastGuards.length:====",prop)
            return {
                id: key,
                name: (
                    <a onClick={() => this.props.history.push('/duty-detail/' + prop.id)} style={{ cursor: "pointer" }}>{prop.name}</a>
                ),
                dutyname: prop.name,
                phone: prop.guard_phone,
                location_name: prop.location_name,
                supervisor_name: prop.supervisor_name,
                guard_name: prop.guard_name,
                starttime: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime)) : prop.timezone}</div>,
                starttime_locationzone: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime + targetOffset * 60 * 1000)) : prop.timezone}</div>,
                missed_alert: prop.missed_alerts,
                missed_percent: <div>{Number(prop.missed_alert_percentage).toFixed(2)+"%"}</div>,
                statestart: prop.statestart,
                stateend: prop.stateend,
            };
        });

        return <div>
            <div className="nav-header-cus">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                'active': activeTab === "1"
                            })}
                            onClick={() => { this.changeTab('1'); }}>
                            Current Duties
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                'active': activeTab === "2"
                            })}
                            onClick={() => { this.changeTab('2'); }}>
                            Past Duties
                        </NavLink>
                    </NavItem>
                    <div style={{paddingTop:'8px'}}>
                    {this.state.loadingData && <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Loading
                                <CirclesWithBar
                                    height="25"
                                    width="25"
                                    radius="3"
                                    color="green"
                                    ariaLabel="loading"
                                    wrapperStyle
                                    wrapperClass
                                /></span>}
                    </div>
                    <div style={{ 'paddingLeft': '10px', position: 'absolute', top: '5px', right: '5px' }}>
                        {activeTab === "2" ?
                            <Select
                                style={{ width: 140 }}
                                value={periodType}
                                onChange={this.onChangePeriod}
                                options={periodOptions}
                            />
                            : <></>}
                        <Select
                            style={{ width: 140 }}
                            value={selectType.split(":")[1]}
                            onChange={this.onSecondCityChange}
                            options={selectOptions}
                        />
                    </div>
                </Nav>
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <ReactTable className="tab-Table"
                        columns={[
                            {
                                Header: "No",
                                id: "row",
                                maxWidth: 50,
                                filterable: false,
                                Cell: (row) => {
                                    return <div>{row.index + 1}</div>;
                                }
                            },
                            {
                                Header: "Duty Alias",
                                accessor: "dutyname"
                            },
                            {
                                Header: "Phone",
                                accessor: "phone"
                            },
                            {
                                Header: "Location",
                                accessor: "location_name"
                            },
                            {
                                Header: "Guard",
                                accessor: "guard_name"
                            },
                            {
                                Header: "Supervisor",
                                accessor: "supervisor_name"
                            },
                            {
                                Header: "Start DateTime",
                                accessor: "starttime"
                            },
                            {
                                Header: "Local Time",
                                accessor: "starttime_locationzone"
                            },
                            {
                                Header: "Missed Alerts",
                                accessor: "missed_alert"
                            }
                        ]}
                        defaultPageSize={5}
                        showPaginationBottom={true}
                        data={data2}
                    />
                </TabPane>

                <TabPane tabId="2">
                    {showTable && <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index + 1 + (currentPage - 1) * perPage}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "dutyname"
                                    },
                                    {
                                        Header: "Phone",
                                        accessor: "phone"
                                    },
                                    {
                                        Header: "Location",
                                        accessor: "location_name"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "guard_name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "supervisor_name"
                                    },
                                    {
                                        Header: "Start DateTime",
                                        accessor: "starttime"
                                    },
                                    {
                                        Header: "Local Time",
                                        accessor: "starttime_locationzone"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "missed_alert"
                                    },
                                    {
                                        Header: "Missed Percent",
                                        accessor: "missed_percent",
                                    }
                                ]}
                                defaultPageSize={perPage}
                                showPaginationBottom={false}
                                data={data3}
                            // filterable
                            />}
                    <div className='center-center mt-2 pb-2'>
                        <Pagination defaultCurrent={currentPage}
                            defaultPageSize={perPage}
                            total={totalCount}
                            pageSizeOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30]}
                            onChange={(page, pageSize) => this.changePagination(page, pageSize)}
                            onShowSizeChange={(current, size) => this.changePageSize(current, size)}
                        />
                    </div>
                </TabPane>
            </TabContent>
        </div>
    }
}

import { connect } from "react-redux";
import { setFilterType } from "../../redux/dashboard/action";

const mapStateToProps = (state, ownProps) => ({
    filterType: state.dashboard.filterType,
    selectOptions: ownProps.selectOptions
});

const mapDispatchToProps = dispatch => ({
    setFilterType: (data) => dispatch(setFilterType(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardTabDuties);