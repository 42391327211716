import React from 'react';
import { connect } from 'react-redux';
import {
	Nav,
	NavItem,
	NavLink,
	Button,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledCarousel,
	Progress,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	Label
} from 'reactstrap';
import * as data from './data';
import { withRouter, Link } from "react-router-dom";
import { logout } from "../../../redux/auth/action";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../../assets/images/logo-icon.png';
import logolighticon from '../../../assets/images/logo-light-icon.png';
import logodarktext from '../../../assets/images/logo.png';
import logoMobile from '../../../assets/images/logo-mobile.png';
import profilephoto from '../../../assets/images/users/1.jpg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from '../../../routes/router';
import Breadcrumbs from "../../../components/breadCrumb";
import { Content } from 'antd/lib/layout/layout';
import { auth } from "../../../Api";
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.state = {
			isOpen: false,
			modal: false,
			showAlert: false,
			showResult: false,
			user: null,
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.passwordAlert = this.passwordAlert.bind(this);
		this.changePassword = this.changePassword.bind(this);
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/

	componentDidMount() {
		let user = localStorage.getItem('mo_user');
		console.log("user from local:", user)
		if (user != null) {
			let tmpUser = JSON.parse(user)
			this.setState({
				user: tmpUser
			})
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	toggleModal() {
		this.setState({
			modal: !this.state.modal
		});
	}

	passwordAlert() {
		this.setState({
			showAlert: !this.state.showAlert
		});
	}

	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	sidebarHandler = () => {
		console.log("sidebarHandler============:", this.props.settings.activeSidebarType)
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};

	changePassword = (event) => {
		event.preventDefault();
		let req = {}
		req.id = localStorage.getItem('admin_id')
		req.password = event.target.password.value;
		req.password_confirmation = event.target.confirm_password.value;

		auth.changePassword(req)
			.then((res) => {
				this.setState({
					showAlert: true,
					alertMessage: res.data.code == 400 ? res.data.message.password : res.data.message
				})
			})
		this.setState({
			modal: !this.state.modal
		})


	}

	logout = () => {
		this.props.signout();
		this.props.history.push('/authentication/login2')
	}

	render() {
		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}

						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>

						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand href="/" className="justify-content-center">
							<span className="logo-text">
								<img src={logodarktext} width="100%" height="60" alt="homepage" className="dark-logo" />
								<img
									src={logodarktext}
									width="100%" height="60"
									className="light-logo"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<Nav className="float-left" navbar>
							{/* <img
								src={logoMobile}
								width="100%" height="60"
								className="light-logo"
								alt="homepage"
							/> */}
							<NavItem>
								<NavLink href="#" className="d-none d-md-block" onClick={this.sidebarHandler}>
									<i className="ti-menu" />
								</NavLink>
							</NavItem>
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Mega Menu Dropdown                                                       */}
							{/*--------------------------------------------------------------------------------*/}
							{/* <UncontrolledDropdown nav inNavbar className="mega-dropdown">
								<DropdownToggle nav> Mega <i className="fa fa-angle-down" /></DropdownToggle>
								<DropdownMenu>
									<Row>
										
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">Carousel</h5>
											<UncontrolledCarousel items={data.items} />
										</Col>
										
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">Progress</h5>
											<div className="d-flex no-block align-items-center mb-2">
												<span>Sales</span>
												<div className="ml-auto">
													<span className="text-primary">
														<i className="mdi mdi-chart-areaspline" />
													</span>
												</div>
											</div>
											<Progress className="mb-3" animated value={2 * 5} />
											<div className="d-flex no-block align-items-center mb-2">
												<span>Marketing</span>
												<div className="ml-auto">
													<span className="text-success">
														<i className="mdi mdi-chart-line" />
													</span>
												</div>
											</div>
											<Progress className="mb-3" animated color="success" value="25" />
											<div className="d-flex no-block align-items-center mb-2">
												<span>Accouting</span>
												<div className="ml-auto">
													<span className="text-danger">
														<i className="mdi mdi-chart-arc" />
													</span>
												</div>
											</div>
											<Progress className="mb-3" animated color="danger" value={50} />
											<div className="d-flex no-block align-items-center mb-2">
												<span>Sales Ratio</span>
												<div className="ml-auto">
													<span className="text-warning">
														<i className="mdi mdi-chart-pie" />
													</span>
												</div>
											</div>
											<Progress className="mb-3" animated color="warning" value={70} />
										</Col>
										
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">Contact Us</h5>
											<Form>
												<FormGroup>
													<Input
														type="text"
														name="name"
														id="textname"
														placeholder="Enter Name Here"
													/>
												</FormGroup>
												<FormGroup>
													<Input
														type="email"
														name="email"
														id="exampleEmail"
														placeholder="Enter Email Here"
													/>
												</FormGroup>
												<FormGroup>
													<Input
														type="textarea"
														name="text"
														id="exampleText"
														placeholder="Message"
													/>
												</FormGroup>
												<Button color="primary">Submit</Button>
											</Form>
										</Col>
										
										<Col xs="12" sm="12" md="12" lg="3">
											<h5 className="mb-3 text-uppercase">List Style</h5>
											<ListGroup flush>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-dark pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Cras justo odio
                        						</ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-dark pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Dapibus ac facilisis in
                        </ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-dark pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Morbi leo risus
                        </ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-dark pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Porta ac consectetur ac
                        </ListGroupItem>
												<ListGroupItem
													tag="a"
													href=""
													className="border-0 pl-0 text-dark pt-0"
												>
													<i className="fa fa-check text-success mr-2" />
													Vestibulum at eros
                        </ListGroupItem>
											</ListGroup>
										</Col>
									</Row>
								</DropdownMenu>
							</UncontrolledDropdown> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Mega Menu Dropdown                                                         */}
							{/*--------------------------------------------------------------------------------*/}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Create New Dropdown                                                      */}
							{/*--------------------------------------------------------------------------------*/}
							{/* <UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav>
									Create New <i className="fa fa-angle-down" />
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem>Option 1</DropdownItem>
									<DropdownItem>Option 2</DropdownItem>
									<DropdownItem divider />
									<DropdownItem>Reset</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Create New Dropdown                                                        */}
							{/*--------------------------------------------------------------------------------*/}
						</Nav>
						<Nav className="ml-auto float-right" navbar>
							{/* <Router>
								<Switch>
								{routes.map(({ path, name, Component }, key) => (
									<Route
										exact
										path={path}
										key={key}
										render={props => {
										const crumbs = routes
											// Get all routes that contain the current one.
											.filter(({ path }) => props.match.path.includes(path))
											// Swap out any dynamic routes with their param values.
											// E.g. "/pizza/:pizzaId" will become "/pizza/1"
											.map(({ path, ...rest }) => ({
											path: Object.keys(props.match.params).length
												? Object.keys(props.match.params).reduce(
												(path, param) => path.replace(
													`:${param}`, props.match.params[param]
												), path
												)
												: path,
											...rest
											}));
										console.log(`Generated crumbs for ${props.match.path}`);
										crumbs.map(({ name, path }) => console.log({ name, path }));
										return (
											<div className="p-8">
											<Breadcrumbs crumbs={crumbs} />
											</div>
										);
										}}
									/>
									))}
								</Switch>
							</Router> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Notifications Dropdown                                                   */}
							{/*--------------------------------------------------------------------------------*/}
							{/* <UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									<i className="mdi mdi-bell font-24" />
								</DropdownToggle>
								<DropdownMenu right className="mailbox">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
										<div className="">
											<h4 className="mb-0">4 New</h4>
											<p className="mb-0">Notifications</p>
										</div>
									</div>
									<div className="message-center notifications">
										{data.notifications.map((notification, index) => {
											return (
												<span href="" className="message-item" key={index}>
													<span className={"btn btn-circle btn-" + notification.iconbg}>
														<i className={notification.iconclass} />
													</span>
													<div className="mail-contnet">
														<h5 className="message-title">{notification.title}</h5>
														<span className="mail-desc">
															{notification.desc}
														</span>
														<span className="time">{notification.time}</span>
													</div>
												</span>
											);
										})}
									</div>
									<a className="nav-link text-center mb-1 text-dark" href=";">
										<strong>Check all notifications</strong>{' '}
										<i className="fa fa-angle-right" />
									</a>
								</DropdownMenu>
							</UncontrolledDropdown> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Notifications Dropdown                                                     */}
							{/*--------------------------------------------------------------------------------*/}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Messages Dropdown                                                        */}
							{/*--------------------------------------------------------------------------------*/}
							{/* <UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									<i className="font-24 mdi mdi-comment-processing" />
								</DropdownToggle>
								<DropdownMenu right className="mailbox">
									<span className="with-arrow">
										<span className="bg-danger" />
									</span>
									<div className="d-flex no-block align-items-center p-3 bg-danger text-white mb-2">
										<div className="">
											<h4 className="mb-0">5 New</h4>
											<p className="mb-0">Messages</p>
										</div>
									</div>
									<div className="message-center message-body">
										{data.messages.map((message, index) => {
											return (
												<span href="" className="message-item" key={index}>
													<span className="user-img">
														<img src=
															{message.image}
															alt="user"
															className="rounded-circle"
															width=""
														/>
														<span className={"profile-status pull-right " + message.status}></span>
													</span>
													<div className="mail-contnet">
														<h5 className="message-title">{message.title}</h5>
														<span className="mail-desc">{message.desc}</span>
														<span className="time">{message.time}</span>
													</div>
												</span>
											);
										})}
									</div>
									<span className="nav-link text-center link text-dark" href="">
										<b>See all e-Mails</b> <i className="fa fa-angle-right" />
									</span>
								</DropdownMenu>
							</UncontrolledDropdown> */}
							{/*--------------------------------------------------------------------------------*/}
							{/* End Messages Dropdown                                                          */}
							{/*--------------------------------------------------------------------------------*/}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Profile Dropdown                                                         */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<img
										src={profilephoto}
										alt="user"
										className="rounded-circle"
										width="31"
									/>
								</DropdownToggle>
								<DropdownMenu right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2 drop-border-radius">
										<div className="">
											<img
												src={profilephoto}
												alt="user"
												className="rounded-circle"
												width="60"
											/>
										</div>
										<div className="ml-2">
											<h4 className="mb-0">{this.state.user != null ? this.state.user.email : "Steave Jobs"}</h4>
											{/* <p className=" mb-0">{this.state.user!=null?this.state.user.email:"varun@gmail.com"}</p> */}
										</div>
									</div>
									<DropdownItem onClick={() => { this.setState({ modal: !this.state.modal }); }}>
										<i className="ti-key mr-1 ml-1" /> Change Password
									</DropdownItem>
									{/* <DropdownItem>
										<i className="ti-wallet mr-1 ml-1" /> My Balance
									</DropdownItem>
									<DropdownItem>
										<i className="ti-email mr-1 ml-1" /> Inbox
                  					</DropdownItem>
									<DropdownItem divider />
									<DropdownItem>
										<i className="ti-settings mr-1 ml-1" /> Account Settings
                  					</DropdownItem>
									<DropdownItem divider /> */}
									<DropdownItem onClick={() => this.logout()}>
										<i className="fa fa-power-off mr-1 ml-1" /> Logout
									</DropdownItem>
									<DropdownItem divider />
									{/* <Button
										color="success"
										className="btn-rounded ml-3 mb-2 mt-2">
										View Profile
                  					</Button> */}
								</DropdownMenu>
							</UncontrolledDropdown>
							{/*--------------------------------------------------------------------------------*/}
							{/* End Profile Dropdown                                                           */}
							{/*--------------------------------------------------------------------------------*/}
						</Nav>
					</Collapse>
				</Navbar>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggleModal}>
						<ModalHeader toggle={this.toggleModal}><h3>Change Password</h3></ModalHeader>
						{<ModalBody>
							<Form onSubmit={this.changePassword}>
								{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
								<FormGroup>
									<Label for="name">New Password</Label>
									<Input type="password" name="password" id="password" />
								</FormGroup>
								<FormGroup>
									<Label for="name">Confirm Password</Label>
									<Input type="password" name="confirm_password" id="confirm_password" />
								</FormGroup>
								<FormGroup style={{ textAlign: "center" }}>
									<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
									<Button color="secondary" className="modal-btn ml-1" onClick={this.toggleModal}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>}
					</Modal>
					<Modal isOpen={this.state.showAlert} toggle={this.passwordAlert}>
						<ModalBody className="alert-body">
							<i className="mdi mdi-alert-circle ft-60"></i>
							<h2>{this.state.alertMessage}</h2>
							<div className="height-10"></div>
							<FormGroup>
								<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.passwordAlert}>Cancel</Button>
								<span>&nbsp;&nbsp;</span>
								<Button color="primary" style={{ width: '100px' }} onClick={this.passwordAlert}>Ok</Button>
							</FormGroup>
						</ModalBody>
					</Modal>
				</div>
			</header>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = (dispatch) => ({
	signout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
