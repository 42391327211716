import React from "react";

import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Input,
	ListGroup,
	ListGroupItem
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

class ActivityOverview extends React.Component {

	constructor(props){
		super(props);
		console.log("props----------:",props)
		this.state = {
			validatedAlert:props.validatedAlert,
			missedAlert:props.missedAlert,
			totalAlert:props.totalAlert
		}
	}

	render() {
		let {validatedAlert,missedAlert,totalAlert} = this.state
		console.log(validatedAlert,missedAlert,totalAlert)
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody>
					<div className="d-md-flex align-items-center">
						<div>
							<CardTitle>ACTIVITY OVERVIEW</CardTitle>
						</div>
						{/* <div className="ml-auto align-items-center">
							<div className="dl">
								<Input type="select" className="custom-select">
									<option value="0">Monthly</option>
									<option value="1">Daily</option>
									<option value="2">Weekly</option>
									<option value="3">Yearly</option>
								</Input>
							</div>
						</div> */}
					</div>
					<Row className="mt-4">
						<Col lg="6">
							<div>
								<Chart
									style={{ height: '290px', width: '100%' }}
									config={{
										data: {
											columns: [
												['Validated Alerts', this.props.validatedAlert],
												['Missed Alerts', this.props.missedAlert],
												
											],

											type: 'donut',
											tooltip: {
												show: true
											}
										},
										donut: {
											label: {
												show: false
											},
											title: "Ratio",
											width: 55,
										},
										legend: {
											hide: false
										},
										color: {
											pattern: ['#2962ff','#4fc3f7']
											// pattern: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb']
										}
									}}
								/>
							</div>
						</Col>
						<Col lg="6">
							<h1 className="display-6 mb-0 font-medium">{this.props.totalAlert!=0?Math.round(this.props.validatedAlert/this.props.totalAlert*100*100)/100:0}%</h1>
							<span>Validated Ratio for Alerts</span>
							<ListGroup>
								<ListGroupItem className="border-0 mt-3 p-0"><i className="fas fa-circle mr-1 text-info font-12"></i> Validated Alerts <span className="float-right">{this.props.totalAlert!=0?Math.round(this.props.validatedAlert/this.props.totalAlert*100*100)/100:0}%</span></ListGroupItem>
								<ListGroupItem className="border-0 mt-3 p-0"><i className="fas fa-circle mr-1 text-cyan font-12"></i> Missed Alerts <span className="float-right">{this.props.totalAlert!=0?Math.round(this.props.missedAlert/this.props.totalAlert*100*100)/100:0}%</span></ListGroupItem>
							</ListGroup>
						</Col>
					</Row>
				</CardBody>
			</Card>


		);
	}
}

export default ActivityOverview;
