//apps
import Chat from '../views/chat/chat';
import Contacts from '../views/contacts/contacts';
import Email from '../views/email/email';
//dashboards
import Classic from '../views/dashboards/classic';
import Dashboard from '../views/dashboards/index';
import Cryptocurrency from '../views/dashboards/cryptocurrency';
import Ecommerce from '../views/dashboards/ecommerce';
import General from '../views/dashboards/general';


//companies

import Companies from '../views/companies/companies'
import CompanyEdit from '../views/companies/company-edit'
import CompanyView from '../views/company-view/company-view'
import CompanyDashboard from '../views/companies/company-dashboard'



//clients

import Clients from '../views/clients/clients'
import ClientInfo from '../views/clients/client-info'
import ClientEdit from '../views/clients/client-edit'
import ClientsLocation from '../views/clients/client-location'
import ClientsLocationDuty from '../views/clients/client-location-duty'
import ClientsLocationEdit from '../views/clients/client-location-edit'

//districts
import Districts from '../views/districts/districts'
import DistrictEdit from '../views/districts/district-edit'
import DistrictInfo from '../views/districts/district-info'

import DistrictManager from '../views/district-manager/district-manager'
import DistrictManagerInfo from '../views/district-manager/district-manager-info'
import DistrictManagerEdit from '../views/district-manager/district-manager-edit'
import Duty from '../views/duty/duty'
import DutyLocation from '../views/duty/duty-location'
import DutyEdit from '../views/duty/duty-edit'
import AlertTemplate from '../views/alert-template/alert-template'
import Billing from '../views/billing/billing'

//guards
import Guards from '../views/guards/guards'
import GuardEdit from '../views/guards/guard-edit'
import GuardInfo from '../views/guards/guard-info'


//ReSellers
import ReSellers from '../views/reSellers/re-sellers'
import ReSellerEdit from '../views/reSellers/reSeller-edit'

//support-contact
import SupportContact from '../views/support-contact/support-contact'

//supervisors
import Supervisors from '../views/supervisors/supervisors'
import SupervisorInfo from '../views/supervisors/supervisor-info'
import SupervisorEdit from '../views/supervisors/supervisor-edit'

//authentication
import Login from '../views/authentication/login';
import Login2 from '../views/authentication/login2';
import Register from '../views/authentication/register';
import Register2 from '../views/authentication/register2';
import Lockscreen from '../views/authentication/lockscreen';
import Recoverpwd from '../views/authentication/recover-pwd';
import Maintanance from '../views/authentication/maintanance';



//Ui-components Dropdown
import Alerts from '../views/ui-components/alert';
import Badges from '../views/ui-components/badge';
import Breadcrumbs from '../views/ui-components/breadcrumb';
import Buttons from '../views/ui-components/button';
import Dropdowns from '../views/ui-components/dropdown';
import BtnGroups from '../views/ui-components/btn-group';
import Cards from '../views/ui-components/cards';
import CollapseComponent from '../views/ui-components/collapse';
import CarouselComponent from '../views/ui-components/carousel';
import JumbotronComponent from '../views/ui-components/jumbotron';
import LayoutComponent from '../views/ui-components/layout';
import ListComponent from '../views/ui-components/listgroup';
import MediaComponent from '../views/ui-components/media';
import ModalComponent from '../views/ui-components/modal';
import NavbarComponent from '../views/ui-components/navbar';
import NavsComponent from '../views/ui-components/navs';
import PaginationComponent from '../views/ui-components/pagination';
import PopoverComponent from '../views/ui-components/popover';
import ProgressComponent from '../views/ui-components/progress';
import TableComponent from '../views/ui-components/table';
import TabsComponent from '../views/ui-components/tabs';
import TooltipComponent from '../views/ui-components/tooltip';
//Sample Pages Dropdown
import Starterkit from '../views/sample-pages/starter-kit';
import Profile from '../views/sample-pages/profile';
import Searchresult from '../views/sample-pages/search-result';
import Gallery from '../views/sample-pages/gallery';
import Helperclass from '../views/sample-pages/helperclass';
import Widgets from '../views/widget/widget';
import Calendar from '../views/calendar/calendar';
import AuthRoutes from './authroutes';
//Chart Pages Dropdown
import Chartjs from '../views/charts/chartjs';
import Chartc3 from '../views/charts/c3chart';
//Icon Pages Dropdown
import Materialicon from '../views/icons/material';
import FontAwesome from '../views/icons/fontawesome';
import Themify from '../views/icons/themify';
import Weather from '../views/icons/weather';
import Simpleline from '../views/icons/simpleline';
import FlagIcon from '../views/icons/flag';
//Form Layout Pages Dropdown
import Basicform from '../views/form-layouts/basic';
import FormInputs from '../views/form-layouts/form-inputs';
import FormGroups from '../views/form-layouts/form-groups';
import FormGrids from '../views/form-layouts/form-grids';
//Form-pickers Pages Dropdown
import Datepicker from '../views/form-pickers/datetimepicker';
import Tagselect from '../views/form-pickers/tag-select';
//Form Validation Page
import FormValidate from '../views/form-validation/form-validation';
//Form Wizard Page
import FormSteps from '../views/steps/steps';
//Table Pages Dropdown
import Basictable from '../views/tables/tablebasic';
import Reacttable from '../views/tables/reacttable';
import Datatable from '../views/tables/react-bootstrap-table';
import DutyDetail from '../views/duty/duty-detail'
import Vectormap from '../views/maps/vectormap';

var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
	// { path: "/dashboards/classic", name: "Dashboard", mini: "B", icon: "mdi mdi-view-dashboard", component: Classic },
	{ path: "/dashboards", auth: true, name: "Dashboard", level: 'c', mini: "B", icon: "mdi mdi-view-dashboard", component: Dashboard },
	{ path: '/resellers', auth: true, name: 'ReSellers', level: 'c', icon: 'mdi mdi-contacts', component: ReSellers },
	{ path: '/reseller-edit/:id', auth: true, name: 'ReSeller Edit', level: 'b', icon: 'mdi mdi-contacts', component: ReSellerEdit },
	{ path: '/companies', auth: true, name: 'Companies', level: 'c', icon: 'fab fa-trello mr-10', component: Companies },
	{ path: '/company-edit', auth: true, name: 'Company Edit', level: 'b', icon: 'mdi mdi-buffer mr-10', component: CompanyEdit },
	{ path: '/support-contact', auth: true, name: 'Support Contacts', level: 'c', icon: 'fas fa-user-plus mr-10', component: SupportContact },
	{ path: "/company-dashboard", auth: true, name: "Company Dashboard", level: 'b', icon: "mdi mdi-view-dashboard", component: CompanyDashboard },
	{ path: '/company/company-view', auth: true, name: 'Company-view', level: 'b', icon: 'mdi mdi-buffer mr-10', component: CompanyView },
	{ path: '/guards', auth: true, name: 'Guards', level: 'b', icon: 'mdi mdi-buffer mr-10', component: Guards },
	{ path: '/guard-edit/:id', auth: true, name: 'Guard Input', level: 'b', icon: 'mdi mdi-buffer mr-10', component: GuardEdit },
	{ path: '/guard-info/:id', auth: true, name: 'Guard Info', level: 'b', icon: 'mdi mdi-buffer mr-10', component: GuardInfo },
	{ path: '/supervisor', auth: true, name: 'Supervisor', level: 'b', icon: 'mdi mdi-buffer mr-10', component: Supervisors },
	{ path: '/supervisor-info/:id', auth: true, name: 'Supervisor Info', level: 'b', icon: 'mdi mdi-buffer mr-10', component: SupervisorInfo },
	{ path: '/supervisor-edit/:id', auth: true, name: 'Supervisor Info', level: 'b', icon: 'mdi mdi-buffer mr-10', component: SupervisorEdit },
	{ path: '/clients', auth: true, name: 'Clients', level: 'b', icon: 'mdi mdi-buffer mr-10', component: Clients },
	{ path: '/client-info/:id', auth: true, name: 'Client Information', level: 'b', icon: 'mdi mdi-buffer mr-10', component: ClientInfo },
	{ path: '/client-edit/:id', auth: true, name: 'Client Edit', level: 'b', icon: 'mdi mdi-buffer mr-10', component: ClientEdit },
	{ path: '/client-location/:id', auth: true, name: 'Clients Location', level: 'b', icon: 'mdi mdi-buffer mr-10', component: ClientsLocation },
	{ path: '/client-location-duty', auth: true, name: 'Clients Location Duty', level: 'b', icon: 'mdi mdi-buffer mr-10', component: ClientsLocationDuty },
	{ path: '/client-location-edit/:id', auth: true, name: 'Clients Location Edit', level: 'b', icon: 'mdi mdi-buffer mr-10', component: ClientsLocationEdit },
	{ path: '/district', auth: true, name: 'Districts', level: 'b', icon: 'mdi mdi-buffer mr-10', component: Districts },
	{ path: '/district-edit/:id', auth: true, name: 'District Edit', level: 'b', icon: 'mdi mdi-buffer mr-10', component: DistrictEdit },
	{ path: '/district-info/:id', auth: true, name: 'Districts Information', level: 'b', icon: 'mdi mdi-buffer mr-10', component: DistrictInfo },
	{ path: '/district-manager', level: 'b', auth: true, name: 'District Manager', icon: 'mdi mdi-buffer mr-10', component: DistrictManager },
	{ path: '/district-manager-info/:id', level: 'b', auth: true, name: 'District Manager Information', icon: 'mdi mdi-buffer mr-10', component: DistrictManagerInfo },
	{ path: '/district-manager-edit/:id', level: 'b', auth: true, name: 'District Manager Edit', icon: 'mdi mdi-buffer mr-10', component: DistrictManagerEdit },
	{ path: '/duty/:guard', auth: true, name: 'Duty', level: 'b', icon: 'ti-map-alt mr-10', component: Duty },
	{ path: '/duty-location/:location', auth: true, name: 'Duty', level: 'b', icon: 'ti-map-alt mr-10', component: DutyLocation },
	{ path: '/duty-edit/:id', auth: true, name: 'Duty', level: 'b', icon: 'ti-map-alt mr-10', component: DutyEdit },
	{ path: '/duty-detail/:id', auth: true, name: 'Duty', level: 'b', icon: 'ti-map-alt mr-10', component: DutyDetail },
	{ path: '/alert-template', level: 'b', auth: true, name: 'Alert Template', icon: 'mdi mdi-buffer mr-10', component: AlertTemplate },
	{ path: '/billing', auth: true, name: 'Billing', level: 'b', icon: 'mdi mdi-buffer mr-10', component: Billing },

	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		collapse: true,
		path: "/dashboards",
		name: "Dashboards",
		state: "dashboardpages",
		icon: "mdi mdi-view-dashboard",
		child: [
			{
				path: "/dashboards/classic",
				name: "Classic",
				mini: "B",
				icon: "mdi mdi-adjust",
				component: Classic
			},
			{
				path: "/dashboards/cryptocurrency",
				name: "Cryptocurrency",
				mini: "B",
				icon: "mdi mdi-adjust",
				component: Cryptocurrency
			},
			{
				path: "/dashboards/ecommerce",
				name: "Ecommerce",
				mini: "B",
				icon: "mdi mdi-adjust",
				component: Ecommerce
			},
			{
				path: "/dashboards/general",
				name: "General",
				mini: "B",
				icon: "mdi mdi-adjust",
				component: General
			}
		]
	},
	{
		collapse: true,
		path: "/ui-components",
		name: "Ui-Components",
		state: "uicomponents",
		icon: "mdi mdi-buffer",
		child: [
			{
				path: '/ui-components/alert',
				name: 'Alerts',

				icon: 'mdi mdi-comment-processing-outline',
				component: Alerts
			},
			{
				path: '/ui-components/badge',
				name: 'Badges',

				icon: 'mdi mdi-arrange-send-backward',
				component: Badges
			},
			{
				path: '/ui-components/breadcrumb',
				name: 'Breadcrumbs',

				icon: 'mdi mdi-equal',
				component: Breadcrumbs
			},
			{
				path: '/ui-components/button',
				name: 'Buttons',

				icon: 'mdi mdi-toggle-switch',
				component: Buttons
			},
			{
				path: '/ui-components/dropdown',
				name: 'Button Dropdown',

				icon: 'mdi mdi-cards-variant',
				component: Dropdowns
			},
			{
				path: '/ui-components/btn-group',
				name: 'Button Group',

				icon: 'mdi mdi-checkbox-multiple-blank',
				component: BtnGroups
			},
			{
				path: '/ui-components/card',
				name: 'Cards',

				icon: 'mdi mdi-credit-card-multiple',
				component: Cards
			},
			{
				path: '/ui-components/collapse',
				name: 'Collapse',

				icon: 'mdi mdi-elevator',
				component: CollapseComponent
			},
			{
				path: '/ui-components/carousel',
				name: 'Carousel',

				icon: 'mdi mdi-view-carousel',
				component: CarouselComponent
			},
			{
				path: '/ui-components/jumbotron',
				name: 'Jumbotron',

				icon: 'mdi mdi-hamburger',
				component: JumbotronComponent
			},
			{
				path: '/ui-components/layout',
				name: 'Layout',

				icon: 'mdi mdi-apps',
				component: LayoutComponent
			},
			{
				path: '/ui-components/listgroup',
				name: 'List Group',

				icon: 'mdi mdi-format-list-bulleted',
				component: ListComponent
			},
			{
				path: '/ui-components/media',
				name: 'Media',

				icon: 'mdi mdi-folder-multiple-image',
				component: MediaComponent
			},
			{
				path: '/ui-components/modal',
				name: 'Modal',

				icon: 'mdi mdi mdi-tablet',
				component: ModalComponent
			},
			{
				path: '/ui-components/navbar',
				name: 'Navbar',

				icon: 'mdi mdi-page-layout-header',
				component: NavbarComponent
			},
			{
				path: '/ui-components/navs',
				name: 'Navs',

				icon: 'mdi mdi-panorama-wide-angle',
				component: NavsComponent
			},
			{
				path: '/ui-components/pagination',
				name: 'Pagination',

				icon: 'mdi mdi-priority-high',
				component: PaginationComponent
			},
			{
				path: '/ui-components/popover',
				name: 'Popover',

				icon: 'mdi mdi-pencil-circle',
				component: PopoverComponent
			},
			{
				path: '/ui-components/progress',
				name: 'Progress',

				icon: 'mdi mdi-poll',
				component: ProgressComponent
			},
			{
				path: '/ui-components/table',
				name: 'Tables',

				icon: 'mdi mdi-border-none',
				component: TableComponent
			},
			{
				path: '/ui-components/tabs',
				name: 'Tabs',

				icon: 'mdi mdi-tab-unselected',
				component: TabsComponent
			},
			{
				path: '/ui-components/tooltip',
				name: 'Toltips',

				icon: 'mdi mdi-image-filter-vintage',
				component: TooltipComponent
			}
		]
	},
	{
		navlabel: true,
		name: "APPS",
		icon: "mdi mdi-dots-horizontal",
	},
	{ path: '/chat', name: 'Chat', icon: 'mdi mdi-comment-processing-outline', component: Chat },
	{ path: '/contacts', name: 'Contacts', icon: 'mdi mdi-contacts', component: Contacts },
	{ path: '/email', name: 'Email', icon: 'mdi mdi-inbox-arrow-down', component: Email },
	{ path: '/calendar', name: 'Calendar', icon: 'mdi mdi-calendar', component: Calendar },
	{
		path: '/authentication',
		name: 'Authentication',
		state: 'openAuthentication',
		icon: 'mdi mdi-account-circle',
		child: auths,
		collapse: true,
	},
	{
		navlabel: true,
		name: "Forms",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		collapse: true,
		path: '/form-layouts',
		name: 'Form Layouts',
		state: 'formlayoutPages',
		icon: 'mdi mdi-arrange-bring-forward',
		child: [
			{
				path: '/form-layouts/form-inputs',
				name: 'Form Inputs',

				icon: 'mdi mdi-priority-low',
				component: FormInputs
			},
			{
				path: '/form-layouts/form-groups',
				name: 'Form Groups',

				icon: 'mdi mdi-rounded-corner',
				component: FormGroups
			},
			{
				path: '/form-layouts/form-grids',
				name: 'Form Grids',

				icon: 'mdi mdi-select-all',
				component: FormGrids
			},
			{
				path: '/form-layouts/basic',
				name: 'Form Basic',

				icon: 'mdi mdi-book',
				component: Basicform
			}
		]
	},
	{
		collapse: true,
		path: '/form-pickers',
		name: 'Form Pickers',
		state: 'formpickerPages',
		icon: 'mdi mdi-pencil-box-outline',
		child: [
			{
				path: '/form-pickers/datetimepicker',
				name: 'Date Pickers',

				icon: 'mdi mdi-calendar-clock',
				component: Datepicker
			},
			{
				path: '/form-pickers/tag-select',
				name: 'Tags & Select',

				icon: 'mdi mdi-tag-multiple',
				component: Tagselect
			}
		]
	},
	{ path: '/form-validation', name: 'Form Validation', icon: 'mdi mdi-alert-box', component: FormValidate },
	{ path: '/form-steps', name: 'Form Steps', icon: 'mdi mdi-cube-send', component: FormSteps },
	{
		navlabel: true,
		name: "Tables",
		icon: "mdi mdi-dots-horizontal",
	},
	{ path: '/tables/tablebasic', name: 'Basic Table', icon: 'mdi mdi-border-all', component: Basictable },
	{ path: '/tables/reacttable', name: 'React Table', icon: 'mdi mdi-border-right', component: Reacttable },
	{ path: '/tables/datatable', name: 'Bootstrap DataTable', icon: 'mdi mdi-border-inside', component: Datatable },
	{
		navlabel: true,
		name: "Charts",
		icon: "mdi mdi-dots-horizontal",
	},
	{ path: '/charts/chartjs', name: 'Chartjs', icon: 'mdi mdi-blur', component: Chartjs },
	{ path: '/charts/c3chart', name: 'C3 Chart', icon: 'mdi mdi-chemical-weapon', component: Chartc3 },
	{
		navlabel: true,
		name: "Extra",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		collapse: true,
		path: '/sample-pages',
		name: 'Sample Pages',
		state: 'samplepages',
		icon: 'mdi mdi-file',
		child: [
			{
				path: '/sample-pages/starter-kit',
				name: 'Starter Kit',

				icon: 'mdi mdi-crop-free',
				component: Starterkit
			},
			{
				path: '/sample-pages/profile',
				name: 'Profile',

				icon: 'mdi mdi-account-network',
				component: Profile
			},
			{
				path: '/sample-pages/search-result',
				name: 'Search Result',

				icon: 'mdi mdi-search-web',
				component: Searchresult
			},
			{
				path: '/sample-pages/gallery',
				name: 'Gallery',

				icon: 'mdi mdi-camera-iris',
				component: Gallery
			},
			{
				collapse: true,
				name: 'Extra Pages',
				icon: 'mdi mdi-file',
				subchild: [
					{
						path: '/sample-pages/helperclass',
						name: 'Helper Classes',

						icon: 'mdi mdi-star',
						component: Helperclass
					}
				]
			}
		]
	},
	{
		path: '/widgets',
		name: 'Widgets',
		icon: 'mdi mdi-gradient',
		component: Widgets
	},
	{
		collapse: true,
		path: '/icons',
		name: 'Icons',
		state: 'iconsPages',
		icon: 'mdi mdi-face',
		child: [
			{
				path: '/icons/material',
				name: 'Material Icons',

				icon: 'mdi mdi-emoticon',
				component: Materialicon
			},
			{
				path: '/icons/fontawesome',
				name: 'Font Awesome Icons',

				icon: 'mdi mdi-emoticon-cool',
				component: FontAwesome
			},
			{
				path: '/icons/themify',
				name: 'Themify Icons',

				icon: 'mdi mdi-chart-bubble',
				component: Themify
			},
			{
				path: '/icons/weather',
				name: 'Weather Icons',

				icon: 'mdi mdi-weather-cloudy',
				component: Weather
			},
			{
				path: '/icons/simpleline',
				name: 'Simple Line Icons',

				icon: 'mdi mdi mdi-image-broken-variant',
				component: Simpleline
			},
			{
				path: '/icons/flag',
				name: 'Flag Icons',

				icon: 'mdi mdi-flag-triangle',
				component: FlagIcon
			}
		]
	},
	{
		path: '/vectormap',
		name: 'Vector Map',
		icon: 'ti-location-pin',
		component: Vectormap
	},
	{ path: '/', pathTo: '/dashboards', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
