import React, { useEffect, useState, useRef } from 'react'
import ReactTable from "react-table"
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { Select } from 'antd';
import { auth } from '../../Api';
import classnames from 'classnames';
import { CirclesWithBar } from 'react-loader-spinner'
import { DatePicker, version } from "antd";
import "antd/dist/antd.css";
import moment from "moment";

const { RangePicker } = DatePicker;
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DashboardTabGraphics extends React.Component {
    constructor(props) {
        super(props);

        var periodType = localStorage.getItem('periodType')
        if (periodType == null) {
            periodType = 'week'
        }

        var selectType = localStorage.getItem('selectType')
        var whichCompany = localStorage.getItem('whichCompany')
        let companyId = localStorage.getItem('companyId')

        if (whichCompany != null && companyId != null) {
            if (whichCompany != companyId) {
                localStorage.setItem('whichCompany', companyId)
                selectType = 'company:company:' + companyId
                localStorage.setItem('periodType', selectType)
            } else {
                if (selectType == null) {
                    selectType = 'company:company:' + companyId
                }
            }
        }

        this.state = {
            selectOptions: props.selectOptions,
            periodOptions: props.periodOptions,
            activeTab: "1",
            selectType: selectType,
            companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
            periodType: periodType,
            currentGuards: [],
            pastDutyData: [],
            chatData: [],
            showTable: true,
            currentPage: 1,
            perPage: 5,
            totalCount: 500,
            sysWidth: window.innerWidth,
            dateMilli: new Date().getTime(),
            pastMilli: new Date().getTime() - 30 * 24 * 60 * 60 * 1000
        }
    }

    componentDidMount() {
        let { selectType, dateMilli, pastMilli } = this.state

        console.log("select type in duty tab:", selectType)
        var req = {}
        req.type = selectType.split(":")[0]
        req.id = selectType.split(":")[2]
        req.dateMilli = dateMilli
        req.pastMilli = pastMilli
        this.getGraphicData(req)
    }

    handleDatePicker = () => {
        var { selectType, startStr, endStr, openDatePicker } = this.state;

        this.setState({ openDatePicker: !this.state.openDatePicker });

        if (openDatePicker && startStr != "" && endStr != "") {
            var startMilli = new Date(startStr).getTime()
            var endMilli = new Date(endStr).getTime() + 24 * 60 * 60 * 1000 - 50
            var req = {}
            req.type = selectType.split(":")[0]
            req.id = selectType.split(":")[2]
            req.dateMilli = endMilli
            req.pastMilli = startMilli
            this.setState({
                startMilli: startMilli,
                endMilli: endMilli
            });
            this.getGraphicData(req)
        }
    };

    async getGraphicData(req) {
        this.setState({
            loadingData: true
        })
        await auth.getGraphicData(req)
            .then((res) => {

                if (res.data.message.report.length > 0) {
                    var guards = res.data.message.report
                    var tmpChartData = {}

                    var startMilli = req.pastMilli
                    var oneMilli = 24 * 60 * 60 * 1000
                    guards.map((value, index) => {
                        var date = new Date(value.endtime);

                        // Get the year, month, and day
                        var year = date.getFullYear();
                        var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
                        var day = ("0" + date.getDate()).slice(-2);

                        // Format the date
                        var formattedDate = day + "/" + month + "/" + year;
                        if (tmpChartData[value.guard_name] == undefined) {

                            tmpChartData[value.guard_name] = {
                                type: "spline",
                                name: value.guard_name,
                                showInLegend: true,
                                dataPoints: [{
                                    y: value.alert_count, label: formattedDate, x: Math.floor((value.endtime - startMilli) / oneMilli)
                                }]
                            }
                        } else {
                            tmpChartData[value.guard_name].dataPoints.push({
                                y: value.alert_count, label: formattedDate, x: Math.floor((value.endtime - startMilli) / oneMilli)
                            })
                        }
                    })

                    var chatData = []
                    Object.keys(tmpChartData).map((key, index) => {
                        chatData.push(tmpChartData[key])
                    })

                    this.setState({
                        chatData: chatData
                    })
                } else {
                    this.setState({
                        chatData: []
                    })
                }
            })
        this.setState({
            loadingData: false
        })
    }

    onSecondCityChange =(value) =>{
        let { dateMilli, pastMilli } = this.state
        localStorage.setItem('selectType', value);
        this.setState({selectType:value})
        var req = {}
        req.type = value.split(":")[0]
        req.id = value.split(":")[2]
        req.dateMilli = dateMilli
        req.pastMilli = pastMilli
        this.getGraphicData(req)
    };

    displayDate(value) {
        var dateValue = new Date(value)
        return dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1) + '-' + dateValue.getDate()
            + ' ' + (dateValue.getHours() < 10 ? "0" + dateValue.getHours() : dateValue.getHours()) + ":" + (dateValue.getMinutes() < 10 ? "0" + dateValue.getMinutes() : dateValue.getMinutes()) + ":" + "00";
    }

    handleJustOpenDatePicker = () => {
        this.setState({ openDatePicker: true });
    };

    render() {

        let {
            currentGuards, pastDutyData, companyInfo, showTable, totalCount, currentPage, perPage,
            activeTab, periodOptions, selectOptions, selectType, periodType, chatData } = this.state


        let date = new Date()
        const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone: companyInfo.timezone }));
        let locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
        let currentOffset = (new Date()).getTimezoneOffset()
        let targetOffset = currentOffset - locationOffset
        const options = {
            animationEnabled: true,
            title: {
                text: "Number of Missed Count"
            },
            axisY: {
                title: "missed count"
            },
            toolTip: {
                shared: true
            },
            data: chatData,
            data0: [{
                type: "spline",
                name: "2016",
                showInLegend: true,
                dataPoints: [
                    { y: 155, label: "Jan" },
                    { y: 150, label: "Feb" },
                    { y: 152, label: "Mar" },
                    { y: 148, label: "Apr" },
                    { y: 142, label: "May" },
                    { y: 150, label: "Jun" },
                    { y: 146, label: "Jul" },
                    { y: 149, label: "Aug" },
                    { y: 153, label: "Sept" },
                    { y: 158, label: "Oct" },
                    { y: 154, label: "Nov" },
                    { y: 150, label: "Dec" }
                ]
            },
            {
                type: "spline",
                name: "2017",
                showInLegend: true,
                dataPoints: [
                    { y: 172, label: "Jan" },
                    { y: 173, label: "Feb" },
                    { y: 175, label: "Mar" },
                    { y: 172, label: "Apr" },
                    { y: 162, label: "May" },
                    { y: 165, label: "Jun" },
                    { y: 172, label: "Jul" },
                    { y: 168, label: "Aug" },
                    { y: 175, label: "Sept" },
                    { y: 170, label: "Oct" },
                    { y: 165, label: "Nov" },
                    { y: 169, label: "Dec" }
                ]
            }]
        }

        return <div>
            <div>
                <Row className='toolbar space-between pt-3 pb-3'>
                    {this.state.sysWidth < 567 ? <div className='pt-2'><Button onClick={this.handleDatePicker}>
                        {
                            this.state.openDatePicker ? "Get Missed Alert" : "Select Range"
                        }
                    </Button></div> : <></>}
                    <div onClick={this.handleJustOpenDatePicker} style={{ paddingTop: '1px' }}>
                        <RangePicker
                            popupClassName="range-picker-mobiliis"
                            defaultValue={moment()}
                            open={this.state.openDatePicker}
                            onChange={(dates, dateString) => {
                                this.setState({
                                    startStr: dateString[0],
                                    endStr: dateString[1]
                                })
                                console.log(dates, dateString)
                            }}
                        />
                    </div>
                    {this.state.sysWidth > 567 ? <div className='pl-2 pr-2'><Button onClick={this.handleDatePicker}>
                        {
                            this.state.openDatePicker ? "Get Missed Alert" : "Select Range"
                        }
                    </Button></div> : <></>}
                    <div style={{ paddingTop: '2px' }}>
                        <Select
                            style={{ width: 140 }}
                            value={selectType.split(":")[1]}
                            onChange={this.onSecondCityChange}
                            options={selectOptions}
                        />
                    </div>
                    <div style={{ paddingTop: '6px' }}>
                        {this.state.loadingData && <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Loading
                            <CirclesWithBar
                                height="25"
                                width="25"
                                radius="3"
                                color="green"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            /></span>}
                    </div>
                </Row>
            </div>
            <CanvasJSChart options={options}
            /* onRef={ref => this.chart = ref} */
            />
        </div>
    }
}

import { connect } from "react-redux";
import { setFilterType } from "../../redux/dashboard/action";

const mapStateToProps = (state, ownProps) => ({
    filterType: state.dashboard.filterType,
    selectOptions: ownProps.selectOptions
});

const mapDispatchToProps = dispatch => ({
    setFilterType: (data) => dispatch(setFilterType(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardTabGraphics);