import React, { useEffect, useState, useRef } from 'react'
import {
    Button, Modal, ModalBody, Form, FormGroup, Label, Input, Row, Col,
} from 'reactstrap';
import { Select } from 'antd';
import { auth } from '../../Api';
import classnames from 'classnames';
import { CirclesWithBar } from 'react-loader-spinner'
import { Pagination, Switch } from 'antd';
import { DatePicker, version } from "antd";
import moment from "moment";
import { saveAs } from 'file-saver';

const { RangePicker } = DatePicker;

class DashboardTabReport extends React.Component {
    constructor(props) {
        super(props);

        var periodType = localStorage.getItem('periodType')
        if (periodType == null) {
            periodType = 'daily'
        }

        var selectType = localStorage.getItem('selectType')
        var whichCompany = localStorage.getItem('whichCompany')
        let companyId = localStorage.getItem('companyId')



        if (whichCompany != null && companyId != null) {
            if (whichCompany != companyId) {
                localStorage.setItem('whichCompany', companyId)
                selectType = 'company:company:' + companyId
                localStorage.setItem('selectType', selectType)
            } else {
                if (selectType == null) {
                    selectType = 'company:company:' + companyId
                }
            }
        }

        if (selectType == null) {
            selectType = 'company:company:' + companyId
        }

        console.log("select type==00--:", selectType)

        this.state = {
            sysWidth: window.innerWidth,
            selectOptions: props.selectOptions,
            periodOptions: [
                {
                    label: '6 monthly',
                    value: "6month"
                },
                {
                    label: 'Monthly',
                    value: "month"
                },
                {
                    label: 'Weekly',
                    value: "week"
                },
                {
                    label: 'Daily',
                    value: "day"
                },
            ],
            adminOptions: props.adminOptions,
            activeTab: "1",
            selectType: selectType,
            selectDownType: selectType,
            reportOn: true,
            companyInfo: JSON.parse(localStorage.getItem('companyInfo')),
            periodType: periodType,
            currentGuards: [],
            pastDutyData: [],
            showTable: true,
            currentPage: 1,
            perPage: 5,
            totalCount: 500,
            reportParams: ["guardname", "location", "missed_alert"],
            reportParamsAdmin: [],
            reportParamsDistrict: [],
            reportParamsDistrictManager: [],
            reportParamsSupervisor: [],
            reportParamsClient: [],
            reportOptions: [
                {
                    label: 'Guard Name',
                    value: 'guardname'
                },
                {
                    label: 'Location',
                    value: 'location'
                },
                {
                    label: 'Missed Alert',
                    value: 'missed_alert'
                }
            ],
            downloadDistrict: [],
            dateMilli: new Date().getTime(),
            pastMilli: new Date().getTime() - 30 * 24 * 60 * 60 * 1000
        }
    }

    componentDidMount() {
        auth.getReportParams({
            company_id: this.state.companyInfo.id
        }).then((res) => {
            if (res.data.message.report != null) {
                var selectType = res.data.message.report.select_type
                var reportDB = JSON.parse(res.data.message.report['report_data'])
                console.log("reportDB====", reportDB)
                console.log("reportDB=admin===", reportDB.admin)
                console.log("reportDB=district===", reportDB.district)
                console.log("reportDB=districtmanager===", reportDB.districtmanager)
                console.log("reportDB=supervisor===", reportDB.supervisor)
                console.log("reportDB=client===", reportDB.client)
                this.setState({
                    reportParamsAdmin: reportDB.admin,
                    reportParamsDistrict: reportDB.district,
                    reportParamsDistrictManager: reportDB.districtmanager,
                    reportParamsSupervisor: reportDB.supervisor,
                    reportParamsClient: reportDB.client,
                    reportParams: reportDB.reportParams,
                    periodType: reportDB.periodType ?? ["daily"],
                    selectType: selectType,
                    reportOn: res.data.message.report.report_on
                })
            }
        })
    }

    onAdminTypeChange = (value, type) => {
        console.log("value, type===:", value, type)
        if (type == 0) {
            this.setState({ reportParamsAdmin: value })
        } else if (type == 1) {
            this.setState({ reportParamsDistrict: value })
        } else if (type == 2) {
            this.setState({ reportParamsDistrictManager: value })
        } else if (type == 3) {
            this.setState({ reportParamsSupervisor: value })
        } else if (type == 4) {
            this.setState({ reportParamsClient: value })
        }
    }

    onAdminTypeCustomChange = (value, type) => {
        console.log("value, type===:", value, type)
        var { startStr, endStr } = this.state;
        if (type == 0) {
            this.setState({ downloadAdmin: value })
        } else if (type == 1) {
            this.setState({ downloadDistrict: value })
        } else if (type == 2) {
            this.setState({ downloadDistrictManager: value })
        } else if (type == 3) {
            this.setState({ downloadSupervisor: value })
        } else if (type == 4) {
            this.setState({ downloadClient: value })
        }
        if (startStr != undefined && endStr != undefined) {
            if (startStr == "" || endStr == "") {
                this.setState({
                    readyCSV: false
                })
            } else {
                this.setState({
                    readyCSV: true
                })
            }
        } else {
            this.setState({
                readyCSV: false
            })
        }
    }

    onSecondCityChange = (value) => {
        this.setState({ selectType: value })
    };

    onDownTypeChange = (value) => {
        this.setState({
            selectDownType: value
        })
    }

    onChangeReportContent = (value) => {
        this.setState({ reportParams: value });
    };

    changeDatePicker(start, end) {
        // let { downloadDistrict } = this.state
        var startMilli = new Date(start).getTime()
        var endMilli = new Date(end).getTime() + 24 * 60 * 60 * 1000 - 50
        this.setState({
            readyCSV: true
        })
        // if (downloadDistrict != undefined) {
        //     if (downloadDistrict.length == 0) {
        //         this.setState({
        //             readyCSV: false
        //         })
        //     } else {
        //         this.setState({
        //             readyCSV: true
        //         })
        //     }
        // } else {
        //     this.setState({
        //         readyCSV: false
        //     })
        // }
        this.setState({
            startStr: start,
            endStr: end,
            startMilli: startMilli,
            endMilli: endMilli
        });

    }

    handleDatePicker = () => {
        var { selectType, startStr, endStr, openDatePicker, downloadDistrict } = this.state;
        this.setState({ openDatePicker: !this.state.openDatePicker });
        if (openDatePicker && startStr != "" && endStr != "") {
            var startMilli = new Date(startStr).getTime()
            var endMilli = new Date(endStr).getTime() + 24 * 60 * 60 * 1000 - 50
            this.setState({
                readyCSV: true
            })
            // if (downloadDistrict != undefined) {
            //     if (downloadDistrict.length == 0) {
            //         this.setState({
            //             readyCSV: false
            //         })
            //     } else {
            //         this.setState({
            //             readyCSV: true
            //         })
            //     }
            // } else {
            //     this.setState({
            //         readyCSV: false
            //     })
            // }
            this.setState({
                startMilli: startMilli,
                endMilli: endMilli
            });
            // this.getGraphicData(req)
        }
    };

    downloadData = async () => {
        this.setState({ downloading: true })
        var { selectType, startStr, endStr, downloadDistrict, companyId, companyInfo, openDatePicker } = this.state;

        var startMilli = new Date(startStr).getTime()
        var endMilli = new Date(endStr).getTime() + 24 * 60 * 60 * 1000 - 50
        var req = {}
        req.type = selectType.split(":")[0]
        req.id = selectType.split(":")[2]
        var districtIds = []
        downloadDistrict.map((item) => {
            districtIds.push(Number(item.split(":")[2]))
        })
        req.ids = districtIds
        req.select_type = selectType
        req.company_id = companyInfo.id
        req.dateMilli = endMilli
        req.pastMilli = startMilli
        this.setState({
            startMilli: startMilli,
            endMilli: endMilli
        });
        await auth.getDownloadData(req)
            .then((res) => {
                if (res.data.message.report.length > 0) {
                    var guards = res.data.message.report
                    console.log("guards=======:", guards)
                    var tmpChartData = {}
                    var startMilli = req.pastMilli
                    var oneMilli = 24 * 60 * 60 * 1000
                    guards.map((value, index) => {
                        var date = new Date(value.endtime);
                        // Get the year, month, and day
                        var year = date.getFullYear();
                        var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
                        var day = ("0" + date.getDate()).slice(-2);
                        // Format the date
                        var formattedDate = day + "/" + month + "/" + year;
                        if (tmpChartData[value.guard_name] == undefined) {
                            tmpChartData[value.guard_name] = {
                                type: "spline",
                                name: value.guard_name,
                                showInLegend: true,
                                dataPoints: [{
                                    y: value.alert_count, label: formattedDate, x: Math.floor((value.endtime - startMilli) / oneMilli)
                                }]
                            }
                        } else {
                            tmpChartData[value.guard_name].dataPoints.push({
                                y: value.alert_count, label: formattedDate, x: Math.floor((value.endtime - startMilli) / oneMilli)
                            })
                        }
                    })
                    const csvData = this.convertToCSV(guards);
                    this.downloadCSV(csvData, 'Mobiliis Custom Report-' + startStr + '->' + endStr + '.csv');
                } else {
                    this.setState({
                        chatData: []
                    })
                }
            })
        this.setState({
            downloading: false
        })
    }

    downloadCSV(data, filename) {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvData, filename);
    };

    // Convert your data to CSV format
    convertToCSV(data) {
        var keyText = ['startdate','starttime','guard_name','location_name','total_alerts','missed_alerts','missed_alert_percentage','ArrivedOnTime','LeftOnTime','tracking','tracking_percent','times_missed_alert'];
        var stringText = ""
        keyText.map((key, i) => {
            if (i == 0) {
                stringText = key
            } else {
                stringText += "," + key
            }
        })
        stringText += "\n"
        data.map((row) => {
            var rowText = ""
            keyText.map((key, index) => {
                if (index == 0) {
                    rowText = row[key]
                } else {
                    rowText += "," + row[key]
                }
            })
            rowText += "\n"
            stringText += rowText
        })
        return stringText
        return 'id,name\n1,John Doe\n2,Jane Doe'; // Example CSV data
    };

    updateReportParams = () => {
        this.setState({ loadingData: true })
        let { reportParamsAdmin, reportParamsDistrict, reportParamsDistrictManager,
            reportParamsSupervisor, reportParamsClient, periodType, selectType, reportOn,
            reportParams, companyInfo } = this.state
        var data = {}
        data.admin = reportParamsAdmin
        data.district = reportParamsDistrict
        data.districtmanager = reportParamsDistrictManager
        data.supervisor = reportParamsSupervisor
        data.client = reportParamsClient
        data.reportParams = reportParams
        // data.periodType = periodType
        data.periodType = 'daily'
        var req = {}
        req.company_id = companyInfo.id
        req.report_data = JSON.stringify(data)
        req.select_type = selectType,
            req.report_on = reportOn

        console.log("req data:", req)
        auth.updateReportParams(req).then((res) => {
            this.setState({
                loadingData: false,
                contentModal: true,
                contentMessage: res.data.message.result
            })
        })
    }

    contentToggle = () => {
        this.setState({
            contentModal: !this.state.contentModal
        });
    }

    getGuard(req) {
        auth.getGuards(req)
            .then((res) => {
                if (res.data.message.guards.length > 0) {
                    var guards = res.data.message.guards
                    var updateGuards = []
                    guards.forEach(element => {
                        var missed_alert = res.data.message["guards" + element.id].length
                        element.missed_alert = missed_alert;
                        updateGuards.push(element)
                    });
                    this.setState({
                        currentGuards: updateGuards
                    })
                } else {
                    this.setState({
                        currentGuards: []
                    })
                }
            })

    }

    displayDate(value) {
        var dateValue = new Date(value)
        return dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1) + '-' + dateValue.getDate()
            + ' ' + (dateValue.getHours() < 10 ? "0" + dateValue.getHours() : dateValue.getHours()) + ":" + (dateValue.getMinutes() < 10 ? "0" + dateValue.getMinutes() : dateValue.getMinutes()) + ":" + "00";
    }

    onChange = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            reportOn: checked
        })
    };

    render() {

        let {
            companyInfo,
            selectOptions,
            selectType,
            selectDownType,
            reportOn
        } = this.state

        console.log("readyCSV===:", this.state.readyCSV)
        return <div className='report-cover'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='center-center'>
                        <div className='sub-title pb-2'>Daily Report</div>
                    </div>
                    <div style={{ margin: 'auto', width: 'fit-content' }}>
                        <div className='pb-1'>1. Select Guards from dropdown</div>
                        <div className='pb-1'>2. Toggle reports 'ON'</div>
                        <div className='pb-1'>3. To stop reports toggle 'OFF'</div>
                        <div className='report-item pb-3 pt-3'>
                            {/* <Select
                            mode='multiple'
                            placeholder="Districts"
                            value={this.state.reportParamsDistrict}
                            style={{ width: 350 }}
                            onChange={(value) => this.onAdminTypeChange(value, 1)}
                            options={[selectOptions[1]]}
                        /> */}
                            <Select
                                style={{ width: 140 }}
                                value={selectType.split(":")[1]}
                                onChange={this.onSecondCityChange}
                                options={selectOptions}
                            />
                        </div>
                        <div className='report-item pb-1'>
                            <label>Turn reports ON/OFF</label>
                            <Switch checked={reportOn} onChange={this.onChange} />
                        </div>
                        <div className='report-item pt-3 center-center'>
                            {this.state.loadingData ? <div style={{ paddingTop: '8px', width: '350px', display: 'inline-block' }}>
                                <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Updating
                                    <CirclesWithBar
                                        height="25"
                                        width="25"
                                        radius="3"
                                        color="green"
                                        ariaLabel="loading"
                                        wrapperStyle
                                        wrapperClass
                                    /></span>
                            </div> : <Button onClick={this.updateReportParams} style={{ padding: '5px 40px' }}>Save</Button>}
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='center-center'>
                        <div className='sub-title pb-2'>Custom Report</div>
                    </div>

                    <div style={{ margin: 'auto', width: 'fit-content' }}>
                        <div>1. Select Guards from dropdown</div>
                        <div>2. Select date range for reports required</div>
                        <div>3. Click 'Download CSV'</div>
                        <div style={{ paddingTop: '2px' }}>
                            {/* <Select
                            mode='multiple'
                            placeholder="Districts"
                            value={this.state.downloadDistrict}
                            style={{ width: 350 }}
                            onChange={(value) => this.onAdminTypeCustomChange(value, 1)}
                            options={[selectOptions[1]]}
                        /> */}
                            <Select
                                style={{ width: 140 }}
                                value={selectDownType.split(":")[1]}
                                onChange={this.onDownTypeChange}
                                options={selectOptions}
                            />
                        </div>
                        <div>
                            <div onClick={this.handleJustOpenDatePicker} style={{ paddingTop: '1px' }}>
                                <RangePicker
                                    popupClassName="range-picker-mobiliis"
                                    defaultValue={moment()}
                                    open={this.state.openDatePicker}
                                    onChange={(dates, dateString) => {
                                        this.changeDatePicker(
                                            dateString[0],
                                            dateString[1]
                                        )
                                    }}
                                />
                                <div className='pl-2 pr-2' style={{ display: 'inline' }}><Button onClick={this.handleDatePicker}>
                                    {
                                        this.state.openDatePicker ? "Done" : "Pick"
                                    }
                                </Button></div>
                            </div>
                        </div>
                        <div className='report-item pt-3 center-center'>
                            {
                                !this.state.readyCSV ?
                                    <Button>Please select filter items</Button>
                                    : this.state.downloading ? <div style={{ paddingTop: '8px', width: '350px', display: 'inline-block' }}>
                                        <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Downloading
                                            <CirclesWithBar
                                                height="25"
                                                width="25"
                                                radius="3"
                                                color="green"
                                                ariaLabel="loading"
                                                wrapperStyle
                                                wrapperClass
                                            /></span>
                                    </div> : <Button onClick={this.downloadData} style={{ padding: '5px 20px' }}>Download CSV</Button>}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="nav-header-cus pt-5">
                <div className='report-item'>
                    <label>Districts&nbsp;:&nbsp;&nbsp;</label>
                    <Select
                        mode='multiple'
                        placeholder="Districts"
                        value={this.state.reportParamsDistrict}
                        style={{ width: 350 }}
                        onChange={(value) => this.onAdminTypeChange(value, 1)}
                        options={[selectOptions[1]]}
                    />
                </div>
                <div className='report-item'>
                    <label>District Managers&nbsp;:&nbsp;&nbsp;</label>
                    <Select
                        mode='multiple'
                        placeholder="District Managers"
                        value={this.state.reportParamsDistrictManager}
                        style={{ width: 350 }}
                        onChange={(value) => this.onAdminTypeChange(value, 2)}
                        options={[selectOptions[2]]}
                    />
                </div>
                <div className='report-item'>
                    <label>Supervisors&nbsp;:&nbsp;&nbsp;</label><Select
                        mode='multiple'
                        placeholder="Supervisors"
                        value={this.state.reportParamsSupervisor}
                        style={{ width: 350 }}
                        onChange={(value) => this.onAdminTypeChange(value, 3)}
                        options={[selectOptions[3]]}
                    /></div>
                <div className='report-item'>
                    <label>Clients&nbsp;:&nbsp;&nbsp;</label>
                    <Select
                        mode='multiple'
                        placeholder="Clients"
                        value={this.state.reportParamsClient}
                        style={{ width: 350 }}
                        onChange={(value) => this.onAdminTypeChange(value, 4)}
                        options={[selectOptions[4]]}
                    />
                </div>
                <div className='report-item'>
                    <label>Frequency&nbsp;:&nbsp;&nbsp;</label>
                    <Select
                        style={{ width: 350 }}
                        value={periodType}
                        onChange={this.onChangePeriod}
                        options={periodOptions}
                    />
                </div>
                <div className='report-item'>
                    <label>Report Content&nbsp;:&nbsp;&nbsp;</label>
                    <Select
                        mode='multiple'
                        style={{ width: 350 }}
                        value={reportParams}
                        disabled={true}
                        onChange={this.onChangeReportContent}
                        options={reportOptions}
                    />
                </div>
                <div className='report-item pt-3 center-center'>
                    {this.state.loadingData ? <div style={{ paddingTop: '8px', width: '350px', display: 'inline-block' }}>
                        <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Updating
                            <CirclesWithBar
                                height="25"
                                width="25"
                                radius="3"
                                color="green"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            /></span>
                    </div> : <Button onClick={this.updateReportParams} style={{ width: 350 }}>Save</Button>}

                </div>
            </div> */}
            <Modal isOpen={this.state.contentModal} toggle={this.contentToggle}>
                <ModalBody className="alert-body">
                    <i className="mdi mdi-alert-circle ft-60"></i>
                    <h2>{this.state.contentMessage}</h2>
                    <div className="height-10"></div>
                    <FormGroup>
                        <Button color="secondary" className="modal-btn ml-1" onClick={this.contentToggle}>OK</Button>
                    </FormGroup>
                </ModalBody>
            </Modal>
        </div>
    }
}

import { connect } from "react-redux";
import { setFilterType } from "../../redux/dashboard/action";

const mapStateToProps = (state, ownProps) => ({
    filterType: state.dashboard.filterType,
    selectOptions: ownProps.selectOptions,
    adminOptions: ownProps.adminOptions
});

const mapDispatchToProps = dispatch => ({
    setFilterType: (data) => dispatch(setFilterType(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardTabReport);