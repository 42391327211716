import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import {auth} from "../../Api";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import './company-view.scss'
import avatar from '../../assets/images/users/3.jpg'
import time4 from '../../assets/images/big/img4.jpg';
import { baseURL,baseImgURL } from '../../Api/BaseApi';

const { getCode, getName } = require('country-list');
const TreeTable = treeTableHOC(ReactTable);

class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			alertModal: false,
			obj: {},
			jsonData: data.dataTable,
			locationData: [],
			data: data.makeData(),
			treedata: data.treedata,
			avatarUrl:'/storage/avatar.jpg',
			companyData: [{
				"name":"Trial Company",
				"phonenumber":"+44123456789",
				"email":"trial@trial.com",
				"active":"True",
				"countrycode":"GB",
				"avatar":'/storage/avatar.jpg',
			}],
			companyId:null
		};
		this.toggle = this.toggle.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		let companyId = localStorage.getItem('companyId')
		this.setState({companyId:companyId})
		var req={}
		req.id = companyId
		auth.getCompany(req)
			.then((res)=>{
				if(res.data.code==200){
					this.setState({
						companyData:res.data.message.users,
						avatarUrl: res.data.message.users[0].avatar,
						locationData:res.data.message.locations
					})
					localStorage.setItem('companyInfo',JSON.stringify(res.data.message.users[0]))
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}
	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	  }

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		let location = event.target.location.value;
		let age = event.target.age.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name, designation, location, age];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal
		})
	}

	render() {
		const { companyData,companyId,avatarUrl } = this.state;

		const data2 = this.state.locationData.map((prop, key) => {
			console.log("props.Country code:",prop.countrycode)
			return {
				id: key,
				name: (
					<a onClick={()=>this.props.history.push('/duty-location/'+prop.id)} style={{cursor:"pointer"}}>{prop.name}</a>
					),
				address: prop.address,
				country: getName(prop.countrycode),
				timezone: prop.timezone,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit:(
					<div className="justify-content-around">
						<Button className="btn-table-edit" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
								
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"white"}}/></Button>
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				),
			};
		});
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		console.log("companyId::",companyId);
		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" defaultValue={this.state.obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Designation</Label>
							<Input type="text" name="designation" id="designation" defaultValue={this.state.obj.designation} />
						</FormGroup>
						<FormGroup>
							<Label for="location">Location</Label>
							<Input type="text" name="location" id="location" defaultValue={this.state.obj.location} />
						</FormGroup>
						<FormGroup>
							<Label for="age">Age</Label>
							<Input type="text" name="age" id="age" defaultValue={this.state.obj.age} />
						</FormGroup>
						<FormGroup>
							<Button color="primary" type="submit">Save</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this client?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" onClick={this.alertToggle}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Card>
                <CompanyMenu></CompanyMenu>
				
				<Row className="toolbar up-card-title">
                    <div className="b-title"><i className="mdi mdi-buffer mr-10"></i>&nbsp;{companyData[0].name}<span className="b-title-in">
						<a onClick={()=>this.props.history.push('/company-edit')} style={{cursor:"pointer"}}>(Edit)</a></span></div>
                    <div className="b-title-end" onClick={()=>this.props.history.push('/company-edit')} style={{cursor:"pointer"}}>
						Setup User Accounts&nbsp;<i className="mr-2 mdi mdi-account-settings"></i></div>
                </Row>
                <Row className="toolbar up-card-box">
                    <div style={{padding:"10px"}}><img src = {avatarUrl!=""?avatarUrl=="/storage/avatar.jpg"?avatar:baseImgURL+avatarUrl:avatar} className="img-company"></img></div>
                    <div>
 
                        <Row className="company-text-area">
                            <div className="company-text-key">Phone</div>
                            <div className="company-text-value">{companyData[0].phonenumber}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">Email</div>
                            <div className="company-text-value">{companyData[0].email}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">Active Company</div>
                            <div className="company-text-value">{companyData[0].active==1?"TRUE":"FALSE"}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">Country</div>
                            <div className="company-text-value">{getName(companyData[0].countrycode)}</div>
                        </Row>
                    </div>
                </Row>
            </Card>
            {/* <Card>
				<CardBody>
					<Row className="table-header">
                        <div className="table-title"><i className="far fa-address-card"></i><span>&nbsp;Address</span></div>
                        <div style={{ width: 400 }} className="auto-complete">
                        <ReactSearchAutocomplete
                            items={items}
                            onSearch={handleOnSearch}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            autoFocus
                        />
                        </div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "Location",
								accessor: "name"
							},
							{
								Header: "Address",
								accessor: "address"
							},
							{
								Header: "Country",
								accessor: "country"
							},
							{
								Header: "Timezone",
								accessor: "timezone"
							},
							// {
							// 	Header: "ACTIVE",
							// 	accessor: "actions",
							// 	sortable: false,
							// 	filterable: false
							// },
							// {
							// 	Header: "",
							// 	accessor:"edit",
							// 	id: "row",
							// 	maxWidth: 100,
							// 	filterable: false,
							// },							
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					
				</CardBody>
			</Card> */}
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default CompanyView;
