import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as cx from 'classnames'
import * as data from "../tables/reactable-data";
import CompanyMenu from '../companies/company-menu'
import "react-table/react-table.css";
import './duty-detail.scss'
import { auth } from '../../Api'
import { CirclesWithBar } from 'react-loader-spinner'
import MyComponent from './map-container';
import EditTrackStatus from './EditActivityStatus';

var tzOffset = require("tz-offset")

class DutyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            open: false,
            modal: false,
            obj: {},
            jsonData: data.dataTable,
            dutyData: [],
            data: data.makeData(),
            treedata: data.treedata,
            alertData: [],
            restBreak: [],
            alertLst: [],
            locationData: {},
            dutyTimeOffset: 0,
            currentOffset: 0,
            targetOffset: 0,
            showAlert: false,
            editTrackStatus: false,
            selectedAlertIndex: 0,
            center: {
                lat: 45,
                lng: 116
            },
            zoom: 11,
            markers: [
                {
                    id: -100,
                    title: "This location is center for duty",
                    name: "Home",
                    position: { lat: "37.778519", lng: "-122.40564" }
                }
            ],
            guardMarkers: [
                {
                    id: 1000,
                    title: "This location is center for duty",
                    name: "Home",
                    latitude: "37.778519",
                    longitude: "-122.40564",
                    onlineStatus: 1
                }
            ],
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mapId: "000"
        };
        this.toggle = this.toggle.bind(this);
        this.toggleTrackStatus = this.toggleTrackStatus.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params
        this.setState({
            dutyId: id
        })
        var req = {}
        req.id = id
        auth.getDuty(req)
            .then((res) => {
                if (res.data.code == 200) {
                    var location = res.data.message.location
                    var dutyTimeOffset = tzOffset.offsetOf(location.timezone)
                    let currentOffset = (new Date()).getTimezoneOffset()
                    let targetOffset = currentOffset - dutyTimeOffset
                    var markers = [
                        {
                            title: "This location is center for duty",
                            name: "Home",
                            position: { lat: location.latitude, lng: location.longitude }
                        },
                    ]
                    var center = {
                        lat: location.latitude,
                        lng: location.longitude
                    }
                    this.setState({
                        dutyData: res.data.message.users,
                        locationData: res.data.message.location,
                        alertData: res.data.message.alert,
                        restBreak: res.data.message.rest_break,
                        markers: markers,
                        guardMarkers: res.data.message.guard_locations,
                        center: center,
                        dutyTimeOffset: dutyTimeOffset,
                        currentOffset: currentOffset,
                        targetOffset: targetOffset,
                        mapId: this.state.mapId + "1"
                    })
                }
            })
    }
    displayDate(value) {
        var dateValue = new Date(value)
        return dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1) + '-' + dateValue.getDate()
            + ' ' + (dateValue.getHours() < 10 ? "0" + dateValue.getHours() : dateValue.getHours()) + ":" + (dateValue.getMinutes() < 10 ? "0" + dateValue.getMinutes() : dateValue.getMinutes()) + ":" + "00";
    }

    toggle() {
        this.setState({
            showAlert: !this.state.showAlert
        });
    }

    toggleTrackStatus(data, key) {
        this.setState({
            selectedAlert: data,
            editTrackStatus: !this.state.editTrackStatus,
            selectedAlertIndex: key
        });
    }

    goEdit = () => {
        let { dutyData, dutyId } = this.state
        var now = new Date().getTime()
        var startTime = dutyData[0].starttime
        if (now - startTime < 0)
            this.props.history.push('/duty-edit/' + dutyId)
        else {
            var alertMessage = "This Duty can't edit"
            this.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
            })
        }
    }

    changeStatus = (value) => {
        let { alertData } = this.state
        alertData[this.state.selectedAlertIndex].HasBeenValidated = value
        alertData[this.state.selectedAlertIndex].editHistory.push("edited")
        this.setState(alertData)
    }

    onWheel = (event) => {
        console.log("mouse event by wheel")
        console.log("wheel event",event)
    }

    render() {
        const { alertData, restBreak, dutyData, locationData, center, 
            dutyTimeOffset, targetOffset, currentOffset, dutyId } = this.state;

        return <div>

            <Card>
                <CompanyMenu></CompanyMenu>
            </Card>
            <Row className="space-around">
                <div className="col-md-6">
                    <Card>
                        <Row className="toolbar up-card-title">
                            <div className="b-title"><i className="icon-shield mr-10"></i>&nbsp;DutyDetail<span className="b-title-in" onClick={() => { this.goEdit() }} style={{ cursor: "pointer" }}>(Edit)</span></div>
                        </Row>
                        <Row className="toolbar up-card-box">
                            {dutyData.length != 0 ? <Row className="mr-0 ml-0" style={{ width: "100%" }}>
                                <div className="col-md-6">
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">DutyName</div>
                                        <div className="duty-text-value">{dutyData[0].name}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">Client</div>
                                        <div className="duty-text-value">{dutyData[0].client_name}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">Location</div>
                                        <div className="duty-text-value">{dutyData[0].location_name}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">Supervisor</div>
                                        <div className="duty-text-value">{dutyData[0].supervisor_name}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">Guard</div>
                                        <div className="duty-text-value">{dutyData[0].guard_name}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">Paid Status</div>
                                        <div className="duty-text-value">{dutyData[0].paidstatus == 0 ? "FALSE" : "TRUE"}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">STATUS</div>
                                        <div className="duty-text-value">{dutyData[0].active == 0 ? "InActive" : "Active"}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">DURATION</div>
                                        <div className="duty-text-value">{dutyData[0].duration} min</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">START DATE</div>
                                        <div className="duty-text-value">{this.displayDate(parseInt(dutyData[0].starttime + targetOffset * 60 * 1000))}</div>
                                    </div>
                                    <div className="duty-text-area">
                                        <div className="duty-text-key">END DATE</div>
                                        <div className="duty-text-value">{this.displayDate(parseInt(dutyData[0].starttime + targetOffset * 60 * 1000) + (dutyData[0].duration) * 60 * 1000)}</div>
                                    </div>
                                    {
                                        dutyTimeOffset != currentOffset ? targetOffset == 0 ?
                                            <Button onClick={() => { this.setState({ targetOffset: currentOffset - dutyTimeOffset }) }}>Show Durty Timezone</Button>
                                            : <Button onClick={() => { this.setState({ targetOffset: 0 }) }}>Show My Timezone</Button> : <></>
                                    }
                                </div>
                            </Row> : <div></div>}
                        </Row>
                    </Card>
                </div>
                <div className="col-md-6">
                    <Card className="pl-3 pr-3 pb-3">
                        <Row className="toolbar up-card-title">
                            <div className="b-title"><i className="icon-paper-plane mr-10"></i>&nbsp;Activity<span className="b-title-in">(Click to change)</span></div>
                        </Row>
                        <div className="height-10"></div>
                        <div className="d-flex flex-wrap">
                            {
                                alertData.map((prop, key) => {
                                    var time = new Date(parseInt(targetOffset != null ? prop.DateTime + targetOffset * 60 * 1000 : prop.DateTime))
                                    var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                                        + ":"
                                        + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
                                    var d1 = new Date()
                                    var now = new Date(d1);
                                    if (targetOffset != null) {
                                        now.setMinutes(d1.getMinutes() + currentOffset - dutyTimeOffset);
                                    }
                                    prop.isPast = false;
                                    if (now - time > 0)
                                        prop.inPast = true;
                                    if (key == 0)
                                        return <div className="alert-confirm" key={strTime}><i className="icon-bell"></i>&nbsp;{strTime}</div>
                                    return (<div
                                        onClick={() => this.toggleTrackStatus(prop, key)}
                                        style={{ cursor: "pointer" }}
                                        className={cx(prop.HasBeenValidated == 1 ? "alert-confirm" :
                                            (now - time > 0) ? "alert-missed" : "alert-remain", prop.editHistory.length>0?"hasHistory":"")}>
                                        <i className="icon-clock"></i>&nbsp;{strTime}</div>
                                    )
                                })
                            }
                        </div>
                    </Card>

                    <Card className="pl-3 pr-3 pb-3">
                        <Row className="toolbar up-card-title">
                            <div className="b-title"><i className="icon-paper-plane mr-10"></i>&nbsp;Rest breaks
                            {/* <span className="b-title-in">(Click to change)</span> */}
                            </div>
                        </Row>
                        <div className="height-10"></div>
                        <div className="d-flex flex-wrap">
                            {
                                restBreak.map((prop, key) => {
                                    var time = new Date(parseInt(targetOffset != null ? prop.time + targetOffset * 60 * 1000 : prop.time))
                                    var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                                        + ":"
                                        + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
                                    var d1 = new Date()
                                    var now = new Date(d1);
                                    if (targetOffset != null) {
                                        now.setMinutes(d1.getMinutes() + currentOffset - dutyTimeOffset);
                                    }
                                    prop.isPast = false;
                                    if (now - time > 0)
                                        prop.inPast = true;
                                    return (<div
                                        // onClick={() => this.toggleTrackStatus(prop, key)}
                                        style={{ cursor: "pointer" }}
                                        className={cx(prop.confirmed == 1 ? "alert-confirm" :
                                            (now - time > 0) ? "alert-missed" : "alert-remain" 
                                            // prop.editHistory.length>0?"hasHistory":""
                                            )}>
                                        <i className="icon-clock"></i>&nbsp;{strTime}</div>
                                    )
                                })
                            }
                        </div>
                    </Card>
                </div>
            </Row>
            <Card>
                <Row className="toolbar up-card-title">
                    <div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Location Details</div>
                </Row>
                <Row className="toolbar up-card-box">
                    {Object.keys(locationData).length != 0 ? <div className="col-md-5">
                        <Row className="company-text-area">
                            <div className="company-text-key">NAME</div>
                            <div className="company-text-value">{locationData.name}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">TIMEZONE</div>
                            <div className="company-text-value">{locationData.timezone}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">ADDRESS</div>
                            <div className="company-text-value">{locationData.address}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">LATITUDE</div>
                            <div className="company-text-value">{locationData.latitude}</div>
                        </Row>
                        <Row className="company-text-area">
                            <div className="company-text-key">LONGITUDE</div>
                            <div className="company-text-value">{locationData.longitude}</div>
                        </Row>
                    </div> : <div className="col-md-5 center-center">
                        <CirclesWithBar
                            height="25"
                            width="25"
                            radius="3"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                        <div>Loading Location Information</div>
                    </div>}
                    <div className="col-md-7 pd-20">
                        <div style={{ height: '500px', width: '100%', position: 'relative' }}>
                            <MyComponent mapCenter={Object.keys(locationData).length != 0 ? {
                                lat: parseFloat(locationData.latitude),
                                lng: parseFloat(locationData.longitude)
                            } : { lat: -3.745, lng: -38.523 }}
                                // key={this.state.mapId}
                                markers={this.state.markers}
                                guardMarkers={this.state.guardMarkers.sort((a, b) => a.time - b.time)}
                                targetOffset={this.state.targetOffset}
                            />
                            {/* <MapWithCountries/> */}
                        </div>
                    </div>
                </Row>
            </Card>
            <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
                <ModalBody className="alert-body">
                    <i className="mdi mdi-alert-circle ft-60"></i>
                    <h2>{this.state.alertMessage}</h2>
                    <div className="height-10"></div>
                    <FormGroup>
                        <Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
                    </FormGroup>
                </ModalBody>
            </Modal>

            <Modal isOpen={this.state.editTrackStatus} toggle={this.toggleTrackStatus}>
                {/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
                <ModalBody className="alert-body">
                    <EditTrackStatus toggleTrackStatus={this.toggleTrackStatus}
                        alertData={this.state.selectedAlert}
                        changeStatus={this.changeStatus}
                        />
                </ModalBody>
            </Modal>

            {/*--------------------------------------------------------------------------------*/}
            {/* End Action table*/}
            {/*--------------------------------------------------------------------------------*/}
        </div>
    }
}

export default DutyDetail;
