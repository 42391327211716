import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button,
	Modal, ModalBody, FormGroup
} from 'reactstrap';
// import { auth } from '../../firebase';
import { auth } from '../../Api'
import * as authRedux from "../../redux/auth/action";
import validators from './validators';

import img2 from '../../assets/images/big/auth-bg.jpg';
import imgLogin01 from '../../assets/images/login-01.jpg'
import imgLogin02 from '../../assets/images/login-02.jpg'
import appLogo from '../../assets/images/login-03.png'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const sidebarBackground = {
	// backgroundImage: "url(" + img2 + ")",
	// backgroundRepeat: "no-repeat",
	// backgroundPosition: "center center"
};

class Login2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: 'test@gmail.com',
			password: 'test',
			error: null,
			showAlert: false,
		};
		this.toggle = this.toggle.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.validators = validators;
		this.onInputChange = this.onInputChange.bind(this);
		this.doLogin = this.doLogin.bind(this);
		this.doLoginServer = this.doLoginServer.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.formValidators = this.formValidators.bind(this);
	}
	toggle() {
		this.setState({
			showAlert: !this.state.showAlert
		});
	}
	onInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		this.formValidators([event.target.name], event.target.value);
	}
	formValidators(fieldName, value) {
		this.validators[fieldName].errors = [];
		this.validators[fieldName].state = value;
		this.validators[fieldName].valid = true;
		this.validators[fieldName].rules.forEach((rule) => {
			if (rule.test instanceof RegExp) {
				if (!rule.test.test(value)) {
					this.validators[fieldName].errors.push(rule.message);
					this.validators[fieldName].valid = false;
				}
			} else if (typeof rule.test === 'function') {
				if (!rule.test(value)) {
					this.validators[fieldName].errors.push(rule.message);
					this.validators[fieldName].valid = false;
				}
			}
		});
	}
	validForm() {
		let status = true;
		Object.keys(this.validators).forEach((field) => {
			if (field === 'email' || field === 'password') {
				if (!this.validators[field].valid) {
					status = false;
				}
			}
		});
		return status;
	}
	showErrors(fieldName) {
		const validator = this.validators[fieldName];
		const result = '';
		if (validator && !validator.valid) {
			const errors = validator.errors.map((info, index) => {
				return <span className="error" key={index}>* {info}<br /></span>
			});
			return (
				<div className="error mb-2">
					{errors}
				</div>
			);
		}
		return result;
	}
	doLoginServer(event) {
		const { email, password } = this.state;
		let req = {}
		req.email = email
		req.password = password
		// req.is_customer = true
		auth.signin(req)
			.then((res) => {
				this.setState(() => ({
					email: email,
					password: password,
				}));
				if (res.data.code == 200) {
					console.log("res.data.data:", res.data.data)
					localStorage.setItem('access_token', res.data.data.token);
					localStorage.setItem('mo_user', JSON.stringify(res.data.data));
					this.props.saveToken({ token: res.data.data.token })
					localStorage.setItem('admin_level', res.data.data.level)
					localStorage.setItem('admin_id', res.data.data.id)
					if(res.data.data.level=="c"){
						localStorage.setItem("companyId",res.data.data.id)
						console.log("res.data.data.name:", res.data.data.name)
						localStorage.setItem('companyName',res.data.data.name)
						localStorage.setItem('companyInfo', JSON.stringify(res.data.data))
						this.props.history.push("/company-dashboard");
					}else{
						this.props.history.push("/");
					}
				}
				if (res.data.code == 401) {
					this.setState({
						showAlert: true,
						alertMessage: res.data.message.error
					})
				}
			})
			.catch(error => {
				alert(error)
			});
	}
	doLogin(event) {
		const {
			email,
			password
		} = this.state;
		auth.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				this.setState(() => ({
					email: email,
					password: password,
				}));
				this.props.history.push("/");
			})
			.catch(error => {
				alert('Invalid login id or password.');
			});
		event.preventDefault();
	}
	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	render() {
		return (
			<div>
				<div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
					<div className="container">
						<div className="login-cover">
							<div>
								<div>
									<img src={appLogo} style={{ width: "100%" }}></img>
								</div>
								<div className="row sub-title">
									<div className='col-md-4 col-12 center-center sub-title'>www.mobiliis.com</div>
									<div className='col-md-4 col-12 center-center sub-title-middle'>Mobile Eyes for the Mobile Age</div>
									<div className='col-md-4 col-12 center-center sub-title'>admin@mobiliis.com</div>
								</div>
								<hr></hr>
							</div>
							<div>
								<Row className="no-gutters justify-content-center">
									<div className="p-4">
										<h3 className="font-medium mb-3">Sign In to Admin</h3>
										<Form className="mt-3">
											<Label for="email" className="font-medium">Email</Label>
											<InputGroup className="mb-2" size="lg">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ti-user"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input type="email" id="email" name="email" value={this.state.email} onChange={this.onInputChange} placeholder="Email" />
											</InputGroup>
											{this.showErrors('email')}
											<Label for="password" className="mt-3 font-medium">Password</Label>
											<InputGroup className="mb-3" size="lg">
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="ti-pencil"></i>
													</InputGroupText>
												</InputGroupAddon>
												<Input type="password" id="password" name="password" value={this.state.password} onChange={this.onInputChange} placeholder="Password" />
											</InputGroup>
											{this.showErrors('password')}
											{/* <div className="d-flex no-block align-items-center mb-4 mt-4">
												<CustomInput type="checkbox" id="exampleCustomCheckbox" label="Remember Me" />
											</div> */}
											<Row className="mb-3">
												<Col xs="12">
													{/* <Button style={{backgroundColor:'#0f60e6'}} onClick={this.doLogin} className={`${this.validForm() ? '' : 'disabled'}`} size="lg" type="submit" block>Log In</Button> */}
													<Button style={{ backgroundColor: '#0f60e6' }} onClick={this.doLoginServer} className={`${this.validForm() ? '' : 'disabled'}`} size="lg" block>Log In</Button>
												</Col>
											</Row>
											<div className="text-center">
												<a href="/authentication/forgot-password" className="text-info ml-1"><b>Forgot Password</b></a>
											</div>
										</Form>
									</div>
								</Row>
							</div>
							<div className='center-center'><div className='sub-title'>Return to <a href='https://mobiliis.com'target="_self"><span className='sub-title-middle'>Mobiliis Home</span></a></div></div>
						</div>
					</div>
				</div>
				<Modal isOpen={this.state.showAlert} toggle={this.toggle}>
					<ModalBody className="alert-body">
						<i className="mdi mdi-alert-circle ft-60"></i>
						<h2>{this.state.alertMessage}</h2>
						<div className="height-10"></div>
						<FormGroup>
							<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
							<span>&nbsp;&nbsp;</span>
							<Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
						</FormGroup>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		isAuthenticated: auth.isAuthenticated
	}
}

const mapDispatchToProps = (dispatch) => ({

	// setLoading: (val) => dispatch(setting.setLoading(val)),
	emailConfirmAction: (state) => dispatch(authRedux.emailConfirmAction(state)),
	// signin: (data) => api.signin(data),
	saveToken: (token) => dispatch(authRedux.saveToken(token)),
	getMeSuccess: (token) => dispatch(authRedux.getMeSuccess(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login2));
