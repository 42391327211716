import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
	ButtonGroup,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import classnames from 'classnames';
import { auth } from '../../Api';
import { getName } from 'country-list';
import { baseURL, baseImgURL } from '../../Api/BaseApi';
import { DatePicker, version } from "antd";
import "antd/dist/antd.css";
import moment from "moment";

const { RangePicker } = DatePicker;

class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],

			data: data.makeData(),
			treedata: data.treedata,
			activeTab: '1',
			companyName: '',
			avatarUrl: '',
			hitAccuracy: {},
			startStr: '',
			endStr: '',
			usage: 0.0,
			sysWidth: window.innerWidth
		};
		this.toggle = this.toggle.bind(this);
	}

	handleDatePicker = () => {
		const { id } = this.props.match.params
		var { startStr, endStr, openDatePicker } = this.state;
		this.setState({ openDatePicker: !this.state.openDatePicker });
		console.log("sss,,:", this.state.openDatePicker)
		if (openDatePicker && startStr != "" && endStr != "") {
			var data = auth.getGuardUsage({
				'guardID': id,
				'start': startStr,
				'end': endStr
			}).then((res) => {
				console.log(res);
				if (res.data.code == 200)
					this.setState({
						usage: res.data.Result,
					})
			})
		}
	};

	handleJustOpenDatePicker = () => {
		this.setState({ openDatePicker: true });
	};

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	componentDidMount() {
		const { id } = this.props.match.params
		let companyName = localStorage.getItem('companyName')
		var req = {}
		req.id = id
		auth.getGuard(req)
			.then((res) => {
				if (res.data.code == 200)
					this.setState({
						jsonData: res.data.message.users,
						avatarUrl: res.data.message.users[0].avatar,
						companyName: companyName
					})
			})
		auth.getHitAccuracy(id)
			.then((res) => {
				if (res.data.code == 200)
					console.log("hitAccuracy:", res.data.data)
				this.setState({
					hitAccuracy: res.data.data
				})
			})
	}


	toggle2(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab': tab
			});
		}
	}
	handleChange = (checked) => {
		console.log("checked:", checked)
		this.setState({ checked });
	}

	render() {
		const { jsonData, companyName, avatarUrl, hitAccuracy, usage } = this.state;
		console.log("this.state.sysWidth========:",this.state.sysWidth)
		return <div>
			<Card>
				<CompanyMenu></CompanyMenu>
				{jsonData.length != 0 ?
					<div>
						<Row className="toolbar up-card-title">
							<div className="b-title"><i className="mdi mdi-buffer mr-10"></i>&nbsp;Guard Information - {companyName}
								<span className="b-title-in" onClick={() => { this.props.history.push('/guard-edit/' + jsonData[0].id) }} style={{ cursor: "pointer" }}>(Edit)</span></div>
							<Button onClick={() => this.props.history.push('/duty/' + jsonData[0].id)}>
								View Guard Duties&nbsp;<i className="ti-align-left"></i>
							</Button>
						</Row>
						<Row className="toolbar">
							<Row className="toolbar up-card-box col-lg-6 col-md-12">
								<div className="pl-3 pt-3"><img src={avatarUrl != "" ? avatarUrl == "/storage/avatar.jpg" ? avatar : baseImgURL + avatarUrl : avatar} className="img-company"></img></div>
								<div>
									<Row className="company-text-area">
										<div className="company-text-key">FirstName</div>
										<div className="company-text-value">{jsonData[0].firstname}</div>
									</Row>

									<Row className="company-text-area">
										<div className="company-text-key">SecondName</div>
										<div className="company-text-value">{jsonData[0].secondname}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Phone</div>
										<div className="company-text-value">{jsonData[0].phone}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Active Company</div>
										<div className="company-text-value">{jsonData[0].company_name}</div>
									</Row>
								</div>
							</Row>
							<Row className="toolbar up-card-box col-lg-3 col-md-6 col-sm-12 col-12">
								<div>
									<Row className="company-text-area">
										<div className="company-text-key">Guard Ave % hits</div>
										<div className="company-rank-value">{hitAccuracy.guardAve}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Company Ave % hits</div>
										<div className="company-rank-value">{hitAccuracy.companyAve}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Guard Company Rank</div>
										<div className="company-rank-value">{hitAccuracy.companyRank + "/" + hitAccuracy.companyGuards}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Mobiliis Ave % hits</div>
										<div className="company-rank-value">{hitAccuracy.totalAve}</div>
									</Row>
									<Row className="company-text-area">
										<div className="company-text-key">Guard Mobiliis Rank</div>
										<div className="company-rank-value">{hitAccuracy.totalRank + "/" + hitAccuracy.totalGuards}</div>
									</Row>
								</div>
							</Row>
							<Row className="toolbar up-card-box col-lg-3 col-md-6 col-sm-12 col-12" style={{ padding: '10px' }}>
								{
									this.state.sysWidth>567?<Row className='toolbar space-between'>
									<div className='align-center'>Data Usage: {usage}MB</div>
									<div style={{ "width": "5px", "height": "5px" }}></div>
									<Button onClick={this.handleDatePicker}>
										{
											this.state.openDatePicker ? "Calculate usage" : "Select Range"
										}
									</Button>
								</Row>:<></>
								}
								<div style={{ "width": "100%", "height": "5px" }}></div>
								<div onClick={this.handleJustOpenDatePicker}>
									<RangePicker
										popupClassName="range-picker-mobiliis"
										defaultValue={moment()}
										open={this.state.openDatePicker}
										onChange={(dates, dateString) => {
											this.setState({
												startStr: dateString[0],
												endStr: dateString[1]
											})
											console.log(dates, dateString)
										}}
									/>
								</div>
								<div style={{ "width": "100%", "height": "5px" }}></div>
								{
									this.state.sysWidth<=567?<Row className='toolbar space-between'>
									<div className='align-center'>Data Usage: {usage}MB</div>
									<div style={{ "width": "5px", "height": "5px" }}></div>
									<Button onClick={this.handleDatePicker}>
										{
											this.state.openDatePicker ? "Calculate usage" : "Select Range"
										}
									</Button>
								</Row>:<></>
								}
								<div>&nbsp;</div>

							</Row>
						</Row>

					</div> :
					<div>Loading</div>}
			</Card>
		</div>
	}
}

export default CompanyView;
