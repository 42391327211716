import React from 'react';
import indexRoutes from './routes/';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import RequireNotAuth from './hoc/RequireNotAuth';
import LandingLayout from './layouts/landingLayout'
import Blanklayout from './layouts/blanklayout';

const App = () => (
    <Provider store={configureStore()}>
        <Router basename="/">
            <Switch>
                
                {/* {
                    indexRoutes.map((prop, key) => {
                        return <Route path={prop.path} key={key} component={prop.component} />;
                        // return <Route
                        //     {...rest}
                        //     render={props =>
                        //     isAuthenticated ? (
                        //         <Component {...props} />
                        //     ) : (
                        //         <Redirect to="/" />
                        //     )
                        //     }   
                        // />;
                    })
                } */}
                <Route path="/authentication" component = {Blanklayout}/>
                <Route path="/" component= {RequireNotAuth(LandingLayout)} />
            </Switch>
        </Router>
    </Provider>
);

export default App;