import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import { auth } from '../../Api'
import { getName } from 'country-list';
import { baseURL,baseImgURL } from '../../Api/BaseApi';

class ClientInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			obj: {},
			jsonData: [],
			guardData: [],
            locationData: [],
            clientId:"null",
			data: data.makeData(),
			treedata: data.treedata
		};
	}

	componentDidMount(){
		const {id} = this.props.match.params
        console.log("id:",id)
        this.setState({clientId:id})
        var req={}
        req.id=id
		var that = this
        auth.getClient(req)
            .then((res)=>{
				console.log("res:",res)
			if(res.data.code==200){
			that.setState({
				jsonData:res.data.message.users,
				guardData:res.data.message.guards,
                locationData: res.data.message.locations,
			})
			}
            })
		}
	handleChange (index) {
		console.log("checked:",index)
		let {guardData} = this.state; 
		guardData[index].active=!guardData[index].active
		console.log(guardData[index].active)
		this.setState({ guardData });
	  }

	render() {
		const { data,jsonData,guardData,locationData,clientId} = this.state;
        const guardData2 = guardData.map((prop, key) => {
			return {
				id: key,
				firstname: <div onClick={()=>this.props.history.push('/guard-info/'+prop.id)} style={{cursor:"pointer"}}>{prop.firstname}</div>,
				secondname: prop.secondname,
				CID: prop.CID,
                actions:(
                    <div className="text-center">
						<Switch onChange={()=>this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active} />
					</div>
                )
			};
		});
        const locationData2 = locationData.map((prop, key) => {
			return {
				id: key,
				name: <div onClick={()=>this.props.history.push('/duty/'+prop.id+'/null')} style={{cursor:"pointer"}}>{prop.name}</div>,
                timezone: prop.timezone
			};
		});
		return <div>
			<Card>
                <CompanyMenu></CompanyMenu>
            </Card>
            <div className="space-around">
                <Card className="pl-3 pr-3 pb-3">
                    <Row className="toolbar up-card-title">
                        <div className="b-title"><i className="mr-2 mdi mdi-account-alert mr-10"></i>&nbsp;{jsonData.length!=0?(jsonData[0].name+" "+jsonData[0].sname):"Client Name"}
                        <a onClick={()=>this.props.history.push('/client-edit/'+clientId)} style={{cursor:"pointer"}}><span className="b-title-in">(Edit)</span></a></div>
                    </Row>
                    {
                    jsonData.length!=0?<Row className="toolbar up-card-box">
                        <div><img src = {baseImgURL+jsonData[0].avatar} className="img-company"></img></div>
                        <div>
                            <Row className="company-text-area">
                                <div className="company-text-key">PHONE</div>
                                <div className="company-text-value">{jsonData[0].phone}</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">EMAIL</div>
                                <div className="company-text-value">{jsonData[0].email}</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">ADDRESS</div>
                                <div className="company-text-value">{jsonData[0].pobox}</div>
                            </Row>
                            <Row className="company-text-area">
                                <div className="company-text-key">COMPANY</div>
                                <div className="company-text-value">{jsonData[0].company}</div>
                            </Row>
                        </div>
                    </Row>:<div></div>
                    }
                </Card>
                <Row>
                    <div className="col-md-6">
                        <Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="mr-2 mdi mdi-account-alert mr-10"></i>&nbsp;Locations</div>
                            </Row>
                            <div className="height-10"></div>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "Location Alias",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Timezone",
                                        accessor: "timezone"
                                    }
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                data={locationData2}
                                // filterable
                            />
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="icon-shield mr-10"></i>&nbsp;Guards</div>
                            </Row>
                            <div className="height-10"></div>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "First Name",
                                        accessor: "firstname"
                                    },
                                    {
                                        Header: "Last Name",
                                        accessor: "secondname"
                                    },
                                    {
                                        Header: "CID",
                                        accessor: "CID"
                                    },
                                    {
                                        Header: "Guard Status",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                data={guardData2}
                                // filterable
                            />
                        </Card>
                    </div>
                </Row>
            </div>
            {/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
    
}

export default ClientInfo;
