import React, { useEffect, useState, useRef } from 'react'
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import blueDot from '../../assets/images/circle-blue.png'
import redDot from '../../assets/images/circle-red.png'
import homeMarker from '../../assets/images/home.png'
import greenMan from '../../assets/images/pin-green.png'
import blueMan from '../../assets/images/pin-blue.png'
import redMan from '../../assets/images/pin-red.png'
import iconPlay from '../../assets/images/icon_play.png'
import iconPause from '../../assets/images/icon_pause.png'
import iconReset from '../../assets/images/icon_reset.png'

const containerStyle = {
    width: '100%',
    height: '450px'
};

function MyComponent({ mapCenter, markers, guardMarkers, targetOffset }) {

    const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 })
    const [activeMarker, setActiveMarker] = useState(null);
    const [stepValue, setStepValue] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [minTime, setMinTime] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const mapRef = useRef();

    const onLoad = (map) => {
        console.log("Map Rendered");
        console.log(map);
        mapRef.current = map;
        setMapCenter()
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAKXMyJn1D2_AnCvqiAt7JKj77ZTD7N4Ys"
    })

    useEffect(() => {
        setCenter(mapCenter)
    }, [mapCenter]);

    useEffect(() => {
        console.log("isLoaded:", isLoaded, guardMarkers.length, mapRef.current)
        setMapCenter()
    }, [isLoaded, guardMarkers]);

    const setMapCenter = () => {
        if (isLoaded && guardMarkers.length && mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            var latSum = 0.0;
            var lngSum = 0.0;
            markers.forEach(({ position }) => bounds.extend({ id: position.id, 
                lat: parseFloat(position.lat), 
                lng: parseFloat(position.lng) }));
            guardMarkers.forEach((position) => {
                latSum += parseFloat(position.latitude)
                lngSum += parseFloat(position.longitude)
            });
            if (guardMarkers.length > 0) {
                latSum = latSum / guardMarkers.length
                lngSum = lngSum / guardMarkers.length
            }

            guardMarkers.forEach((position) => {
                if (Math.abs(latSum - position.latitude) < 1 && Math.abs(lngSum - position.longitude) < 1) {
                    bounds.extend({ id: position.id, lat: parseFloat(position.latitude), lng: parseFloat(position.longitude) })
                }
            });
            guardMarkers.sort((a, b) => (a.id > b.id) ? 1 : -1)

            setMinTime(0)
            setMaxTime(guardMarkers.length - 1)
            setStepValue(guardMarkers.length - 1)
            mapRef.current.fitBounds(bounds);
        }
    }

    const [map, setMap] = React.useState(null)

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleMarkerClick = (markerId) => {
        setActiveMarker(markerId);
    };

    const handleInfoWindowClose = () => {
        setActiveMarker(null);
    };

    useEffect(() => {
        let interval = null;
        if (playing) {
            interval = setInterval(() => {
                setStepValue((prevTime) => {
                    if (prevTime >= maxTime) { // Assuming max time value is 24
                        clearInterval(interval);
                        setPlaying(false); // Stop playing when max time is reached
                        return maxTime; // or 0 to loop
                    }
                    return prevTime + 1; // Increment time
                });
            }, 200); // Update time every 1 second
        } else if (!playing && stepValue !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [playing, stepValue]);

    const handlePlayClick = () => {
        if (stepValue == guardMarkers.length - 1) {
            setStepValue(0)
        }
        if (playing) {
            setPlaying(false)
        } else {
            setPlaying(true);
        }
    };

    const handleResetClick = () => {
        setStepValue(0);
        setPlaying(true);
    };

    const getPointTime = () => {
        if (guardMarkers.length < 2) {
            return <div>Time</div>
        } else {
            var guard = guardMarkers[stepValue];
            var time = new Date(parseInt(guard.time + targetOffset * 60 * 1000))
            var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                + ":"
                + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
            return <div>{strTime}</div>
        }
    }

    return isLoaded ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={9}
                onLoad={onLoad}
                onUnmount={onUnmount}
            // ref={mapRef.current}
            >
                { /* Child components, such as markers, info windows, etc. */}
                {markers.map((marker, index) => {
                    var time = new Date(parseInt(marker.time))
                    var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                        + ":"
                        + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
                    return (
                        <Marker
                            key={index.toString() + "home"}
                            title={marker.title}
                            name={strTime}
                            position={{ lat: parseFloat(marker.position.lat), lng: parseFloat(marker.position.lng) }}
                            icon={{
                                url: homeMarker,
                                scaledSize: new window.google.maps.Size(40, 40)
                            }}
                        />
                    )
                })}
                {guardMarkers.map((guard, index) => {
                    if (index > stepValue) return <></>
                    var time = new Date(parseInt(guard.time + targetOffset * 60 * 1000))
                    var strTime = (time.getHours() < 10 ? "0" + time.getHours() : time.getHours())
                        + ":"
                        + (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes());
                    return (
                        <Marker
                            key={index.toString() + 'point'}
                            id={guard.id}
                            position={{ lat: parseFloat(guard.latitude), lng: parseFloat(guard.longitude) }}
                            icon={{
                                url: index == 1 ? greenMan
                                    : index == (guardMarkers.length - 1) ? (guard.onlineStatus == 1 ? blueMan : redMan)
                                        : guard.onlineStatus == 1 ? (stepValue == index ? blueMan
                                            : blueDot) : (stepValue == index ? redMan : redDot)
                            }}
                            onClick={() => handleMarkerClick(guard.id)}
                            name={strTime}
                        >
                            {activeMarker === guard.id && (
                                <InfoWindow
                                    position={{ lat: parseFloat(guard.latitude), lng: parseFloat(guard.longitude) }}
                                    onCloseClick={handleInfoWindowClose}
                                >
                                    <div>
                                        <h4>{strTime}</h4>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    )
                })}
            </GoogleMap>
            <div style={{ padding: '20px' }} className='center-center'>
                <button className='play-button' onClick={handlePlayClick}><img src={playing ? iconPause : iconPlay} width={20} alt='' /></button>
                <button className='play-button' onClick={handleResetClick}><img src={iconReset} width={20} alt='' /></button>
                <input
                    style={{ width: '100%' }}
                    type="range"
                    min={minTime.toString()}
                    max={maxTime.toString()} // Assuming the slider represents hours in a day
                    value={stepValue}
                    onChange={(e) => setStepValue(Number(e.target.value))}
                />
                <div style={{ paddingLeft: '5px' }}>{getPointTime()}</div>

            </div>
        </>

    ) : <></>
}

export default React.memo(MyComponent)