import React,{ useState } from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import Select, {components} from 'react-select'
import countryList from 'react-select-country-list'
// import { SelectTimezone } from 'react-select-timezone';
import TimezoneSelect from 'react-timezone-select'
import GoogleMapReact from 'google-map-react';
import ImageUploader from 'react-images-upload';
import { baseURL,baseImgURL } from '../../Api/BaseApi';
import { auth } from '../../Api'
import { registerSupervisor } from '../../Api/ChildrenApi';
import { getName } from 'country-list';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);


export function CountryFlag(props) {
    return (
      <span
        className={"flag-icon flag-icon-" + props.code}
        style={{ fontSize: props.size || "30px" }}
      />
    );
  }
  
  export const CountryFlagSelectOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
          {props.label}
        </div>
      </components.Option>
    );
  };
  
  export const CountryFlagValueContainer = ({ children, ...props }) => {
    const code = (props.hasValue && props.getValue()[0].value) || false;
  
    return (
      <div style={{ display: "flex", flexGrow: 1 }}>
        {(code && <CountryFlag code={code.toLowerCase()} />) || null}
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      </div>
    );
  };
  
  const styles = {
    valueContainer: (base, state) => {
      const height = "35px";
      return { ...base, height };
    }
  };

class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: data.dataTable,
			data: data.makeData(),
			treedata: data.treedata,
			options: countryList().getData(),
			firstName: '',
			secondName:'',
			CID:'',
			phoneValue:'',
			timeValue: '',
			countryValue:null,
			emailValue: 'com@com.com',
			avatarUrl:'/storage/avatar.jpg',
			center: {
				lat: 59.95,
				lng: 30.33
			},
			zoom: 11,
			districtLst:[],
			districtOption: null,
			managerLst:[],
			managerOption: null,
			supervisorLst: [],
			supervisorOption: null,
			clientLst: [],
			clientOption: null,
			showAlert:false,
			showResult:false,
			pictures: [],
			guardId:"null"
		};
		this.toggle = this.toggle.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}
	
	toggle() {
		this.setState({
		  showAlert: !this.state.showAlert
		});
	  }

	componentDidMount(){
		const {id} = this.props.match.params
		let companyId = localStorage.getItem('companyId')
		let {districtLst} = this.state
		this.setState({
			guardId:id,
			company_id:companyId
		})
		var req={}
		req.company_id = companyId
		auth.getDistrict(req)
			.then((res)=>{
				districtLst=[]
				if(res.data.code==200){
					res.data.message.users.forEach(element => {
					districtLst.push({value:element.id.toString(),label:element.name})
					});
				this.setState({districtLst})
				}
			})
		if(id!="null"){
			req={}
			req.id = id
			auth.getGuard(req)
				.then((res)=>{
					let guardData = res.data.message.users[0]
					this.setState({
						firstName:guardData.firstname,
						secondName:guardData.secondname,
						CID:guardData.CID,
						phoneValue:guardData.phone,
						districtOption:{value:guardData.district_id,label:guardData.district_name},
						managerOption:{value:guardData.districtmanager_id,label:guardData.districtmanager_name},
						supervisorOption:{value:guardData.supervisor_id,label:guardData.supervisor_name},
						avatarUrl:guardData.avatar
					})
					if(guardData.supervisor_id!=null){
						var req = {}
						req.supervisor_id=guardData.supervisor_id
					}
				})
		}
	}

	districtChange=(e)=>{
		let { managerLst} = this.state
		this.setState({
			districtOption:e,
		})
		var district_id = e.value
		var req={}
		req.district_id=district_id

		auth.getDistrictManager(req)
			.then((res)=>{
				managerLst=[]
				if(res.data.code==200){
					res.data.message.users.forEach(element => {
						managerLst.push({value:element.id,label:element.name})
					})
				}
				this.setState({managerLst})
			})
	  }
	managerChange=(e)=>{
		let {supervisorLst} = this.state
		this.setState({
			managerOption:e,
		})
		var districtmanager_id = e.value
		var req={}
		req.districtmanager_id=districtmanager_id

		auth.getSupervisor(req)
			.then((res)=>{
				supervisorLst=[]
				if(res.data.code==200){
					res.data.message.users.forEach(element => {
						supervisorLst.push({value:element.id,label:element.firstname})
					})
				}
				this.setState({supervisorLst})
			})
		console.log("e.managerID:",e)
	}
	supervisorChange=(e)=>{
		
		this.setState({
			supervisorOption:e,
		})
		var req={}
		req.supervisor_id = e.value
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		this.setState({
			phoneValue: event
		})
	}
	
    handleChange = (event)=>{
		console.log(["event.valu:",event.target.value, event.target.name])
		switch(event.target.name){
		  	case "firstName":
				this.setState({
				firstName:event.target.value
				})
				break;
			case "secondName":
				this.setState({
				secondName:event.target.value
				})
				break;
			case "CID":
				this.setState({
				CID:event.target.value
				})
				break;
			case "phone":
				this.setState({
				phoneValue:event.target.value
				})
				break;
		}
	  }
	onSave = (event) =>{
		let {emailValue,phoneValue,countryValue,firstName,secondName,CID,
			password,avatarUrl,company_id,timeValue,
			districtOption,managerOption,supervisorOption,clientOption,guardId
		} = this.state
		this.setState({showResult:false});
		var req={}
		req.firstname = firstName
		req.secondname = secondName
		req.CID = CID
		req.phone = phoneValue
		req.email = emailValue
		// req.timezone = timeValue.label
		req.timezone = "none"
		req.company_id = company_id
		if(avatarUrl!="")
		  req.avatar = avatarUrl
		req.countrycode='CN'
		if(districtOption!=null)
		  {
			req.district = districtOption.label  
			req.district_id = districtOption.value
		}else{
			req.district_id = -1;
		}
		
		if(managerOption!=null){
			req.districtmanager_id = managerOption.value
		}else{
			req.districtmanager_id = -1;
		}
		if(supervisorOption!=null){
			req.supervisor_id = supervisorOption.value
		}else{
			req.supervisor_id = -1;
		}

		if(clientOption!=null){
			req.client_id = clientOption.value
		}else{
			req.client_id = -1;
		}
		var that = this;
		let alertMessage = "";
		if(districtOption==null){
		  alertMessage='Please select District'
		  that.setState({
			showAlert:true,
			showResult:false,
			alertMessage:alertMessage
		  })
		}
		else if(managerOption==null){
			alertMessage='Please select District Manager'
			that.setState({
			  showAlert:true,
			  showResult:false,
			  alertMessage:alertMessage
			})
		  }
		else if(supervisorOption==null){
			alertMessage='Please select Supervisor'
			that.setState({
			  showAlert:true,
			  showResult:false,
			  alertMessage:alertMessage
			})
		  }
		else
		{
			if(guardId!="null"){
				req.id = guardId
				auth.updateGuard(req)
					.then((res)=>{
						if(res.data.code=201)
					{
					console.log(["res.data.message:",res.data.message]);
					alertMessage='Updated Successfully'
					that.setState({
						showAlert:true,
						showResult:true,
						alertMessage:alertMessage
					})}
				})
			}
			else
			auth.registerGuard(req)
				.then((res)=>{
				console.log("auth.register's res:",res);
				if(res.data.message!=null){
					if(res.data.code==400)
					{
					if(res.data.message.firstname!=null){
						alertMessage+='The firstname already exists.\n'
					}
					if(res.data.message.phone!=null){
						alertMessage+='This phone number already exists\n'
					}
					console.log(["res.data.message:",res.data.message,alertMessage]);
					that.setState({
						showAlert:true,
						showResult:false,
						alertMessage:alertMessage
					})
					console.log(["res.data.message:",alertMessage,that.state.alertMessage]);
					}
					else if(res.data.code=201)
					{
					console.log(["res.data.message:",res.data.message]);
					alertMessage='Created Successfully'
					that.setState({
						showAlert:true,
						showResult:true,
						alertMessage:alertMessage
					})
				}
				}
				});}
		event.preventDefault();
	  }

	onDrop(picture) {
		var req = {}
		req.file = picture[0]
		console.log("req:",req);
		var that = this;
		auth.upload(req)
		  .then((res)=>{
			if(res.data.code==201){
				var avatarL = res.data.url.split("/")
				that.setState({
				  avatarUrl: avatarL[avatarL.length-1]
				})
			}
		  })
		this.setState({
			pictures: this.state.pictures.concat(picture),
		});
	}                                 

	render() {
		const { avatarUrl, districtOption, districtLst, managerLst, managerOption,
			supervisorLst,supervisorOption,clientLst,guardId } = this.state;
        
        const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]

		return <div>
			<Card>
                <CompanyMenu></CompanyMenu>
                <Row className="toolbar up-card-title">
                    <div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Guard Information</div>
                    {guardId!="null"?
						<a onClick={()=>{this.props.history.push('/duty/'+guardId)}}>
                        <div className="b-title-end">View Location Duties&nbsp;<i className="ti-align-left"></i></div>
                    </a>:<div></div>}
                </Row>
                <Row className="toolbar up-card-box justify-content-center">
					<div className="col-md-3" style={{textAlign:"center"}}>
						<img src = {avatarUrl!=""?avatarUrl=="/storage/avatar.jpg"?avatar:baseImgURL+avatarUrl:avatar} className="img-company"></img>
						<Form>
							<FormGroup>
								{/* <Input type="file" name="file" id="exampleFile" onChange={this.onDrop} /> */}
								<ImageUploader
									withIcon={false}
									buttonText='Choose images'
									onChange={this.onDrop}
									imgExtension={['.jpg', '.gif', '.png']}
									maxFileSize={5242880}
									singleImage={true}
								/>
							</FormGroup>
						</Form>
					</div>
                    <form className="col-md-7" name="myForm" onSubmit={this.onSave}>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">First Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="firstName" type="text" value={this.state.firstName} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Second Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="secondName" type="text" value={this.state.secondName} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">CID</label>
							<div className="col-md-9">
								<input className="location-text-input" name="CID" type="text" value={this.state.CID} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Phone</label>
							<div className="col-md-9">
								<PhoneInput
									placeholder="Enter phone number"
									value={this.state.phoneValue}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</div>
						</Row>
						<Row className="company-text-area">
							<label className="col-md-3 control-label">District</label>
							<div className="col-md-9">
								<Select name="district_id" options={districtLst} value = {districtOption}
								onChange={this.districtChange} required/>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">District Manager</label>
							<div className="col-md-9">
								<Select options={managerLst} value={managerOption} onChange = {this.managerChange}></Select>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Supervisor</label>
							<div className="col-md-9">
								<Select options={supervisorLst} value={supervisorOption} onChange = {this.supervisorChange}></Select>
							</div>
						</Row>
						<div className="height-10"></div>
						<Row className="d-flex justify-content-center">
							<Button type = "submit" className = "saveButton">Save Change</Button>
						</Row>
						<div className="height-10"></div>
					</form>
                </Row>
            </Card>
			<Modal isOpen={this.state.showAlert} toggle={this.toggle}>
			{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{width:'100px'}} className="ml-1" onClick={this.toggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{width:'100px'}} onClick={this.toggle}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
		</div>
	}
}

export default CompanyView;
