import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import classnames from 'classnames';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './billing.scss'

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: data.dataTable,
			data: data.makeData(),
			treedata: data.treedata,
			'activeTab': '1',
			dateValue: [new Date(), new Date()],
			companyName: ''
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		let companyName = localStorage.getItem('companyName')
		this.setState({
			companyName: companyName
		})
	}
	onDatePickerChange(value) {
		this.setState({
			dateValue: value
		})
	}
	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	toggle2(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				'activeTab': tab
			});
		}
	}
	handleChange = (checked) => {
		console.log("checked:", checked)
		this.setState({ checked });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		let location = event.target.location.value;
		let age = event.target.age.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name, designation, location, age];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal
		})
	}

	render() {
		const { data, companyName } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				name: (
					<a href="/company/company-view">{prop[0]}</a>
				),
				designation: prop[1],
				location: prop[2],
				age: prop[3],
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
								console.log(["key:",key,obj.name]);
								this.setState({
									modal: !this.state.modal,
									obj: obj,
									
								});
							}}
							color="inverse"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button> */}

						{/* use this button to remove the data row */}
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit: (
					<div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,

							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "green" }} /></div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "red" }} /></div>
					</div>
				),
			};
		});
		const items = [
			{
				id: 0,
				name: 'Cobol'
			},
			{
				id: 1,
				name: 'JavaScript'
			},
			{
				id: 2,
				name: 'Basic'
			},
			{
				id: 3,
				name: 'PHP'
			},
			{
				id: 4,
				name: 'Java'
			}
		]

		const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		}

		const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		}

		const handleOnFocus = () => {
			console.log('Focused')
		}
		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" defaultValue={this.state.obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Designation</Label>
							<Input type="text" name="designation" id="designation" defaultValue={this.state.obj.designation} />
						</FormGroup>
						<FormGroup>
							<Label for="location">Location</Label>
							<Input type="text" name="location" id="location" defaultValue={this.state.obj.location} />
						</FormGroup>
						<FormGroup>
							<Label for="age">Age</Label>
							<Input type="text" name="age" id="age" defaultValue={this.state.obj.age} />
						</FormGroup>
						<FormGroup>
							<Button color="primary" type="submit">Save</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
			<div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-euro-sign"></i><span> Billing - {companyName}</span></div>
						<div style={{ width: 400 }} className="auto-complete">
							<ReactSearchAutocomplete
								items={items}
								onSearch={handleOnSearch}
								onSelect={handleOnSelect}
								onFocus={handleOnFocus}
								autoFocus
							/>
						</div>
						<DateRangePicker
							onChange={(value) => this.onDatePickerChange(value)}
							value={this.state.dateValue}
						/>
					</Row>
					<div className="nav-header-cus">
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({
										'active': this.state.activeTab === '1'
									})}
									onClick={() => {
										this.toggle2('1');
									}}
								>
									Paid
                                </NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({
										'active': this.state.activeTab === '2'
									})}
									onClick={() => {
										this.toggle2('2');
									}}
								>
									Unpaid
                                </NavLink>
							</NavItem>
						</Nav>
					</div>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							<ReactTable
								columns={[
									{
										Header: "No",
										id: "row",
										maxWidth: 50,
										filterable: false,
										Cell: (row) => {
											return <div>{row.index + 1}</div>;
										}
									},
									{
										Header: "Duty Name",
										accessor: "name"
									},

									{
										Header: "Duty Start Date & Time",
										accessor: "location"
									},

									{
										Header: "Paid Status",
										accessor: "actions",
										sortable: false,
										filterable: false
									},


								]}
								defaultPageSize={10}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data2}
							// filterable
							/>
						</TabPane>
						<TabPane tabId="2">
							<ReactTable
								columns={[
									{
										Header: "No",
										id: "row",
										maxWidth: 50,
										filterable: false,
										Cell: (row) => {
											return <div>{row.index + 1}</div>;
										}
									},
									{
										Header: "Duty Name",
										accessor: "name"
									},

									{
										Header: "Duty Start Date & Time",
										accessor: "location"
									},

									{
										Header: "Paid Status",
										accessor: "actions",
										sortable: false,
										filterable: false
									},
								]}
								defaultPageSize={5}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data2}
							// filterable
							/>
						</TabPane>
					</TabContent>

				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
