import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Col,
	Row,
	Input
} from 'reactstrap';
import * as S from '..';
import { Line } from 'react-chartjs-2';
import './part-data.scss'
import { color } from "highcharts";

//Line chart

class PartData extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			title:props.title,
			icon:props.icon,
			index: props.index,
			color: props.color,
			value: props.value
		}
	}
	render() {
		var {title,icon,value,color} = this.state
		console.log("value:",value);
		return (
			<Card>
				<CardBody className="border-top back-border" style={{backgroundColor:color}}>
					<Row>
						<Col xs ={3}>
							<div className="part-icon">
								<i className={icon}></i>
							</div>
						</Col>
						<Col  className="part-content">
								<p>{title}</p>
								<p className="number">{this.props.value}</p>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default PartData;
