import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
  Card,
  Button, Modal, ModalBody, FormGroup, Row,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import CompanyMenu from '../companies/company-menu'
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list'
// import { SelectTimezone } from 'react-select-timezone';
import TimezoneSelect from 'react-timezone-select'
import { auth } from '../../Api'
import { baseImgURL } from '../../Api/BaseApi';
import { getName } from 'country-list';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

export function CountryFlag(props) {
  return (
    <span
      className={"flag-icon flag-icon-" + props.code}
      style={{ fontSize: props.size || "30px" }}
    />
  );
}

export const CountryFlagSelectOption = props => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
        {props.label}
      </div>
    </components.Option>
  );
};

export const CountryFlagValueContainer = ({ children, ...props }) => {
  const code = (props.hasValue && props.getValue()[0].value) || false;

  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      {(code && <CountryFlag code={code.toLowerCase()} />) || null}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );
};

const styles = {
  valueContainer: (base, state) => {
    const height = "35px";
    return { ...base, height };
  }
};

class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      open: false,
      modal: false,
      showAlert: false,
      showResult: false,
      alertMessage: '',
      obj: {},
      jsonData: data.dataTable,
      data: data.makeData(),
      treedata: data.treedata,
      options: countryList().getData(),
      company_id: -1,
      nameValue: null,
      emailValue: null,
      phoneValue: null,
      countryValue: null,
      location: '',
      timeValue: '',
      center: {
        lat: 59.95,
        lng: 30.33
      },
      showAlert: false,
      showResult: false,
      zoom: 11,
      pictures: [],
      avatarUrl: "",
      districtLst: [],
      districtOption: null,
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {

    let { districtLst } = this.state
    var company_id = localStorage.getItem('companyId')
    const { id } = this.props.match.params
    this.setState({
      company_id: company_id,
      district_id: id
    })
    var req = {}
    if (id != "null") {
      req.id = id
      auth.getDistrict(req)
        .then((res) => {
          districtLst = []
          if (res.data.code == 200) {
            console.log("res.data.=====:", res)
            let districtData = res.data.message.users[0]
            this.setState({
              nameValue: districtData.name,
              phoneValue: districtData.phone,
              location: districtData.location,
              timeValue: { value: districtData.timezone, label: districtData.timezone }
            })
          }
        })
    }
  }
  toggle() {
    this.setState({
      showAlert: !this.state.showAlert
    });
  }

  onSave = (event) => {

    let { emailValue, phoneValue, countryValue, nameValue, avatarUrl,
      company_id, timeValue, location, district_id } = this.state
    this.setState({ showResult: false });
    var req = {}
    req.name = nameValue
    req.phone = phoneValue
    req.email = emailValue
    req.location = location
    req.company_id = company_id
    req.avatar = avatarUrl
    req.countrycode = "CN"
    req.timezone = "Pacific/Honolulu"

    var that = this;
    let alertMessage = "";
    console.log("req++++++++++:", req)
    if (district_id != "null") {
      req.id = district_id
      auth.updateDistrict(req)
        .then((res) => {
          if (res.data.code = 201) {
            console.log(["res.data.message:", res.data.message]);
            alertMessage = 'Updated Successfully'
            that.setState({
              showAlert: true,
              showResult: true,
              alertMessage: alertMessage
            })
          }
        })
    }
    else
      auth.registerDistrict(req)
        .then((res) => {
          console.log("auth.register's res:", res);

          if (res.data.message != null) {
            if (res.data.code == 400) {
              if (res.data.message.name != null) {
                alertMessage += 'The name already exists.\n'
              }
              if (res.data.message.phonenumber != null) {
                alertMessage += 'This phone number already exists\n'
              }
              console.log(["res.data.message:", res.data.message, alertMessage]);
              that.setState({
                showAlert: true,
                showResult: false,
                alertMessage: alertMessage
              })
              console.log(["res.data.message:", alertMessage, that.state.alertMessage]);
            }
            else if (res.data.code = 201) {
              console.log(["res.data.message:", res.data.message]);
              alertMessage = 'Created Successfully'
              that.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
              })
            }
          }
        });
    event.preventDefault();
  }

  setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		this.setState({
			phoneValue: event
		})
	}
  
  handleChange = (event) => {
    console.log(["event.valu:", event.target.value, event.target.name])
    switch (event.target.name) {
      case "district":
        this.setState({
          nameValue: event.target.value
        })
        break;
      case "email":
        this.setState({
          emailValue: event.target.value
        })
        break;
      case "phone":
        this.setState({
          phoneValue: event.target.value
        })
        break;
      case "location":
        this.setState({
          location: event.target.value
        })
        break;
      case "district_id":
        this.setState({
          district_id: event.target.value
        })
    }
  }
  districtChange = (e) => {
    this.setState({ districtOption: e })
    console.log("e.distirct:", e);
  }

  render() {
    let { districtLst, districtOption, avatarUrl } = this.state

    console.log("districtLst:", districtLst)

    return <div>
      <Card>
        <CompanyMenu></CompanyMenu>
        <Row className="toolbar up-card-title">
          <div className="b-title"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>&nbsp;District Information</div>
        </Row>
        <Row className="toolbar up-card-box justify-content-center">
          <form className="col-md-7" onSubmit={this.onSave}>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Name</label>
              <div className="col-md-9">
                <input className="location-text-input" name="district" type="text" value={this.state.nameValue} onChange={this.handleChange} required></input>
              </div>
            </Row>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Phone</label>
              <div className="col-md-9">
              <PhoneInput
									placeholder="Enter phone number"
									value={this.state.phoneValue}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
              </div>
            </Row>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Office Location</label>
              <div className="col-md-9">
                <input className="location-text-input" name="location" type="text" value={this.state.location} onChange={this.handleChange} required></input>
              </div>
            </Row>
            <div className="height-10"></div>
            <Row className="d-flex justify-content-center">
              <Button type="submit" className="saveButton">Submit</Button>
            </Row>
            <div className="height-10"></div>
          </form>
        </Row>
      </Card>
      <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
        {/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
        <ModalBody className="alert-body">
          <i className="mdi mdi-alert-circle ft-60"></i>
          <h2>{this.state.alertMessage}</h2>
          <div className="height-10"></div>
          <FormGroup>
            <Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
            <span>&nbsp;&nbsp;</span>
            <Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  }
}


export default CompanyView;
