import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { auth } from '../../Api'
import ReactLoading from 'react-loading';
import CompanyEdit from './company-edit';
import ContentModal from '../../components/contentModal';
import { AutoComplete, Input as AInput, Pagination } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

const { getCode, getName } = require('country-list');

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: -1,
			modal: false,
			alertModal: false,
			alertActiveModal: false,
			contentModal: false,
			activeLoading: false,
			contentMessage: "",
			obj: {},
			jsonData: [],
			originData: [],
			data: data.makeData(),
			treedata: data.treedata,
			defaultPageSize:5
		};
		this.toggle = this.toggle.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.alertActiveToggle = this.alertActiveToggle.bind(this);
		this.contentToggle = this.contentToggle.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		var defaultPageSize = localStorage.getItem('defaultPageSize')
		if(defaultPageSize!=undefined){
			this.setState({
				defaultPageSize: defaultPageSize
			})
		}
		var admin_level = localStorage.getItem('admin_level')
		var admin_id = localStorage.getItem('admin_id')
		var req = {}
		console.log("admin_level:", admin_level, admin_id)
		if (admin_level != "a")
			req.admin_id = admin_id
		console.log(req)
		auth.getCompany(req)
			.then((res) => {
				console.log("res company:", res)
				if (res.data.code == 200) {
					this.setState({
						jsonData: res.data.message.users,
						originData: res.data.message.users,
						totalCount: res.data.message.users.length
					})
				}
			});
	}

	componentDidUpdate() {
		console.log("called setState function");
	}

	toggle() {
		console.log("clicked toggle function")
		this.setState({
			modal: !this.state.modal
		});
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}
	contentToggle() {
		this.setState({
			contentModal: !this.state.contentModal
		});
	}
	alertActiveToggle() {
		this.setState({
			alertActiveModal: !this.state.alertActiveModal
		});
	}
	handleChange(index, prop) {
		console.log("checked:", index)
		let { jsonData, alertActiveModal } = this.state;
		if (prop.active == 0)
			auth.activeCompany(prop)
				.then((res) => {
					this.componentDidMount()
				})
		else
			this.setState({
				alertActiveModal: !alertActiveModal,
				obj: prop
			})
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phonenumber = event
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let { jsonData, obj } = this.state;
		// obj.id = event.target.id.value;
		obj.name = event.target.name.value;
		obj.email = event.target.email.value;
		obj.phonenumber = obj.phonenumber;
		console.log('company obje:', obj);
		auth.updateCompany(obj)
			.then((res) => {
				console.log("res:", res);
			})
		this.setState({
			modal: !this.state.modal
		})
	}

	goDetail(prop) {
		localStorage.setItem('companyId', prop.id)
		localStorage.setItem('companyName', prop.name)
		this.props.history.push('/company/company-view/' + prop.id)
	}

	deleteRow() {
		console.log("clicked deletedRow")
		let { obj } = this.state;
		auth.deleteCompany(obj)
			.then((res) => {
				console.log("res after delete:", res)
				if (res.status == 200) {
					if (res.data.code == 200) {
						var index = this.state.jsonData.findIndex(x => x.id == obj.id)
						let tmpData = [...this.state.jsonData]
						if (index != -1) {
							tmpData.splice(index, 1)
						}
						this.setState({
							jsonData: tmpData,
							alertModal: false,
							contentModal: true,
							contentMessage: "Deleted the company successfully"
						})
					} else {
						this.setState({
							alertModal: false,
							contentMessage: res.data.message,
							contentModal: true
						})
					}
				} else {
					this.setState({
						alertModal: false,
						contentMessage: "Server relation error",
						contentModal: true
					})
				}

			})
		this.setState({})
	}

	activeRow() {
		console.log("res after delete:")

		let { obj, activeLoading } = this.state;
		this.setState({ activeLoading: true })
		if (obj.active == 0)
			auth.activeCompany(obj)
				.then((res) => {
					this.setState({
						alertActiveModal: !this.state.alertActiveModal
					})
					this.componentDidMount()
				})
		else
			auth.stopCompany(obj)
				.then((res) => {
					this.setState({
						alertActiveModal: !this.state.alertActiveModal
					})
					this.componentDidMount()
				})
		this.setState({ activeLoading: false })

	}
	goEdit() {
		localStorage.removeItem('companyId')
		this.props.history.push("/company-edit");
	}

	goDetailPage(index, prop) {
		localStorage.setItem('companyId', prop.id)
		localStorage.setItem('companyName', prop.name)
		let urlLst = ["/company-dashboard", "/district", "/guards",
			"/supervisor", "/clients", "/alert-template", "/billing"]
		this.props.history.push(urlLst[index])
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.name.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	};

	onSizeChange = (size,page) => {
		console.log("size,page::",size,page)
		localStorage.setItem('defaultPageSize',size)
		this.setState({
			defaultPageSize:size
		})
	}

	render() {
		const { data, obj, activeLoading, defaultPageSize} = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			if (prop.active == 1) prop.active = true
			if (prop.active == 0) prop.active = false
			console.log("props:RRRR:", prop);
			return {
				id: key,
				name: (
					<div onClick={() => { prop.active == 1 ? this.goDetail(prop) : console.log("") }}
						className={prop.active ? "activeCompany" : "stopCompany"} style={{ cursor: "pointer" }}><a>{prop.name}</a></div>
				),
				country: getName(prop.countrycode),
				phonenumber: prop.phonenumber,
				email: prop.email,
				timezone: prop.timezone,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{activeLoading ? <ReactLoading type={"bars"} color={"#3399ff"} height={'30'} width={'66'} />
							: <Switch onChange={() => this.handleChange(key, prop)} uncheckedIcon checkedIcon offColor="#ddd"
								checked={prop.active}
							/>}
					</div>
				),
				edit: (
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-2" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "white" }} /></Button>
						<Button className="btn-table-remove ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
					</div>
				),
				linkPage: (
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
								<a onClick={function () {
									console.log(this.state.open)
									if (key == this.state.openKey)
										this.setState({ openKey: -1 })
									else
										this.setState({ openKey: key })
								}.bind(this)} ><i className="fas fa-plus"></i>&nbsp;View</a>
							</h6>
						</div>
						<div className={this.state.openKey == key ? "panel-collapse" : "panel-collapse panel-close"}>
							<div className="list-group">
								<a onClick={() => { this.goDetailPage(0, prop) }} style={{ cursor: "pointer" }}><i className="mdi mdi-view-dashboard mr-10"></i>&nbsp;Dashboard</a>
								<a onClick={() => { this.goDetailPage(1, prop) }} style={{ cursor: "pointer" }}><i className="ti-map-alt mr-10"></i>&nbsp;Districts</a>
								<a onClick={() => { this.goDetailPage(2, prop) }} style={{ cursor: "pointer" }}><i className="icon-shield mr-10"></i>&nbsp;Guards</a>
								<a onClick={() => { this.goDetailPage(3, prop) }} style={{ cursor: "pointer" }}><i className="mr-2 mdi mdi-account-alert mr-10"></i>&nbsp;Supervisors</a>
								<a onClick={() => { this.goDetailPage(4, prop) }} style={{ cursor: "pointer" }}><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>&nbsp;Clients</a>
								<a onClick={() => { this.goDetailPage(5, prop) }} style={{ cursor: "pointer" }}><i className="icon-bell mr-10"></i>&nbsp;Alerts Template</a>
								<a onClick={() => { this.goDetailPage(6, prop) }} style={{ cursor: "pointer" }}><i className="icon-chart mr-10"></i>&nbsp;Billing</a>
							</div>
						</div>
					</div>
				)
			};
		});

		console.log("defaultPageSize=========:",defaultPageSize)

		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Company Edit</h3></ModalHeader>
				{
					obj == {} ? <div></div> : <ModalBody>
						<Form onSubmit={this.handleSubmit}>
							{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
							<FormGroup>
								<Label for="name">Company Name</Label>
								<Input type="text" name="name" id="name" defaultValue={obj.name} />
							</FormGroup>
							<FormGroup>
								<Label for="email">Email</Label>
								<Input type="text" name="email" id="email" defaultValue={obj.email} />
							</FormGroup>
							<FormGroup>
								<Label for="age">Phone</Label>
								<PhoneInput
									placeholder="Enter phone number"
									value={obj.phonenumber}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</FormGroup>
							<FormGroup style={{ textAlign: "center" }}>
								<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
								<Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>}
			</Modal>
			{/* <ContentModal isOpen={this.state.alertModal} 
			message01="Are you sure you want to delete this Company?"
			message02="You won't be able to revert this!"
			toggle={this.alertToggle} 
			ok={()=>this.deleteRow()}></ContentModal> */}
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this Company?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={this.deleteRow}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.alertActiveModal} toggle={this.alertActiveToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to inactive this Company?</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertActiveToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={() => this.activeRow()}>Yes, InActive It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.contentModal} toggle={this.contentToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.contentMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.contentToggle}>OK</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}

			<Card>
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Companies</span></div>
						<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={[]}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search" />
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "COMPANY",
								accessor: "name"
							},
							{
								Header: "PHONE",
								accessor: "phonenumber"
							},
							{
								Header: "EMAIL",
								accessor: "email"
							},
							{
								Header: "Timezone",
								accessor: "timezone"
							},
							{
								Header: "ACTIVE",
								accessor: "actions",
								sortable: false,
								maxWidth: 66,
								filterable: false
							},
							{
								Header: "RELATED PAGES",
								accessor: "linkPage",
								// id: "row",
								maxWidth: 160,
								filterable: false,
							},
							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 120,
								justfyContent: "center",
								filterable: false,
							},
						]}
						showPaginationBottom={true}
						pageSize={defaultPageSize}
						pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]}
						onPageSizeChange={(size,number)=>this.onSizeChange(size,number)}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<a onClick={() => this.goEdit()}><Button className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Company
						</Button></a>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
