import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './layout-components/header/header';
import Footer from './layout-components/footer/footer';
import Sidebar from './layout-components/sidebar/sidebar'
import {withRouter} from "react-router-dom";
import LoadingComponent from './loader/Loader';
import {Link} from 'react-router-dom';
import ThemeRoutes from '../routes/router'

class LandingLayout extends React.Component {
	constructor(props) {
		super(props);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.state = {
			isOpen: false,
			width: window.innerWidth,
			isLoading: this.props.isLoading,
			admin_level: '',
			user: null
		};

		this.props.history.listen((location, action) => {
			if (window.innerWidth < 767 &&
				document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
				document.getElementById('main-wrapper').classList.toggle("show-sidebar");
			}
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook, Applies when loading or resizing App                           */
	/*--------------------------------------------------------------------------------*/
	componentDidMount() {
		this.updateDimensions()
		let user = localStorage.getItem('mo_user');
		if(user!=null){
			let tmpUser = JSON.parse(user)
			this.setState({
				user:tmpUser,
				admin_level : localStorage.getItem('admin_level')
			})
		}else{
			this.setState({
				admin_level : localStorage.getItem('admin_level')
			})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isLoading!==this.props.isLoading){
			this.setState({isLoading: this.props.isLoading});
		}
	}

	/*--------------------------------------------------------------------------------*/
	/*Function that handles sidebar, changes when resizing App                        */
	/*--------------------------------------------------------------------------------*/
	updateDimensions() {

		let element = document.getElementById('main-wrapper');
		this.setState({
			width: window.innerWidth
		});
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				if (this.state.width < 1170) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
					element.classList.add("mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
					element.classList.remove("mini-sidebar");
				}
				break;

			case 'overlay':
				if (this.state.width < 767) {
					element.setAttribute("data-sidebartype", "mini-sidebar");
				} else {
					element.setAttribute("data-sidebartype", this.props.settings.activeSidebarType);
				}
				break;

			default:
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Life Cycle Hook                                                                 */
	/*--------------------------------------------------------------------------------*/
	componentWillUnmount() {
		window.removeEventListener("load", this.updateDimensions);
		window.removeEventListener("resize", this.updateDimensions);
	}
	render() {
		/*--------------------------------------------------------------------------------*/
		/* Theme Setting && Layout Options will be Change From Here                       */
		/*--------------------------------------------------------------------------------*/
		return (
			<div
                id="main-wrapper"
				dir={this.props.settings.activeDir}
				data-theme={this.props.settings.activeTheme}
				data-layout={this.props.settings.activeThemeLayout}
				data-sidebartype={this.props.settings.activeSidebarType}
				data-sidebar-position={this.props.settings.activeSidebarPos}
				data-header-position={this.props.settings.activeHeaderPos}
				data-boxed-layout={this.props.settings.activeLayout}
            >
				{LoadingComponent({isLoading: this.state.isLoading, error: null})}
				
                    <Header />
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Sidebar                                                                        */}
                    {/*--------------------------------------------------------------------------------*/}
					<Sidebar {...this.props} routes={ThemeRoutes} />
					<div className="page-wrapper d-block">
                        <div className="page-content container-fluid">
                            <Switch>
                                {ThemeRoutes.map((prop, key) => {
                                    if (prop.navlabel) {
                                        return null;
                                    }
                                    else if(prop.auth && !this.props.is_authenticated) {
                                        return <Redirect from={prop.path} to={'/authentication/login2'} key={key} />
                                    } else {
                                        if (prop.collapse) {
                                            return prop.child.map((prop2, key2) => {
                                                if (prop2.collapse) {
                                                    return prop2.subchild.map((prop3, key3) => {
                                                        return (
                                                            <Route path={prop3.path} component={prop3.component} key={key3} />
                                                        );
                                                    });
                                                }
                                                return (
                                                    <Route path={prop2.path} component={prop2.component} key={key2} />
                                                );
                                            });
                                        }
                                        else if (prop.redirect) {
                                            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                        }
                                        else {
                                            return (
                                                <Route path={prop.path} exact={prop.exact}  component={prop.component} key={key} />
                                            );
                                        }
                                    }
                                })}
                            </Switch>
                        </div>
                        <Footer />
                    </div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	...state,
	isLoading: state.settings.isLoading,
	is_authenticated: state.auth.isAuthenticated

});
export default withRouter(connect(mapStateToProps)(LandingLayout));
