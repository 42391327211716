import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import CompanyMenu from '../companies/company-menu'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import {auth} from "../../Api";
import { getName } from 'country-list';

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			alertModal: false,
			contentModal: false,
			obj: {},
			jsonData: [],
			data: data.makeData(),
			treedata: data.treedata,
			client_id:'',
			companyName:'',
			clientName:'',
			contentMessage:''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.contentToggle = this.contentToggle.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
	}

	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}
	contentToggle() {
		this.setState({
			contentModal: !this.state.contentModal
		});
	}

	deleteRow() {
		console.log("clicked deletedRow")
		let { obj } = this.state;
		auth.deleteLocation(obj)
			.then((res) => {
				console.log("res after delete:", res)
				if(res.status==200){
					if(res.data.code==200){
						this.setState({
							alertModal:false,
							contentModal:true,
							contentMessage:"Deleted the location successfully"
						})
						this.componentDidMount()
					}else{
						this.setState({
							alertModal:false,
							contentMessage:res.data.message,
							contentModal:true
						})
					}
				}else{
					this.setState({
						alertModal:false,
						contentMessage:"Server relation error",
						contentModal:true
					})
				}
				
			})
	}

	componentDidMount(){
		const {id} = this.props.match.params
		let client_id = localStorage.getItem('clientId')
		let companyName = localStorage.getItem('companyName')
		let clientName = localStorage.getItem('clientName')

		this.setState({
			companyName:companyName,
			clientName:clientName,
			client_id:client_id
		})
		var req={}
		req.client_id = client_id
		auth.getLocation(req)
			.then((res)=>{
				if(res.data.code==200){
				this.setState({jsonData: res.data.message.users})
				}
			})
	}
	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	  }

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		let location = event.target.location.value;
		let age = event.target.age.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name, designation, location, age];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal
		})
	}

	goLocationDetail = (prop) =>{
		this.props.history.push('/client-location-edit/'+prop.id)
	}

	render() {
		const { data, obj,clientName,companyName } = this.state;
		console.log("obj:sss:",obj)

		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				name: (
					<a onClick={()=>this.goLocationDetail(prop)} style={{cursor:"pointer"}}>{prop.name}</a>
					),
				timezone:prop.timezone,
				edit:(
					<Button className="btn-table-remove ml-1" onClick={() => {
						let obj = data2.find(o => o.id === key);
						console.log(["key:", key, obj]);
						this.setState({
							alertModal: !this.state.alertModal,
							obj: prop,
						});
					}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
				),
				linkPage:(
					<div>
						{/* <a href="/duty/:location=123"><i className="pr-1 ti-align-left"></i>Location Duties</a> */}
						<a onClick={()=>this.props.history.push('/duty-location/'+prop.id)} style={{cursor:"pointer"}}><div className="b-title-end"><i className="pr-1 ti-align-left"></i>Location Duties&nbsp;</div></a>
					</div>
				)
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]
		
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		return <div>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this location?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={this.deleteRow}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.contentModal} toggle={this.contentToggle}>
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.contentMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.contentToggle}>OK</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
					<div className="table-title"><i className="fas fa-users"></i><span> Location - {clientName}({companyName})</span></div>
						<div style={{ width: 400 }} className="auto-complete">
					<ReactSearchAutocomplete
						items={items}
						onSearch={handleOnSearch}
						onSelect={handleOnSelect}
						onFocus={handleOnFocus}
						autoFocus
					/>
					</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "Location Alias",
								accessor: "name"
							},							
							{
								Header: "Time Zone",
								accessor: "timezone"
							},							
							{
								Header: "Actions",
								accessor:"linkPage",
								// id: "row",
								maxWidth: 150,
								filterable: false,
							},
							{
								Header: "",
								accessor:"edit",
								// id: "row",
								maxWidth: 100,
								filterable: false,
							},				
						]}
						defaultPageSize={10}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					<div className="add-button">
						<Button onClick={()=>{this.props.history.push('/client-location-edit/null')}} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Location
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
