export default {
  // serverDevURL: 'http://localhost:8000',
  serverDevURL: 'https://laravel.mobiliis.com',
  serverProURL: 'https://laravel.mobiliis.com',
  accountProUrl: 'https://laravel.mobiliis.com',
  basePath: '/api/',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  timeOut: 60000,
};
  
  