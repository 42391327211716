import React, { useState } from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
	ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list'
// import { SelectTimezone } from 'react-select-timezone';
import { auth } from '../../Api';
import ImageUploader from 'react-images-upload';
import { baseURL, baseImgURL } from '../../Api/BaseApi';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

class CompanyView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			checked: true,
			jsonData: data.dataTable,
			guardData: [],
			data: data.makeData(),
			treedata: data.treedata,
			options: countryList().getData(),
			firstName: '',
			secondName: '',
			CID: '',
			phoneValue: '',
			timeValue: '',

			emailValue: "com@com.com",
			avatarUrl: 'avatar.jpg',
			center: {
				lat: 59.95,
				lng: 30.33
			},
			zoom: 11,
			superId: "null",
			company_id: "",
			countryOption: null,
			districtLst: [],
			districtOption: null,
			managerLst: [],
			managerOption: null,
			guardLst: [],
			guardOption: null,
			showAlert: false,
			showResult: false,
			pictures: [],
			modalEdit: false,
			modalCancel: false
		};
		this.toggle = this.toggle.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.toggleCancel = this.toggleCancel.bind(this);
	}
	componentDidMount() {
		let { guardLst } = this.state
		const { id } = this.props.match.params
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName');
		let { districtLst } = this.state
		this.setState({
			superId: id,
			company_id: companyId,
			companyName: companyName
		})
		var req = {}
		req.company_id = companyId
		auth.getDistrict(req)
			.then((res) => {
				districtLst = []
				if (res.data.code == 200) {
					res.data.message.users.forEach(element => {
						districtLst.push({ value: element.id.toString(), label: element.name })
					});
					this.setState({ districtLst })
				}
			})
		var req = {}
		req.id = id
		if (id != "null") {
			auth.getSupervisor(req)
				.then((res) => {
					console.log("res:", res)
					if (res.data.code = 200) {
						let supervisorData = res.data.message.users[0]
						let guardData = res.data.message.guards
						this.setState({
							avatarUrl: supervisorData.avatar,
							firstName: supervisorData.firstname,
							secondName: supervisorData.secondname,
							CID: supervisorData.CID,
							phoneValue: supervisorData.phone,
							districtOption: { value: supervisorData.district_id, label: supervisorData.district },
							managerOption: { value: supervisorData.districtmanager_id, label: supervisorData.districtmanager },
							guardData: guardData
						})
					}

				})
			req.company_id = companyId
			auth.unAssignGuard(req)
				.then((res) => {
					console.log("res:", res);
					guardLst = []
					res.data.message.users.forEach(element => {
						guardLst.push({ value: element.id, label: element.firstname + element.secondname })
					})
					this.setState({ guardLst })
				})
		}
	}

	toggle() {
		this.setState({
			showAlert: !this.state.showAlert
		});
	}

	toggleEdit() {
		this.setState({
			modalEdit: !this.state.modalEdit
		});
	}
	toggleCancel() {
		this.setState({
			modalCancel: !this.state.modalCancel
		});
	}

	districtChange = (e) => {
		let { managerLst } = this.state
		this.setState({
			districtOption: e,
		})
		console.log("e.distirct:", e);
		var district_id = e.value
		var req = {}
		req.district_id = district_id

		auth.getDistrictManager(req)
			.then((res) => {
				managerLst = []
				if (res.data.code == 200) {
					res.data.message.users.forEach(element => {
						managerLst.push({ value: element.id, label: element.name })
					})
				}
				this.setState({ managerLst })
			})
		this.componentDidMount()
	}
	managerChange = (e) => {
		this.setState({
			managerOption: e,
		})
		console.log("e.managerID:", e)
	}
	guardChange = (e) => {
		this.setState({
			guardOption: e,
		})
		console.log("e.managerID:", e)
	}

	assignGuard = () => {
		let { guardOption, superId } = this.state
		if (guardOption != null) {
			let req = {}
			req.id = guardOption.value
			req.super_id = superId

			auth.assignGuard(req)
				.then((res) => {
					console.log("res:", res)
				})
			this.setState({
				modalEdit: !this.state.modalEdit
			})
			this.componentDidMount()
		}
	}
	assignCancel = () => {
		let { obj } = this.state
		let req = {}
		req.id = obj.id

		auth.assignCancel(req)
			.then((res) => {
				console.log("res:", res)
			})
		this.setState({
			modalCancel: !this.state.modalCancel
		})
		this.componentDidMount()
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		this.setState({
			phoneValue: event
		})
	}

	handleChange = (event) => {
		console.log(["event.valu:", event.target.value, event.target.name])
		switch (event.target.name) {
			case "firstName":
				this.setState({
					firstName: event.target.value
				})
				break;
			case "secondName":
				this.setState({
					secondName: event.target.value
				})
				break;
			case "CID":
				this.setState({
					CID: event.target.value
				})
				break;
			case "phone":
				this.setState({
					phoneValue: event.target.value
				})
				break;
		}
	}
	onSave = (event) => {
		let { emailValue, phoneValue, firstName, secondName, CID, superId,
			avatarUrl, company_id,
			districtOption, managerOption
		} = this.state
		this.setState({ showResult: false });
		var req = {}
		req.firstname = firstName
		req.secondname = secondName
		req.CID = CID
		req.phone = phoneValue
		req.email = emailValue
		req.company_id = company_id
		if (avatarUrl != "")
			req.avatar = avatarUrl
		req.countrycode = 'CN'
		if (districtOption != null) {
			req.district = districtOption.label
			req.district_id = districtOption.value
		}
		if (managerOption != null)
			req.districtmanager_id = managerOption.value
		var that = this;
		let alertMessage = "";
		if (districtOption == null) {
			alertMessage = 'Please select District'
			that.setState({
				showAlert: true,
				showResult: false,
				alertMessage: alertMessage
			})
		}
		else if (managerOption == null) {
			alertMessage = 'Please select District Manager'
			that.setState({
				showAlert: true,
				showResult: false,
				alertMessage: alertMessage
			})
		}
		else {
			if (superId != "null") {
				req.id = superId
				auth.updateSupervisor(req)
					.then((res) => {
						if (res.data.code = 201) {
							console.log(["res.data.message:", res.data.message]);
							alertMessage = 'Updated Successfully'
							that.setState({
								showAlert: true,
								showResult: true,
								alertMessage: alertMessage
							})
						}
					})
			}
			else
				auth.registerSupervisor(req)
					.then((res) => {
						console.log("auth.register's res:", res);
						if (res.data.message != null) {
							if (res.data.code == 400) {
								console.log("res.data.message.email:", res.data.message.email, res.data.message.email != null);
								if (res.data.message.email != null) {
									alertMessage += 'The email already exists.\n'
								}
								if (res.data.message.phone != null) {
									alertMessage += 'This phone number already exists\n'
								}
								console.log(["res.data.message:", res.data.message, alertMessage]);
								that.setState({
									showAlert: true,
									showResult: false,
									alertMessage: alertMessage
								})
								console.log(["res.data.message:", alertMessage, that.state.alertMessage]);
							}
							else if (res.data.code = 201) {
								console.log(["res.data.message:", res.data.message]);
								alertMessage = 'Created Successfully'
								that.setState({
									showAlert: true,
									showResult: true,
									alertMessage: alertMessage
								})
							}
						}
					});
		}
		event.preventDefault();
	}

	onDrop(picture) {
		var req = {}
		req.file = picture[0]
		console.log("req:", req);
		var that = this;
		auth.upload(req)
			.then((res) => {
				if (res.data.code == 201) {
					var avatarL = res.data.url.split("/")
					that.setState({
						avatarUrl: avatarL[avatarL.length - 1]
					})
				}
			})
		this.setState({
			pictures: this.state.pictures.concat(picture),
		});
	}

	render() {
		const { superId, districtOption, districtLst, managerLst, managerOption, avatarUrl, companyName, guardLst, guardOption } = this.state;
		const data2 = this.state.guardData.map((prop, key) => {
			return {
				id: key,
				firstname: <div onClick={() => this.props.history.push('/guard-info/' + prop.id)} style={{ cursor: "pointer" }}>{prop.firstname}</div>,
				secondname: prop.secondname,
				CID: prop.CID,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{prop.active == 1 ? "TRue" : "FALSE"}
					</div>
				),
				edit: (
					<div>
						<button onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modalCancel: !this.state.modalCancel,
								obj: prop,
							});
						}}>Cancel</button>
					</div>

				)
			};
		});
		const items = [
			{
				id: 0,
				name: 'Cobol'
			},
			{
				id: 1,
				name: 'JavaScript'
			},
			{
				id: 2,
				name: 'Basic'
			},
			{
				id: 3,
				name: 'PHP'
			},
			{
				id: 4,
				name: 'Java'
			}
		]

		const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		}

		const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		}

		const handleOnFocus = () => {
			console.log('Focused')
		}

		return <div>
			<Card>
				<CompanyMenu></CompanyMenu>
				<Row className="toolbar up-card-title">
					<div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Supervisor Information</div>
					{/* <a href="/duty">
                        <div className="b-title-end">View Location Duties&nbsp;<i className="ti-align-left"></i></div>
                    </a> */}
				</Row>
				<Row className="toolbar up-card-box justify-content-center">
					<div className="col-md-3" style={{ textAlign: "center" }}>
						<img src={avatarUrl != "" ? baseImgURL + avatarUrl : avatar} className="img-company"></img>
						<Form>
							<FormGroup>
								{/* <Input type="file" name="file" id="exampleFile" onChange={this.onDrop} /> */}
								<ImageUploader
									withIcon={false}
									buttonText='Choose images'
									onChange={this.onDrop}
									imgExtension={['.jpg', '.gif', '.png', '.gif']}
									maxFileSize={5242880}
									singleImage={true}
								/>
							</FormGroup>
						</Form>
					</div>
					<form className="col-md-7" name="myForm" onSubmit={this.onSave}>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">First Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="firstName" type="text" value={this.state.firstName} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Second Name</label>
							<div className="col-md-9">
								<input className="location-text-input" name="secondName" type="text" value={this.state.secondName} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">CID</label>
							<div className="col-md-9">
								<input className="location-text-input" name="CID" type="text" value={this.state.CID} onChange={this.handleChange} required></input>
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">Phone</label>
							<div className="col-md-9">
								<PhoneInput
									placeholder="Enter phone number"
									value={this.state.phoneValue}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</div>
						</Row>
						<Row className="company-text-area">
							<label className="col-md-3 control-label">District</label>
							<div className="col-md-9">
								<Select name="district_id" options={districtLst} value={districtOption}
									onChange={this.districtChange} required />
							</div>
						</Row>
						<Row className="company-text-area">
							<label htmlFor="address" className="col-md-3 control-label">District Manager</label>
							<div className="col-md-9">
								<Select options={managerLst} value={managerOption} onChange={this.managerChange}></Select>
							</div>
						</Row>
						<div className="height-10"></div>
						<Row className="d-flex justify-content-center">
							<Button type="submit" className="saveButton">Save Change</Button>
						</Row>
						<div className="height-10"></div>
					</form>
				</Row>
			</Card>
			{superId != "null" ? <Card>
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Guards - {companyName}</span></div>
						<div style={{ width: 400 }} className="auto-complete">
							<ReactSearchAutocomplete
								items={items}
								onSearch={handleOnSearch}
								onSelect={handleOnSelect}
								onFocus={handleOnFocus}
								autoFocus
							/>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "First Name",
								accessor: "firstname"
							},
							{
								Header: "Last Name",
								accessor: "secondname"
							},
							{
								Header: "CID",
								accessor: "CID"
							},
							{
								Header: "Active Guard",
								accessor: "actions"
							},
							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 100,
								filterable: false,
							},
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<Button className="btn" color="primary" size="lg" active onClick={this.toggleEdit}>
							<i className="fas fa-user-plus"></i>&nbsp; Assign New Guard
						</Button>
					</div>
				</CardBody>
			</Card>
				: <div></div>}
			<Modal isOpen={this.state.showAlert} toggle={this.toggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			<Modal isOpen={this.state.modalEdit} toggle={this.toggleEdit}>
				<ModalHeader toggle={this.toggleEdit}>Assign-Guard</ModalHeader>
				<ModalBody className="alert-body">
					<Select options={guardLst} value={guardOption} onChange={this.guardChange}></Select>
					<div style={{ height: "50px" }}></div>
					<FormGroup>
						<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggleEdit}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{ width: '100px' }} onClick={this.assignGuard}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>

			<Modal isOpen={this.state.modalCancel} toggle={this.toggleCancel}>
				<ModalHeader toggle={this.toggleCancel}>Assign-Guard</ModalHeader>
				<ModalBody className="alert-body">
					<h2>Are you sure to cancel assignment?</h2>
					<FormGroup>
						<Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggleCancel}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{ width: '100px' }} onClick={this.assignCancel}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>

		</div>
	}
}


export default CompanyView;
