import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import * as auth from "./auth/action";

const logger = createLogger();

export function configureStore(initialState) {

    const middlewares = [logger];

    const store = createStore(
        reducers,
        initialState,
        applyMiddleware(...middlewares)
    );
    store.dispatch(auth.checkAuth());

    return store;
}
