import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import { auth } from '../../Api';
import { getName } from 'country-list';
import { baseURL,baseImgURL } from '../../Api/BaseApi';

const TreeTable = treeTableHOC(ReactTable);

class DistrictInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
            managerData:[],
            districtmanager_id:"null",
			data: data.makeData(),
			treedata: data.treedata
		};
	}
    componentDidMount(){
		console.log("this.props.match.params:",this.props.match.params);
		const {id} = this.props.match.params
        console.log("id:",id)
        var company_id = localStorage.getItem('companyId')
        var req={}
        req.id=id
        req.company_id=company_id
        this.setState({
            districtmanager_id:id
        })
        auth.getDistrictManager(req)
            .then((res)=>{
                if(res.data.code==200){
                    this.setState({
                        jsonData:res.data.message.users,
                        managerData:res.data.message.district
                    })
                }
            })
		this.setState({
			id:id,
		})
	}

	render() {
		const { data, jsonData, managerData,districtmanager_id } = this.state;
        console.log("managerData:",managerData)
		return <div>
			<Card>
                <CompanyMenu></CompanyMenu>
				</Card>
                <Row className="space-around">
                    <div className="col-md-6">
                    {jsonData.length!=0?<Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;General Information<span className="b-title-in">
						<a onClick={()=>this.props.history.push('/district-manager-edit/'+districtmanager_id)} style={{cursor:"pointer"}}>(Edit)</a></span></div>
                            </Row>
                            <Row className="toolbar up-card-box">
                                <div>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">FristName</div>
                                        <div className="company-text-value">{jsonData[0].name}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">SecondName</div>
                                        <div className="company-text-value">{jsonData[0].sname}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">Phone</div>
                                        <div className="company-text-value">{jsonData[0].phonenumber}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">Active Company</div>
                                        <div className="company-text-value">{jsonData[0].active?"TRUE":"FALSE"}</div>
                                    </Row>
                                </div>
                            </Row>
                        </Card>:<Card className="pl-3 pr-3 pb-3 pt-3 d-flex align-item-center">There is no Data</Card>}
                    </div>
                    <div className="col-md-6">
                        <Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>&nbsp;Districts<span className="b-title-in"></span></div>
                            </Row>
                            {
                                managerData.length!=0?managerData.map(item =>(
                                    <div>
                                        <div className="height-10"></div>
                                        <Row className="toolbar up-card-box" onClick={()=>{this.props.history.push('/district-info/'+item.id)}} style={{cursor:"pointer"}}>
                                            <div className="avatar-circle">
                                                {/* <img src={baseImgURL+item.avatar} className="in-avatar-cicle"></img> */}
                                                {<i className="mdi mdi-account-multiple-outline"></i>}
                                            </div>
                                            <div>&nbsp;{item.name}</div>
                                            {/* <div><a href = "mailto:trial@trial.com">&nbsp; {item.email}</a></div> */}
                                        </Row>
                                    </div>
                                )):<div className="pl-3 pr-3 pt-3">There is no district</div>
                            }
                        </Card>
                    </div>
                    </Row>
            {/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default DistrictInfo;
