import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import { auth } from "../../Api";
import { AutoComplete, Input as AInput } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { getCode, getName } = require('country-list');

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			alertModal: false,
			obj: {},
			objData: {},
			jsonData: [],
			originData: [],
			autoOptions:[],
			data: data.makeData(),
			treedata: data.treedata,
			companyName: '',
			defaultPageSize: 5
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		var defaultPageSize = localStorage.getItem('defaultPageSize')
		if (defaultPageSize != undefined) {
			this.setState({
				defaultPageSize: defaultPageSize
			})
		}
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')
		this.setState({
			companyId: companyId,
			companyName: companyName
		})
		var req = {}
		req.company_id = companyId
		auth.getGuard(req)
			.then((res) => {
				console.log("skjdkfjsk--------clients", companyId, res.data.message.users);
				if (res.data.code == 200) {
					this.setState({
						jsonData: res.data.message.users,
						originData: res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	handleChange(index) {
		console.log("checked:", index)
		let { jsonData } = this.state;
		jsonData[index].islogged = !jsonData[index].islogged
		console.log(jsonData[index].islogged)
		this.setState({ jsonData });
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phone = event
	}
	
	handleSubmit = (event) => {
		event.preventDefault();
		let { jsonData, obj, objData } = this.state;
		console.log(["guard edit:", obj])
		let req = {}
		req.id = objData.id;
		req.firstname = event.target.firstname.value;
		req.secondname = event.target.secondname.value;
		req.countrycode = 'CN';
		req.phone = obj.phone;
		req.avatar = objData.avatar
		req.CID = objData.CID
		req.company_id = objData.company_id
		req.district_id = objData.district_id
		req.districtmanager_id = objData.districtmanager_id
		req.supervisor_id = objData.supervisor_id
		req.client_id = objData.client_id

		auth.updateGuard(req)
			.then((res) => {
				if (res.status)
					console.log("res:", res);
			})
		this.setState({
			modal: !this.state.modal
		})
		this.componentDidMount()
	}

	deleteRow() {
		let { obj } = this.state;
		let req = {}
		req.id = obj.idKey
		auth.deleteGuard(req)
			.then((res) => {
				console.log("res after delete:", res)
			})
		this.setState({
			alertModal: !this.state.alertModal
		})
		this.componentDidMount()
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.firstname.toLowerCase().includes(value.toLowerCase())
				|| prop.secondname.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	  };

	  onSizeChange = (size, page) => {
		console.log("size,page::", size, page)
		localStorage.setItem('defaultPageSize', size)
		this.setState({
			defaultPageSize: size
		})
	}
	render() {
		const { data, autoOptions, obj, companyName, defaultPageSize } = this.state;

		const items = []

		const data2 = this.state.jsonData.map((prop, key) => {
			if (prop.active == 1) prop.active = true
			if (prop.active == 0) prop.active = false

			items.push({
				id: prop.id,
				name: prop.firstname + prop.secondname
			})
			return {
				id: key,
				firstname: (
					<a onClick={() => this.props.history.push('/guard-info/' + prop.id)} style={{ cursor: "pointer" }}>{prop.firstname}</a>
				),
				idKey: prop.id,
				firstnamet: prop.firstname,
				secondname: prop.secondname,
				cid: prop.CID,
				phone: prop.phone,
				country: getName(prop.countrycode),
				status: prop.active == 0 ? "FALSE" : "TRUE",
				assigned: prop.assigned == 0 ? "FALSE" : "TRUE",
				nomissedalert: prop.nomissedalert,
				nototalalert: prop.nototalalert,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={() => this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.islogged} />
					</div>
				),
				duties: (
					<a href="/duty/123"><div>showduties</div></a>
				),
				edit: (
					<div className="justify-content-around">
						<Button className="btn-table-edit" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							console.log("obj====:",obj)
							this.setState({
								modal: !this.state.modal,
								obj: obj,
								objData: prop
							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "white" }} /></Button>
						<Button className="btn-table-remove" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
					</div>
				),
				linkPage: (
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
								<a onClick={function () {
									console.log(this.state.open)
									this.setState({ open: !this.state.open })
								}.bind(this)} ><i className="fas fa-plus"></i>View</a>
							</h6>
						</div>
						<div className={this.state.open ? "panel-collapse" : "panel-collapse panel-close"}>
							<ul className="list-group">
								<li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
								<li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-settings mr-10"></i>Districts Managers</li>
								<li className="list-group-item"><i className="icon-shield mr-10"></i>Guards</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-alert mr-10"></i>Supervisors</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>Clients</li>
								<li className="list-group-item"><i className="icon-bell mr-10"></i>Alerts Template</li>
								<li className="list-group-item"><i className="ti-align-left mr-10"></i>Duties</li>
								<li className="list-group-item"><i className="icon-chart mr-10"></i>Billing</li>
							</ul>
						</div>
					</div>
				)
			};
		});



		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Guard Edit</h3></ModalHeader>
				{
					obj == {} ? <div></div> : <ModalBody>
						<Form onSubmit={this.handleSubmit}>
							{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
							<FormGroup>
								<Label for="name">First Name</Label>
								<Input type="text" name="firstname" id="name" defaultValue={obj.firstnamet} />
							</FormGroup>
							<FormGroup>
								<Label for="name">Second Name</Label>
								<Input type="text" name="secondname" id="name" defaultValue={obj.secondname} />
							</FormGroup>
							<FormGroup>
								<Label for="age">Phone</Label>
								<PhoneInput
									placeholder="Enter phone number"
									value={obj.phone}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</FormGroup>
							<FormGroup style={{ textAlign: "center" }}>
								<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
								<Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this guard?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={() => this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
			<div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Guards - {companyName}</span></div>
						<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={autoOptions}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search"/>
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "First Name",
								accessor: "firstname"
							},
							{
								Header: "Last Name",
								accessor: "secondname"
							},
							{
								Header: "CID",
								accessor: "cid"
							},
							{
								Header: "Status",
								accessor: "status"
							},
							{
								Header: "Assigned",
								accessor: "assigned"
							},
							{
								Header: "Missed Alerts No",
								accessor: "nomissedalert"
							},

							{
								Header: "Total Alerts No",
								accessor: "nototalalert"
							},
							// {
							// 	Header: "Duties",
							// 	accessor: "duties"
							// },
							{
								Header: "Is Logged In",
								accessor: "actions",
								sortable: false,
								filterable: false
							},

							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 100,
								filterable: false,

							},
						]}
						showPaginationBottom={true}
						pageSize={defaultPageSize}
						pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]}
						onPageSizeChange={(size, number) => this.onSizeChange(size, number)}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<Button onClick={() => this.props.history.push('/guard-edit/null')} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Guard
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
