import {sendRequest, requestMethod} from './BaseApi'

const auth = {
    TRUE: true,
    FALSE: false
};

///////////////////////////////////////////////////////////
///admin:a, reseller:b, company:c,districtmanager:d
///////////////////////////////////////////////////////////

export const register=(requestObj)=>{
    return sendRequest(
        'auth/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export function signin(requestObj){
    return sendRequest(
        'auth/login',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export function forgotPassword(requestObj){
    return sendRequest(
        'password/sendtoken',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export function changePassword(requestObj){
    return sendRequest(
        'password/change',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

// //////////////////////////////////////////////////////////
//////


/////////////////////////////////////////////////////////////


export async function getDashboard(requestObj){
    var url ='dashboard/getDashboard'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getGuards(requestObj){
    var url ='dashboard/getGuards'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getPastGuards(requestObj){
    var url ='dashboard/getPastGuards'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getAdminName(){
    var url ='dashboard/getAdminName'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        {}
    )
}

export async function updateReportParams(requestObj){
    var url ='dashboard/updateReportParams'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj
    )
}

export async function getReportParams(requestObj){
    var url ='dashboard/getReportParams'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj
    )
}

export async function getGraphicData(requestObj){
    var url ='dashboard/getGraphicData'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj
    )
}

export async function getDownloadData(requestObj){
    var url ='dashboard/getDownloadData'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj
    )
}

export async function getCompany(requestObj){
    var url = 'auth/company/get'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function updateCompany(requestObj){
    var url ='auth/company/update'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function stopCompany(requestObj){
    var url ='auth/company/stop'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function activeCompany(requestObj){
    var url ='auth/company/active'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}


export const deleteCompany = (requestObj) => {
    var url ='auth/company/delete'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function deleteReseller(requestObj){
    var url ='auth/reseller/delete'
    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getDistrictManager(requestObj){
    var url ='auth/districtManager'

    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function updateDistrictManager(requestObj){
    var url ='auth/districtManager/update'

    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}


export async function deleteDistrictManager(requestObj){
    var url ='auth/districtManager/delete'

    return sendRequest(
        url,
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getReseller(requestObj){
    var url ='auth/reseller/'
    if(requestObj!=undefined)
        url = 'auth/reseller/'+requestObj
    return sendRequest(
        url,
        requestMethod.GET,
        auth.FALSE,
        requestObj,
    )
}

export async function upload(requestObj){
    return sendRequest(
        'image/store',
        requestMethod.FILE,
        auth.FALSE,
        requestObj,
    )
}

///supervisor////////////////////////

///////////////////////////////////////


export const registerSupervisor=(requestObj)=>{
    return sendRequest(
        'supervisor/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function getSupervisor(requestObj){
    return sendRequest(
        'supervisor/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function updateSupervisor(requestObj){
    return sendRequest(
        'supervisor/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function deleteSupervisor(requestObj){
    return sendRequest(
        'supervisor/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export async function unAssignGuard(requestObj){
    return sendRequest(
        'supervisor/unAssign',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

///client///////////////////////////

////////////////////////////////////

export const registerClient=(requestObj)=>{
    return sendRequest(
        'client/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const getClient=(requestObj)=>{
    return sendRequest(
        'client/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateClient=(requestObj)=>{
    return sendRequest(
        'client/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteClient=(requestObj)=>{
    return sendRequest(
        'client/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

///Location///////////////////////////

////////////////////////////////////

export const registerLocation=(requestObj)=>{
    return sendRequest(
        'location/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const getLocation=(requestObj)=>{
    return sendRequest(
        'location/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateLocation=(requestObj)=>{
    return sendRequest(
        'location/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteLocation=(requestObj)=>{
    return sendRequest(
        'location/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const registerGuard=(requestObj)=>{
    return sendRequest(
        'guard/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const getHitAccuracy=(requestObj)=>{
    var url ='guardapp/hitAccuracy/'
    if(requestObj!=undefined)
        url = 'guardapp/hitAccuracy/'+requestObj
    return sendRequest(
        url,
        requestMethod.GET,
        auth.FALSE,
    )
}

export const getGuard=(requestObj)=>{
    return sendRequest(
        'guard/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const getGuardUsage=(requestObj)=>{
    return sendRequest(
        'guardapp/getGuardUsageWeb',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateGuard=(requestObj)=>{
    return sendRequest(
        'guard/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteGuard=(requestObj)=>{
    return sendRequest(
        'guard/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const assignGuard=(requestObj)=>{
    return sendRequest(
        'guard/assign',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const assignCancel=(requestObj)=>{
    return sendRequest(
        'guard/assignCancel',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const eventRegister=(requestObj)=>{
    return sendRequest(
        'guard/eventRegister',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const eventGet=(requestObj)=>{
    return sendRequest(
        'guard/eventGet',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const eventUpdate=(requestObj)=>{
    return sendRequest(
        'guard/eventUpdate',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const eventDelete=(requestObj)=>{
    return sendRequest(
        'guard/eventDelete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const registerAlertTemplate=(requestObj)=>{
    return sendRequest(
        'alertTemplate/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const getAlertTemplate=(requestObj)=>{
    return sendRequest(
        'alertTemplate/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateAlertTemplate=(requestObj)=>{
    console.log("999000")
    return sendRequest(
        'alertTemplate/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteAlertTemplate=(requestObj)=>{
    console.log("999000")
    return sendRequest(
        'alertTemplate/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

///district
//////////////////////////////////////////////////////////////////

export const registerDistrict=(requestObj)=>{
    return sendRequest(
        'district/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const getDistrict=(requestObj)=>{
    return sendRequest(
        'district/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateDistrict=(requestObj)=>{
    return sendRequest(
        'district/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteDistrict=(requestObj)=>{
    return sendRequest(
        'district/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}


///duty
//////////////////////////////////////////////////////////////////

export const registerDuty=(requestObj)=>{
    return sendRequest(
        'duty/register',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}
export const getDuty=(requestObj)=>{
    return sendRequest(
        'duty/get',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}


export const updateDuty=(requestObj)=>{
    return sendRequest(
        'duty/update',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const deleteDuty=(requestObj)=>{
    console.log("delete duty",requestObj)
    return sendRequest(
        'duty/delete',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const getGuardDuty=(requestObj)=>{
    return sendRequest(
        'duty/getGuard',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const updateAlertValidate=(requestObj)=>{
    return sendRequest(
        'alert/validateAlert',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const validateAlertAdmin=(requestObj)=>{
    return sendRequest(
        'alert/validateAlertAdmin',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const getAlertEditHistory=(requestObj)=>{
    return sendRequest(
        'alert/getAlertEditHistory',
        requestMethod.POST,
        auth.FALSE,
        requestObj,
    )
}

export const doCreateUserWithEmailAndPassword = () => {

}

export const doSignInWithEmailAndPassword = () => {

}

