import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, Col,
    ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import { auth } from '../../Api'
import { getName } from 'country-list';
import { baseURL,baseImgURL } from '../../Api/BaseApi';

const TreeTable = treeTableHOC(ReactTable);

class SupervisorInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			guardData: [],
			supervisorId:"null",
			data: data.makeData(),
			treedata: data.treedata,
			supervisorName:'Supervisor'
		};
	}

	componentDidMount(){
		const {id} = this.props.match.params
        console.log("id:",id)
        var company_id = localStorage.getItem('companyId')
        var req={}
        req.id=id
		this.setState({supervisorId:id})
		var that = this
        auth.getSupervisor(req)
            .then((res)=>{
				console.log("res:",res)
			if(res.data.code==200){
			that.setState({
				jsonData: res.data.message.users,
				guardData: res.data.message.guards,
				supervisorName: res.data.message.users[0].firstname+" "+res.data.message.users[0].secondname
			})
			}
            })
		}
	handleChange (index) {
		console.log("checked:",index)
		let {guardData} = this.state; 
		guardData[index].active=!guardData[index].active
		console.log(guardData[index].active)
		this.setState({ guardData });
	  }



	render() {
		const { data,jsonData,supervisorName,supervisorId} = this.state;
        const data2 = this.state.guardData.map((prop, key) => {
			return {
				id: key,
				firstname: <div onClick={()=>this.props.history.push('/guard-info/'+prop.id)} style={{cursor:"pointer"}}>{prop.firstname}</div>,
				secondname: prop.secondname,
				CID: prop.CID,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={()=>this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active==1?true:false} />
					</div>
				),
				edit:(
					<div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
								
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"green"}}/></div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{color:"red"}}/></div>
					</div>
					
				)
			};
		});
		
		return <div>
			<Card>
                <CompanyMenu></CompanyMenu>
				
				</Card>

                <Row className="space-around">
                    <div className="col-md-6">
                        <Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="mr-2 mdi mdi-account-alert mr-10"></i>&nbsp;Supervisor-Info<a onClick={()=>{this.props.history.push("/supervisor-edit/"+supervisorId)}} style={{cursor:"pointer"}}><span className="b-title-in">(Edit)</span></a></div>
                            </Row>
                            {
							jsonData.length!=0?<Row className="toolbar up-card-box">
                                <div><img src = {baseImgURL+jsonData[0].avatar} className="img-company"></img></div>
                                <div>
									<Row className="company-text-area">
                                        <div className="company-text-key">NAME</div>
                                        <div className="company-text-value">{jsonData[0].firstname+" "+jsonData[0].secondname}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">CID</div>
                                        <div className="company-text-value">{jsonData[0].CID}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">PHONE</div>
                                        <div className="company-text-value">{jsonData[0].phone}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">DISTRICT</div>
                                        <div className="company-text-value">{jsonData[0].district}</div>
                                    </Row>
                                    <Row className="company-text-area">
                                        <div className="company-text-key">COMPANY</div>
                                        <div className="company-text-value">{jsonData[0].company}</div>
                                    </Row>
                                </div>
                            </Row>:<div></div>
                        	}
						</Card>
                    </div>
                    <div className="col-md-6">
                        <Card className="pl-3 pr-3 pb-3">
                            <Row className="toolbar up-card-title">
                                <div className="b-title"><i className="icon-shield mr-10"></i>&nbsp;Guards</div>
                            </Row>
                            <div className="height-10"></div>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "First Name",
                                        accessor: "firstname"
                                    },
                                    {
                                        Header: "Last Name",
                                        accessor: "secondname"
                                    },
                                    {
                                        Header: "CID",
                                        accessor: "CID"
                                    },
                                    {
                                        Header: "Guard Status",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                data={data2}
                                // filterable
                            />
                        </Card>
                    </div>
                </Row>
            {/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" defaultValue={this.state.obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Designation</Label>
							<Input type="text" name="designation" id="designation" defaultValue={this.state.obj.designation} />
						</FormGroup>
						<FormGroup>
							<Label for="location">Location</Label>
							<Input type="text" name="location" id="location" defaultValue={this.state.obj.location} />
						</FormGroup>
						<FormGroup>
							<Label for="age">Age</Label>
							<Input type="text" name="age" id="age" defaultValue={this.state.obj.age} />
						</FormGroup>
						<FormGroup>
							<Button color="primary" type="submit">Save</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	}
    
}

export default SupervisorInfo;
