import React from 'react';
import Chart from 'react-apexcharts'

class ApexChart extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          title: props.title,
        series: [props.confirmed, props.missed],
        options: {
          chart: {
            width: 300,
            type: 'donut',
          },
          colors: ['#0066cc', '#66b3ff', '#546E7A', '#E91E63', '#FF9800'],
          plotOptions: {
            pie: {
              startAngle: -90,
              endAngle: 270
            }
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            type: 'gradient',
          },
          legend: {
            formatter: function(val, opts) {
                console.log(["value---:",val]);
              return val + " - " + opts.w.globals.series[opts.seriesIndex]
            }
          },
          title: {
            text: 'Missed Alerts'
          },
          responsive: [{
            breakpoint: 300,
            options: {
              chart: {
                width: 150
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      };
    }
    componentDidMount(){
        this.setState({
          // series: [this.props.confirmed, this.props.missed],
            options: {
                chart: {
                  width: 300,
                  type: 'donut',
                },
                plotOptions: {
                  pie: {
                    startAngle: -90,
                    endAngle: 270
                  }
                },
                dataLabels: {
                  enabled: false
                },
                fill: {
                  type: 'gradient',
                },
                legend: {
                  formatter: function(val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex]
                  }
                },
                title: {
                  text: this.props.title
                },
                responsive: [{
                  breakpoint: 300,
                  options: {
                    chart: {
                      width: 150
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              }
        })
    }

    render() {
      return (
        <Chart options={this.state.options} series={this.state.series} type="donut" width={300} />
            );
        }
    }

    export default ApexChart
