import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
    CardText,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row,Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import classnames from 'classnames';
import './client-location-duty.scss';

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: data.dataTable,
			data: data.makeData(),
			treedata: data.treedata,
            activeTab: '1'
		};
		this.toggle2 = this.toggle2.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
    toggle2(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            'activeTab': tab
          });
        }
      }
	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	  }

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		let location = event.target.location.value;
		let age = event.target.age.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name, designation, location, age];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal
		})
	}

	render() {
		const { data } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				name: (
					<a href="/company/company-view">{prop[0]}</a>
					),
				designation: prop[1],
				location: prop[2],
				age: prop[3],
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
								console.log(["key:",key,obj.name]);
								this.setState({
									modal: !this.state.modal,
									obj: obj,
									
								});
							}}
							color="inverse"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button> */}
						
						{/* use this button to remove the data row */}
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit:(
					<div>
						{/* <div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
								
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"green"}}/></div> */}
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{color:"red"}}/></div>
					</div>
				),
				linkPage:(
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
							<a onClick={function(){
									console.log(this.state.open)
									this.setState({open:!this.state.open})}.bind(this)} ><i className="fas fa-plus"></i>View</a>
							</h6>
						</div>
						<div className={this.state.open? "panel-collapse": "panel-collapse panel-close"}>
							<ul className="list-group">
								<li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
								<li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-settings mr-10"></i>Districts Managers</li>
								<li className="list-group-item"><i className="icon-shield mr-10"></i>Guards</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-alert mr-10"></i>Supervisors</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>Clients</li>
								<li className="list-group-item"><i className="icon-bell mr-10"></i>Alerts Template</li>
								<li className="list-group-item"><i className="ti-align-left mr-10"></i>Duties</li>
								<li className="list-group-item"><i className="icon-chart mr-10"></i>Billing</li>
							</ul>
						</div>
					</div>
				)
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]
		
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		return <div>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
                        <div className="table-title"><i className="ti-align-left"></i><span> Duties for Trial Location</span></div>
                            <div style={{ width: 400 }} className="auto-complete">
                        <ReactSearchAutocomplete
                            items={items}
                            onSearch ={handleOnSearch}
                            onSelect ={handleOnSelect}
                            onFocus ={handleOnFocus}
                            autoFocus
                        />
                        </div>
					</Row>
                    <div className="nav-header-cus">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={classnames({
                                    'active': this.state.activeTab === '1'
                                })}
                                onClick={() => {
                                    this.toggle2('1');
                                }}
                                >
                                Current/Future Duties
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({
                                    'active': this.state.activeTab === '2'
                                })}
                                onClick={() => {
                                    this.toggle2('2');
                                }}
                                >
                                Past Duties
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                            <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "designation"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "location"
                                    },
                                    {
                                        Header: "Start Date & Time",
                                        accessor: "location"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Paid Status",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor:"edit",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        
                                    },							
                                ]}
                                defaultPageSize={10}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                data={data2}
                                // filterable
                                />
                        </TabPane>
                        <TabPane tabId="2">
                        <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index+1}</div>;
                                        }
                                    },
                                    // {
                                    // 	Header: "Duty Alias",
                                    // 	accessor: "name"
                                    // },
                                    {
                                        Header: "Supervisor",
                                        accessor: "designation"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "location"
                                    },
                                    {
                                        Header: "Start Date & Time",
                                        accessor: "location"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Paid Status",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "age"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor:"edit",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        
                                    },							
                                ]}
                                defaultPageSize={10}
                                showPaginationBottom={true}
                                className="-striped -highlight"
                                data={data2}
                                // filterable
                            />
                            </TabPane>
                            </TabContent>
                    
					
					<div className="add-button">
						<Button className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Duty
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}
export default ReSellers;
