import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import "./re-sellers.scss"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {auth} from '../../Api'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { getCode, getName } = require('country-list');

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			alertModal: false,
			obj: {},
			jsonData: [],
			data: data.makeData(),
			treedata: data.treedata
		};
		this.toggle = this.toggle.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		auth.getReseller()
			.then((res)=>{
				if(res.data.code==200){
					this.setState({
						jsonData:res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}

	handleChange (index){
		console.log("checked:",index)
		let {jsonData} = this.state; 
		jsonData[index].active=!jsonData[index].active
		console.log(jsonData[index].active)
		this.setState({ jsonData });
	  }
	
	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phonenumber = event
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let {jsonData,obj} = this.state; 
		obj.name = event.target.name.value;
		obj.countrycode = getCode(event.target.country.value);
		obj.location = getCode(event.target.location.value); 
		obj.phonenumber = obj.phonenumber;
		auth.updateCompany(obj)
			.then((res)=>{
				if(res.status)
				console.log("res:",res);
			})
		this.setState({
			modal: !this.state.modal
		})
	}

	deleteRow () {
		let {obj} = this.state;
		auth.deleteReseller(obj)
			.then((res)=>{
				console.log("res after delete:",res)
			})
		this.setState({
			alertModal:!this.state.alertModal
		})
		this.componentDidMount()
	}

	addNewdatabase(){
		localStorage.removeItem('companyId')
		localStorage.removeItem('resellerId')
		this.props.history.push('reseller-edit/null')
	}

	goDetail (prop){
		localStorage.setItem('resellerId',prop.id)
		localStorage.setItem('resellerName',prop.name)
		this.props.history.push('/reseller-edit/'+prop.id)  
	}

	render() {
		const { data,obj } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			if(prop.active==1)prop.active=true
			if(prop.active==0)prop.active=false
			return {
				id: key,
				name: (
					<div onClick={()=>this.goDetail(prop)} style={{cursor:"pointer"}}><a>{prop.name}</a></div>
					),
				country: getName(prop.countrycode),
				location: getName(prop.countrycode),
				phonenumber: prop.phonenumber,
				email: prop.email,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={()=>this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd"
							 checked={prop.active}
						/>
					</div>
				),
				edit:(
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"white"}}/></Button>
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				),
				linkPage:(
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
							<a onClick={function(){
									console.log(this.state.open)
									if(key==this.state.openKey)
									this.setState({openKey:-1})
									else
									this.setState({openKey:key})}.bind(this)} ><i className="fas fa-plus"></i>&nbsp;View</a>
							</h6>
						</div>
						<div className={this.state.openKey==key? "panel-collapse": "panel-collapse panel-close"}>
							<div className="list-group">
								<a onClick={()=>{this.props.history.push('/')}}><i className="mdi mdi-view-dashboard mr-10"></i>&nbsp;Dashboard</a>
								<a onClick={()=>{this.props.history.push('/district')}}><i className="ti-map-alt mr-10"></i>&nbsp;Districts</a>
								<a onClick={()=>{this.props.history.push('/guards')}}><i className="icon-shield mr-10"></i>&nbsp;Guards</a>
								<a onClick={()=>{this.props.history.push('/supervisor')}}><i className="mr-2 mdi mdi-account-alert mr-10"></i>&nbsp;Supervisors</a>
								<a onClick={()=>{this.props.history.push('/clients')}}><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>&nbsp;Clients</a>
								<a onClick={()=>{this.props.history.push('/alert-template')}}><i className="icon-bell mr-10"></i>&nbsp;Alerts Template</a>
								<a onClick={()=>{this.props.history.push('/duty/null/123')}}><i className="ti-align-left mr-10"></i>&nbsp;Duties</a>
								{/* <a onClick={()=>{this.props.history.push('/billing')}}><i className="icon-chart mr-10"></i>&nbsp;Billing</a> */}
							</div>
						</div>
					</div>
				)
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		]
	
		const handleOnSearch = (string, results) => {
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
		console.log(string, results)
		}
	
		const handleOnSelect = (item) => {
		// the item selected
		console.log(item)
		}
	
		const handleOnFocus = () => {
		console.log('Focused')
		}
		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Reseller Edit</h3></ModalHeader>
				{
				obj=={}?<div></div>:<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
						<FormGroup>
							<Label for="name">Reseller Name</Label>
							<Input type="text" name="name" id="name" defaultValue={obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="country">Country</Label>
							<Input type="text" name="country" id="country" disabled={true} defaultValue={obj.countrycode!=undefined?getName(obj.countrycode):""} />
						</FormGroup>
						<FormGroup>
							<Label for="location">Location</Label>
							<Input type="text" name="location" id="location" defaultValue={obj.countrycode!=undefined?getName(obj.countrycode):""} />
						</FormGroup>
						<FormGroup>
							<Label for="age">Phone</Label>
							<PhoneInput
									placeholder="Enter phone number"
									value={obj.phonenumber}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
						</FormGroup>
						<FormGroup style={{textAlign:"center"}}>
							<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
							<Button color="secondary"className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this Reseller?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary"  className="modal-btn mr-1" onClick={()=>this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
					<div className="table-title"><i className="fas fa-users"></i><span> ReSellers</span></div>
						<div style={{ width: 400 }} className="auto-complete">
					<ReactSearchAutocomplete
						items={items}
						onSearch={handleOnSearch}
						onSelect={handleOnSelect}
						onFocus={handleOnFocus}
						autoFocus
					/>
					</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "COMPANY",
								accessor: "name"
							},
							{
								Header: "COUNTRY",
								accessor: "country"
							},
							{
								Header: "MAIN ADDRESS",
								accessor: "location"
							},
							{
								Header: "PHONE",
								accessor: "phonenumber"
							},
							{
								Header: "EMAIL",
								accessor: "email"
							},
							{
								Header: "ACTIVE",
								accessor: "actions",
								sortable: false,
								maxWidth:66,
								filterable: false
							},
							{
								Header: "RELATED PAGES",
								accessor:"linkPage",
								// id: "row",
								maxWidth: 160,
								filterable: false,
							},		
							{
								Header: "",
								accessor:"edit",
								id: "row",
								maxWidth: 100,
								justfyContent: "center",
								filterable: false,
							},							
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					<div className="add-button">
						<a onClick={()=>this.addNewdatabase()}><Button className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Reseller
						</Button></a>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
