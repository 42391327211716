import React, { useState } from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";

import {
  Card,
  Button, Modal, ModalBody, Form, FormGroup, Label, Input, Row, Col,
  ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { auth } from '../../Api'
import { Divider } from "antd";

class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      open: false,
      modal: false,
      obj: {},
      jsonData: data.dataTable,
      data: data.makeData(),
      treedata: data.treedata,
      options: countryList().getData(),
      nameValue: "",
      countryValue: null,
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 11,
      startDate: new Date(),
      showAlert: false,
      showResult: false,
      durationList: [],
      minutesList: [],
      meterList: [],
      selectedDuration: {
        value: 12,
        label: "12 hours"
      },
      selectedMinute: {
        value: 10,
        label: "10"
      },
      clientLst: [],
      clientOption: null,
      locationLst: [],
      locationOption: null,
      supervisorLst: [],
      supervisorOption: null,
      supervisorUser: null,
      guardOption: null,
      alertTemplateLst: [],
      alertTemplateOption: null,
      oldDuties: [],
      showExistAlert: false,
      hasDuty: false,
      guardId: 'null',
      rest_break: false,
      dutyData: {}
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      showAlert: !this.state.showAlert
    });
  }

  async componentDidMount() {
    let { id } = this.props.match.params
    let guardId = 'null'
    if (id.includes('g')) {
      guardId = id.replace("g", "")
      id = 'null'
    }
    console.log("guardID,id:", guardId, id)
    let companyId = localStorage.getItem('companyId')
    let { clientLst, supervisorLst, alertTemplateLst, startDate } = this.state
    let tmpss = startDate
    startDate.setHours(tmpss.getHours() + 1, 0, 0, 0)
    let tmpNumberList = Array.from({ length: 12 }, (v, k) => k + 1)
    let tmpMinutesList = Array.from({ length: 6 }, (v, k) => k + 1)
    let tmpMeterList = Array.from({ length: 6 }, (v, k) => k + 1)
    let tmpDurations = []
    tmpNumberList.map((val) => {
      tmpDurations.push({
        value: val,
        label: val.toString() + " hours"
      })
    })
    var selectedMeter = {
      value: 10,
      label: "10"
    }
    var selectedMinute ={
      value: 10,
      label: "10"
    } 
    let tmpMinutes = []
    tmpMinutesList.map((val) => {
      tmpMinutes.push({
        value: val * 10,
        label: (val * 10).toString()
      })
    })
    let tmpMeters = []
    tmpMeterList.map((val) => {
      tmpMeters.push({
        value: val * 10,
        label: (val * 10).toString()
      })
    })

    this.setState({
      dutyId: id,
      guardId: guardId,
      startDate: startDate,
      company_id: companyId,
      durationList: tmpDurations,
      minutesList: tmpMinutes,
      meterList: tmpMeters,
      selectedMeter:selectedMeter,
      selectedMinute: selectedMinute
    })

    if (id != "null") {
      var req = {}
      req.id = id
      await auth.getDuty(req)
        .then((res) => {
          console.log("duty------edit:", res)
          let dutyData = res.data.message.users[0]
          guardId = dutyData.guard_id
          this.setState({
            dutyData:dutyData,
            nameValue: dutyData.name,
            guardId: dutyData.guard_id,
            startDate: new Date(dutyData.starttime),
            clientOption: { value: dutyData.client_id, label: dutyData.client_name },
            locationOption: { value: dutyData.location_id, label: dutyData.location_name, timezone: dutyData.timezone },
            supervisorOption: { value: dutyData.supervisor_id, label: dutyData.supervisor_name },
            guardOption: { value: dutyData.guard_id, label: dutyData.guard_name },
            alertTemplateOption: { value: dutyData.alerttemplate_id, label: dutyData.alerttemplate_name, alert_time: dutyData.alert_time }
          })
        })
    }
    var req = {}
    req.company_id = companyId
    auth.getClient(req)
      .then((res) => {
        clientLst = []
        if (res.data.code == 200) {
          res.data.message.users.forEach(element => {
            clientLst.push({ value: element.id.toString(), label: element.name })
          });
          this.setState({ clientLst })
        }
      })

    var reqguard = {}
    reqguard.id = guardId
    auth.getGuard(reqguard)
      .then((res) => {
        if (res.data.code == 200) {
          this.setState({
            guardData: res.data.message.users
          })
        }
        var req = {
          id: res.data.message.users[0].supervisor_id
        }
        auth.getSupervisor(req)
          .then((res) => {
            supervisorLst = []
            if (res.data.code == 200) {
              res.data.message.users.forEach(element => {
                supervisorLst.push({ value: element.id.toString(), label: element.firstname })
              });
              this.setState({
                supervisorOption: {
                  value: res.data.message.users[0].id,
                  label: res.data.message.users[0].firstname
                },
                supervisorUser: res.data.message.users[0],
                supervisorLst: supervisorLst
              })
            }
            var reqClient = {
              supervisor_id: res.data.message.users[0].id
            }
            auth.getClient(reqClient)
              .then((res) => {
                clientLst = []
                if (res.data.code == 200) {
                  res.data.message.users.forEach(element => {
                    clientLst.push({ value: element.id.toString(), label: element.name })
                  });
                  this.setState({ clientLst })
                }
              })
          })
      })
    var req = {}
    if (guardId != "null") {
      req.guard_id = guardId
    }
    auth.getAlertTemplate(req)
      .then((res) => {
        alertTemplateLst = []
        if (res.data.code == 200) {
          res.data.message.users.forEach(element => {
            alertTemplateLst.push({
              value: element.id.toString(),
              label: element.template_name,
              alert_time: element.alert_time
            })
          });
          this.setState({ alertTemplateLst })
        }
      })
  }

  clientChange = (e) => {
    let { locationLst } = this.state
    this.setState({
      clientOption: e,
    })
    console.log("e.distirct:", e);
    var client_id = e.value
    var req = {}
    req.client_id = client_id

    auth.getLocation(req)
      .then((res) => {
        locationLst = []
        if (res.data.code == 200) {
          res.data.message.users.forEach(element => {
            locationLst.push({ value: element.id, label: element.name, timezone: element.timezone })
          })
        }
        this.setState({ locationLst })
      })
  }

  handleChange = (event) => {
    console.log(["event.valu:", event.target.value, event.target.name])
    switch (event.target.name) {
      case "name":
        this.setState({
          nameValue: event.target.value
        })
        break;
    }
  }
  locationChange = (e) => {
    console.log("location:", e)
    this.setState({
      locationOption: e,
    })
  }
  guardChange = async (e) => {
    console.log("guard:", e)
    this.setState({
      guardOption: e,
    })
    var req = {}
    req.guard_id = e.value
    var response = await auth.getGuardDuty(req)
    console.log("response of duty:", response.data.message.duty);
    if (response.data.message.duty)
      if (response.data.message.duty.length > 0)
        this.setState({ showExistAlert: true })
  }
  alertTemplateChange = (e) => {
    console.log("location:", e)
    this.setState({
      alertTemplateOption: e,
    })
  }
  setDurationChange = (e) => {
    this.setState({
      selectedDuration: e
    })
  }

  setMinutesChange = (e) => {
    console.log("e of min:", e)
    this.setState({
      selectedMinute: e
    })
  }

  setMeterChange = (e) => {
    console.log("e of min:", e)
    this.setState({
      selectedMeter: e
    })
  }

  onChange = (checked) => {
    console.log(`switch to ${checked}`);
    this.setState({
      rest_break: checked
    })
  };

  dateHandleChange = (value) => {
    console.log("startDate:", value)
    var date = value
    console.log("datemiliseconds:", date.getTime())
    var millisec = date.getTime();
    console.log("convertedDate:", new Date(millisec))
    value.setSeconds(0)
    console.log("startDate--setSeconds(0):", value)
    var date = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
    var time = value.getHours() + ":" + value.getMinutes() + ":" + value.getSeconds();
    console.log(date, time);
    this.setState({ startDate: value })
  }

  onSave = (event) => {

    event.preventDefault();

    this.setState({ showResult: false });
    let { nameValue, clientOption, dutyId, guardId,
      company_id, locationOption, supervisorOption, supervisorUser, guardOption,
      startDate, alertTemplateOption, selectedDuration,
      rest_break, selectedMinute, selectedMeter,dutyData
    } = this.state

    Date.prototype.addMins = function (min) {
      this.setTime(this.getTime() + (min * 60 * 1000));
      return this;
    }
    var req = {}
    var duration = selectedDuration.value * 60
    var endtime = startDate.getTime() + duration * 60 * 1000
    req.name = nameValue
    req.duration = duration
    req.company_id = company_id
    if (clientOption != null) {
      req.client_id = clientOption.value
    }
    if (locationOption != null) {
      let date = new Date()
      const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
      const tzDate = new Date(date.toLocaleString('en-US', { timeZone: locationOption.timezone }));
      let locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;

      let currentOffset = (new Date()).getTimezoneOffset()

      let targetOffset = currentOffset - locationOffset

      req.starttime = startDate.getTime() - targetOffset * 60 * 1000
      req.endtime = endtime - targetOffset * 60 * 1000

      req.location_id = locationOption.value
    }

    req.supervisor_id = supervisorUser.id
    req.guard_id = guardId
    req.rest_break = rest_break
    req.rest_time = selectedMinute.value
    req.rest_meter = selectedMeter.value

    if (alertTemplateOption != null) {
      req.alerttemplate_id = alertTemplateOption.value
      req.alert_time = alertTemplateOption.alert_time
    }
    var that = this;
    let alertMessage = "";
    if (clientOption == null) {
      alertMessage = 'Please select Client'
      that.setState({
        showAlert: true,
        showResult: false,
        alertMessage: alertMessage
      })
    }
    else if (locationOption == null) {
      alertMessage = 'Please select location'
      that.setState({
        showAlert: true,
        showResult: false,
        alertMessage: alertMessage
      })
    }
    else if (alertTemplateOption == null) {
      alertMessage = 'Please select alert-template'
      that.setState({
        showAlert: true,
        showResult: false,
        alertMessage: alertMessage
      })
    }
    else {
      if(req.alert_time==45){
        if(selectedDuration.value%3!=0){
          alertMessage = '45 min intervals only works with Duration of 3,6,9 or 12 hours'
              that.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
              })
          return;
        }   
      }
      if (dutyId != "null") {
        req.id = Number(dutyId)
        req.duty_tid = dutyData.duty_tid
        auth.updateDuty(req)
          .then((res) => {
            if (res.data.code = 201) {
              alertMessage = 'Updated Successfully'
              that.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
              })
            }
          })
      }
      else {
        auth.registerDuty(req)
          .then((res) => {
            console.log("auth.register's res:", res);
            if (res.data.message != null) {
              if (res.data.code == 400) {
                console.log("res.data.message.email:", res.data.message.email, res.data.message.email != null);
                if (res.data.message.name != null) {
                  alertMessage += 'The Duty-name already exists.\n'
                }
                if (res.data.message.phone != null) {
                  alertMessage += 'This phone number already exists\n'
                }
                console.log(["res.data.message:", res.data.message, alertMessage]);
                that.setState({
                  showAlert: true,
                  showResult: false,
                  alertMessage: alertMessage
                })
                console.log(["res.data.message:", alertMessage, that.state.alertMessage]);
              }
              else if (res.data.code = 201) {
                console.log(["res.data.message:", res.data.message]);
                alertMessage = 'Created Successfully'
                that.setState({
                  showAlert: true,
                  showResult: true,
                  alertMessage: alertMessage
                })
              }
            }
          });
      }
    }
    // event.preventDefault();
  }

  render() {
    const { clientLst, clientOption, locationLst, locationOption,
      supervisorLst, supervisorOption,
      alertTemplateLst, alertTemplateOption,
      showExistAlert, durationList, minutesList, meterList,
      selectedDuration, selectedMinute, selectedMeter, rest_break } = this.state;

    return <div>
      <Card>
        <CompanyMenu></CompanyMenu>
        <Row className="toolbar up-card-title">
          <div className="b-title"><i className="ti-map-alt mr-10"></i>&nbsp;Duty Information</div>
        </Row>
        <Row className="toolbar up-card-box justify-content-center">
          <form name="myForm" style={{ width: "100%" }} onSubmit={this.onSave}>
            <Row>
              <div className="col-lg-6 col-12">
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-md-3 control-label">Alias</label>
                  <div className="col-md-9">
                    <input className="location-text-input" type="text" name="name" value={this.state.nameValue} onChange={this.handleChange} required></input>
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-md-3 control-label">Supervisor</label>
                  <div className="col-md-9">
                    <Select options={supervisorLst} value={supervisorOption} onChange={this.supervisorChange}></Select>
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label className="col-md-3 control-label">Client</label>
                  <div className="col-md-9">
                    <Select name="district_id" options={clientLst} value={clientOption}
                      onChange={this.clientChange} required />
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-md-3 control-label">Location</label>
                  <div className="col-md-9">
                    <Select name="district_id" options={locationLst} value={locationOption}
                      onChange={this.locationChange} required />
                  </div>
                </Row>
                <div className="height-10"></div>
              </div>
              <div className="col-lg-6 col-12" style={{ marginTop: "auto", marginBottom: "auto" }}>
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-lg-12 col-xl-3 control-label">StartTime</label>
                  <div className="col-lg-12 col-xl-9">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.dateHandleChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className="datepicker"
                    />
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-md-3 control-label">Alert Interval</label>
                  <div className="col-md-9">
                    <Select options={alertTemplateLst} value={alertTemplateOption} onChange={this.alertTemplateChange}></Select>
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label htmlFor="duration" className="col-md-3 control-label">Duration</label>
                  <div className="col-md-9">
                    <Select placeholder="Select duration" options={durationList} value={selectedDuration} onChange={this.setDurationChange}></Select>
                  </div>
                </Row>
                <Divider/>
                <Row className="company-text-area">
                  <label htmlFor="address" className="col-md-6 control-label">Rest break ON/OFF</label>
                  <div className="col-md-6">
                    <Switch checked={rest_break} onChange={this.onChange} />
                  </div>
                </Row>
                <Row className="company-text-area">
                  <label htmlFor="duration" className="col-md-6 control-label">Select max rest time (mins)</label>
                  <div className="col-md-6">
                    <Select placeholder="Select Minute" options={minutesList} value={selectedMinute} onChange={this.setMinutesChange}></Select>
                  </div>
                </Row>
                {!showExistAlert ? <div></div> : <Row className="company-text-area">
                  <div className='alertText'>* This guard has already duties, after create this duty, the previous duties will be removed </div>
                </Row>}
              </div>
              <div className="col-lg-6 col-12" style={{ marginTop: "auto", marginBottom: "auto" }}>
                
                {/* <Row className="company-text-area">
                  <label htmlFor="duration" className="col-md-7 control-label">Select length (m)</label>
                  <div className="col-md-5">
                    <Select placeholder="Select Length" options={meterList} value={selectedMeter} onChange={this.setMeterChange}></Select>
                  </div>
                </Row> */}
              </div>
            </Row>
            <Row className="d-flex justify-content-center">
              <Button type="submit" className="saveButton">Save Change</Button>
            </Row>
            <div className="height-10"></div>
          </form>

        </Row>
      </Card>
      <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
        {/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
        <ModalBody className="alert-body">
          <i className="mdi mdi-alert-circle ft-60"></i>
          <h2>{this.state.alertMessage}</h2>
          <div className="height-10"></div>
          <FormGroup>
            <Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
            <span>&nbsp;&nbsp;</span>
            <Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  }
}

export default CompanyView;
