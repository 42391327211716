import React from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SalesSummary, EmailCampaign, ActiveVisitors, Stats, Projects, RecentComment, Chat, PartData, ActivityOverview }
    from '../../components/dashboard/';
import { Doughnut, Line, Bar, Radar, Pie, Polar } from 'react-chartjs-2';
// import * as d from './chart/chartjs-data';
import * as d from '../charts/chartjs-data'
import Chart from 'react-c3-component';
import './index.scss'
import { auth } from '../../Api';

import img1 from '../../assets/images/users/1.jpg';
import img4 from '../../assets/images/users/4.jpg';
import img5 from '../../assets/images/users/5.jpg';

const color = ["#00264d", "#004d99", "#0066cc", "#0080ff", "#4da6ff", "#99ccff"]
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["#00264d", "#0066cc", "#4da6ff", "#004d99", "#0080ff", "#99ccff"],
            clientData: {
                labels: [
                    'Active Clients',
                    'Suponsed',
                    'InActive Clients'
                ],

                datasets: [{
                    data: d.chartData.doughnutData.data,
                    backgroundColor: [
                        '#004d99',
                        '#0080ff',
                        '#99ccff'
                    ],
                    hoverBackgroundColor: [
                        '#4da6ff',
                        '#0066cc',
                        '#00264d'
                    ]
                }]
            },
            guardData: {
                labels: [
                    'Active Guard',
                    'InActive Guard',
                    'Suponsed'
                ],

                datasets: [{
                    data: d.chartData.doughnutData.data,
                    backgroundColor: [
                        '#00264d',
                        '#0066cc',
                        '#4da6ff'
                    ],
                    hoverBackgroundColor: [
                        '#99ccff',
                        '#0080ff',
                        '#004d99'
                    ]
                }]
            },
            jsonData: {}
        }
    }

    componentDidMount() {
        let admin_id = localStorage.getItem('admin_id')
        let admin_level = localStorage.getItem('admin_level')
        if (admin_level == "c") {
            this.props.history.push('/company-dashboard')
        }
        let req = {}
        if (admin_level != "a")
            req.admin_id = admin_id
        auth.getDashboard(req)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        jsonData: res.data.message,
                        supervisors: res.data.message.supervisors,
                        clientData: {
                            labels: [
                                'Active Clients',
                                'Suponsed',
                                'InActive Clients'
                            ],

                            datasets: [{
                                data: [res.data.message.client_active,
                                res.data.message.client_suspend,
                                res.data.message.client_inactive],
                                backgroundColor: [
                                    '#004d99',
                                    '#0080ff',
                                    '#99ccff'
                                ],
                                hoverBackgroundColor: [
                                    '#4da6ff',
                                    '#0066cc',
                                    '#00264d'
                                ]
                            }]
                        },
                        guardData: {
                            labels: [
                                'Active Guard',
                                'InActive Guard',
                                'Suponsed'
                            ],
                            datasets: [{
                                data: [
                                    res.data.message.guard_active,
                                    res.data.message.guard_inactive,
                                    res.data.message.guard_suspend],
                                backgroundColor: [
                                    '#00264d',
                                    '#0066cc',
                                    '#4da6ff'
                                ],
                                hoverBackgroundColor: [
                                    '#99ccff',
                                    '#0080ff',
                                    '#004d99'
                                ]
                            }]
                        },
                    })
                }
            })
    }

    render() {
        let { jsonData, clientData, guardData, supervisors } = this.state
        let validatedAlert = jsonData.validated_alert != undefined ? jsonData.validated_alert : 0
        let missedAlert = jsonData.missed_alert != undefined ? jsonData.missed_alert : 0
        let totalAlert = validatedAlert + missedAlert
        let clients = jsonData.clients != undefined ? jsonData.clients : 0
        let guards = jsonData.guards != undefined ? jsonData.guards : 0
        return (
            <div>
                <Row>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                                <CardTitle>Clients - {clients} Total</CardTitle>
                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 350 }}>
                                    <Doughnut data={clientData} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } } }} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                                <CardTitle>Duties</CardTitle>
                                <div className="chart-wrapper" style={{ width: '100%', margin: '2px auto', height: 350 }}>
                                    <Chart
                                        config={{
                                            data: {
                                                columns: jsonData.duties_current != undefined ? [
                                                    ["Current Duties", jsonData.duties_current],
                                                    ["Completed Duties", jsonData.duties_past],
                                                    ["Future Duties", jsonData.duties_future]
                                                ] : [
                                                    ["Current Duties", 5],
                                                    ["Completed Duties", 150],
                                                    ["Future Duties", 10]
                                                ],
                                                type: "pie",
                                                onclick: function (o, n) { console.log("onclick", o, n) },
                                                onmouseover: function (o, n) { console.log("onmouseover", o, n) },
                                                onmouseout: function (o, n) { console.log("onmouseout", o, n) }
                                            },
                                            color: { pattern: ["#2962FF", "#4fc3f7", "#a1aab2"] }
                                        }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <CardBody>
                                <CardTitle>Guards - {guards} Total</CardTitle>
                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 350 }}>
                                    <Doughnut data={guardData} options={{ maintainAspectRatio: false, legend: { display: true, labels: { fontFamily: "Poppins" } } }} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <PartData title="Security Company" icon="fab fa-trello" color={color[0]} value={jsonData.companys} />
                    </Col>
                    <Col md={4}>
                        <PartData title="Supervisors" icon="mr-2 mdi mdi-account-alert" color={color[3]} value={jsonData.supervisors} />
                    </Col>
                    <Col md={4}>
                        <PartData title="District Managers" icon="mr-2 mdi mdi-account-settings" color={color[5]} value={jsonData.district_managers} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <PartData title="Clients" icon="mr-2 mdi mdi-account-multiple-outline" color={color[1]} value={jsonData.clients} />
                    </Col>
                    <Col md={4}>
                        <PartData title="Districts" icon="ti-map-alt" color={color[2]} value={jsonData.districts} />
                    </Col>
                    <Col md={4}>
                        <PartData title="Locations" icon="icon-location-pin" color={color[4]} value={jsonData.locations} />
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <CardTitle><div><i className="icon-chart"></i>&nbsp;BILLING STATUS</div></CardTitle>
                                <Chart
                                    config={{
                                        data: {
                                            columns: [
                                                ["data1", 50, 250, 90, 400, 300, 150],
                                                // ["data2", 30, 100, 85, 50, 15, 25]
                                            ],
                                            types: { data1: "bar" }
                                        },
                                        axis: { rotated: !0 },
                                        grid: { y: { show: !0 } },
                                        size: { height: 400 },
                                        color: { pattern: ["#4fc3f7", "#2962FF"] }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <ActivityOverview validatedAlert={validatedAlert} missedAlert={missedAlert} totalAlert={totalAlert} />
                    </Col>

                </Row>
            </div>
        );
    }
}

export default Dashboard;
