import { combineReducers } from 'redux';
import settings from './settings/reducer';
import chatReducer from "./chat/reducer";
import contactReducer from "./contacts/";
import emailReducer from "./email/";
import auth from './auth/reducer';
import dashboard from './dashboard/reducer';

const reducers = combineReducers({
    settings,
    auth,
    dashboard,
    chatReducer,
    contactReducer,
    emailReducer
});

export default reducers;