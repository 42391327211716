import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import CompanyMenu from '../companies/company-menu'
import { auth } from "../../Api";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { withRouter, Link } from "react-router-dom";
import { AutoComplete, Input as AInput } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { getCode, getName } = require('country-list');

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			originData: [],
			data: data.makeData(),
			treedata: data.treedata,
			companyName: '',
			defaultPageSize: 5
		};
		this.toggle = this.toggle.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		var defaultPageSize = localStorage.getItem('defaultPageSize')
		if (defaultPageSize != undefined) {
			this.setState({
				defaultPageSize: defaultPageSize
			})
		}
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')
		var req = {}
		req.company_id = companyId
		auth.getClient(req)
			.then((res) => {
				if (res.data.code == 200) {
					this.setState({
						companyName: companyName,
						jsonData: res.data.message.users,
						originData: res.data.message.users
					})
				}
			});
	}

	toggle() {
		console.log(this.state.modal);
		this.setState({
			modal: !this.state.modal
		});
	}

	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}

	handleChange(index) {
		console.log("checked:", index)
		let { jsonData } = this.state;
		jsonData[index].active = !jsonData[index].active
		console.log(jsonData[index].active)
		this.setState({ jsonData });
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phone = event
	}

	handleSubmit = (event) => {
		let { obj } = this.state;
		event.preventDefault();
		obj.name = event.target.name.value;
		obj.pobox = event.target.pobox.value;
		auth.updateClient(obj)
			.then((res) => {
				console.log("res after update:", res)
			})
		this.setState({
			modal: !this.state.modal
		})
		this.componentDidMount()
	}

	goLocation = (prop) => {
		localStorage.setItem('clientId', prop.id)
		localStorage.setItem('clientName', prop.name)
		this.props.history.push('/client-location/' + prop.id)
	}

	deleteRow() {
		let { obj } = this.state;
		auth.deleteClient(obj)
			.then((res) => {
				console.log("res after delete:", res)
			})
		this.setState({
			alertModal: !this.state.alertModal
		})
		this.componentDidMount()
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.name.toLowerCase().includes(value.toLowerCase())
				|| prop.sname.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	};

	onSizeChange = (size, page) => {
		console.log("size,page::", size, page)
		localStorage.setItem('defaultPageSize', size)
		this.setState({
			defaultPageSize: size
		})
	}

	render() {
		const { data, obj, companyName, defaultPageSize } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			console.log("prop:", prop)
			if (prop.active == 1) prop.active = true
			if (prop.active == 0) prop.active = false
			return {
				id: key,
				name: (
					<a onClick={() => this.props.history.push('/client-info/' + prop.id)} style={{ cursor: "pointer" }}>{prop.name + " " + prop.sname}</a>
				),
				phone: prop.phone,
				email: prop.email,
				pobox: prop.pobox,
				country: getName(prop.countrycode),
				noguard: prop.noguard,
				nolocation: prop.nolocation,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={() => this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active} />
					</div>
				),
				edit: (
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "white" }} /></Button>
						<Button className="btn-table-remove" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
					</div>
				),
				linkPage: (
					<div>
						<span onClick={() => this.goLocation(prop)} style={{ cursor: "pointer" }}><i className="fas fa-pencil-alt"></i>Edit Location</span>
					</div>
				)
			};
		});

		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Client Edit</h3></ModalHeader>
				{
					obj == {} ? <div></div> : <ModalBody>
						<Form onSubmit={this.handleSubmit}>
							{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
							<FormGroup>
								<Label for="name">Client Name</Label>
								<Input type="text" name="name" id="name" defaultValue={obj.name} />
							</FormGroup>
							<FormGroup>
								<Label for="age">Phone</Label>
								<PhoneInput
									placeholder="Enter phone number"
									value={obj.phone}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</FormGroup>
							<FormGroup>
								<Label for="country">Address</Label>
								<Input type="text" name="pobox" id="pobox" defaultValue={obj.pobox} />
							</FormGroup>
							<FormGroup style={{ textAlign: "center" }}>
								<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
								<Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this client?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={() => this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
			<div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Clients - {companyName}</span></div>
						<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={[]}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search" />
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "Client Name",
								accessor: "name"
							},
							{
								Header: "PHONE",
								accessor: "phone"
							},
							{
								Header: "EMAIL",
								accessor: "email"
							},
							{
								Header: "Address",
								accessor: "pobox"
							},
							{
								Header: "NO.Of Guard",
								accessor: "noguard"
							},
							{
								Header: "NO.Of Location",
								accessor: "nolocation"
							},
							{
								Header: "Actions",
								accessor: "linkPage",
								// id: "row",
								maxWidth: 150,
								filterable: false,
							},
							{
								Header: "IsLogged",
								accessor: "actions",
								sortable: false,
								filterable: false
							},
							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 100,
								filterable: false,

							},
						]}
						pageSize={defaultPageSize}
						showPaginationBottom={true}
						pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]}
						onPageSizeChange={(size, number) => this.onSizeChange(size, number)}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<Button onClick={() => { this.props.history.push('/client-edit/null') }} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Client
						</Button>
						{/* <Button className="btn" color="primary" size="lg" active>
							<a href="/client-edit" ><i className="fas fa-user-plus"></i> Add New Client</a>
						</Button> */}
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default withRouter(ReSellers);
