const jsondata = [
    {
        "name": "Airi Satou",
        "gender": "Female",
        "company": "Quantum Computer Services",
        "age": 22
    },
    {
        "name": "Claudine Neal",
        "gender": "Female",
        "company": "BackRub",
        "age": 55
    },
    {
        "name": "Beryl Rice",
        "gender": "Female",
        "company": "Best Buy",
        "age": 67
    },
    {
        "name": "Wilder Gonzales",
        "gender": "Male",
        "company": "Apple, Inc."
    },
    {
        "name": "Georgina Schultz",
        "gender": "Female",
        "company": "Blackberry"
    },
    {
        "name": "Carroll Buchanan",
        "gender": "Male",
        "company": "Pepsi-Cola"
    },
    {
        "name": "Valarie Atkinson",
        "gender": "Female",
        "company": "Tokyo Tsushin Kogyo"
    },
    {
        "name": "Schroeder Mathews",
        "gender": "Male",
        "company": "Starbucks"
    },
    {
        "name": "Lynda Mendoza",
        "gender": "Female",
        "company": "Nike"
    },
    {
        "name": "Sarah Massey",
        "gender": "Female",
        "company": "Hertz Rent-A-Car"
    },
    {
        "name": "Robles Boyle",
        "gender": "Male",
        "company": "eBay"
    },
    {
        "name": "Evans Hickman",
        "gender": "Male",
        "company": "International Business Machines"
    },
    {
        "name": "Dawson Barber",
        "gender": "Male",
        "company": "Nintendo"
    },
    {
        "name": "Bruce Strong",
        "gender": "Male",
        "company": "SUBWAY"
    },
    {
        "name": "Nellie Whitfield",
        "gender": "Female",
        "company": " Circuit City"
    },
    {
        "name": "Jackson Macias",
        "gender": "Male",
        "company": "Firefox"
    },
    {
        "name": "Pena Pena",
        "gender": "Male",
        "company": "PayPal"
    },
    {
        "name": "Lelia Gates",
        "gender": "Female",
        "company": "Yahoo"
    },
    {
        "name": "Letitia Vasquez",
        "gender": "Female",
        "company": "Wrappixel"
    },
    {
        "name": "Trevino Moreno",
        "gender": "Male",
        "company": "Blackbuck"
    },
    {
        "name": "Barr Page",
        "gender": "Male",
        "company": "Apex"
    },
    {
        "name": "Kirkland Merrill",
        "gender": "Male",
        "company": "Nokia"
    },
    {
        "name": "Blanche Conley",
        "gender": "Female",
        "company": "Reliance Industries"
    }
]

export { jsondata };