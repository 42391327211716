import React from 'react';
import logodarktext from '../../../assets/images/logo.png';
import logolighttext from '../../../assets/images/logo-light-text.png';
import './footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer footer-align"
       style={{background: "#e4e4e4",alignItems:'center'}}>
        <span className="logo-text">
          <img src={logodarktext} width="100" height="30" alt="homepage" className="dark-logo" />
        </span>
        <span> 2018 - 2024 © Mobiliis</span>
      </footer>
    );
  }
}
export default Footer;
