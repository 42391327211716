import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { auth } from '../../Api';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';

const createEventId = () => uuidv4();

const MyCalendar = (props) => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [company, setCompany] = useState({});

  useEffect(()=>{
    if(props.guardId!=undefined){
      var req = {}
      req.guard_id = props.guardId
      req.company_id = localStorage.getItem('companyId')
      auth.eventGet(req)
            .then((res) => {
                if (res.data.code == 200) {
                  var eventList=[]
                  res.data.message.users.map((prop,key)=>{
                    const newEvent = {
                      id: prop.calendar_id,
                      title:prop.title,
                      start: prop.start,
                      startMilli: prop.startMilli,
                      end: prop.end,
                      endMilli: prop.endMilli,
                      allDay: prop.allDay==1?true:false,
                      guard_id: props.guard_id,
                      admin_id: props.admin_id
                    };
                    eventList.push(newEvent)
                  })
                  setCompany(res.data.message.company)
                  setCurrentEvents(eventList);
                }
            })
    }
  },[props])

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // Clear date selection

    // const startDate = selectInfo.start;
    // const endDate = selectInfo.end;

    // // Calculate the difference in days
    // const diffTime = Math.abs(endDate - startDate);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // if (diffDays > 1) {
    //   return;
    // } 
    
    let date = new Date()
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: company.timezone }));
    let locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
    let currentOffset = (new Date()).getTimezoneOffset()
    let targetOffset = currentOffset - locationOffset
    console.log("targetOffset===:",targetOffset,company.timezone)
    const dateStart = moment.tz(selectInfo.startStr, company.timezone);
    const dateEnd = moment.tz(selectInfo.endStr, company.timezone);

    var valid = true;
    currentEvents.forEach((event)=>{
      if(event.startMilli>=dateEnd.valueOf() || event.endMilli<=dateStart.valueOf()){
        valid=true
      }else{
        valid=false;
        return;
      }
    });

    if(valid==false){
      alert("Holidays can't be duplicated on the same day.")
      return;
    }

    let title = prompt('Please enter a new title for your event:');
    if (title) {
      const newEvent = {
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        startMilli: dateStart.valueOf(),
        end: selectInfo.endStr,
        endMilli: dateEnd.valueOf(),
        allDay: selectInfo.allDay,
        guard_id: props.guardId,
        admin_id: localStorage.getItem('admin_id'),
        company_id: localStorage.getItem('companyId')
      };
      setCurrentEvents(currentEvents => [...currentEvents, newEvent]);
      console.log("new Event====oo:", newEvent)
      auth.eventRegister(newEvent)
        .then(res=>{
          console.log("res value:",res)
        })
    }
  };

  const handleEventClick = (clickInfo) => {
    if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'?`)) {
      clickInfo.event.remove(); // Removes the event from the calendar
      setCurrentEvents(currentEvents =>
        currentEvents.filter(event => event.id !== clickInfo.event.id)
      );
      auth.eventDelete({id:clickInfo.event.id})
    }
  };

  const handleEventChange = (changeInfo) => {
    // Optionally handle event changes (drag & drop, resizing)
    console.log('Event changed:', changeInfo.event);
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: ''
          // right: 'timeGridWeek, dayGridMonth'
        }}
        initialView="dayGridMonth"
        // editable={true}
        selectable={true}
        
        // selectMirror={true}
        // dayMaxEvents={true}
        weekends={true}
        select={handleDateSelect}
        events={currentEvents}
        eventClick={handleEventClick}
        eventChange={handleEventChange} // For drag & drop or resizing
      />
    </div>
  );
};

export default MyCalendar;
