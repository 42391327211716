import React, { Component } from 'react';
import ReactTable from "react-table";
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import CompanyMenu from '../companies/company-menu'
import classnames from 'classnames';
import avatar from '../../assets/images/users/3.jpg'
import ApexPieA from '../../components/apexpie-a'
import { auth } from '../../Api';
import { baseURL, baseImgURL } from '../../Api/BaseApi';
import { AutoComplete, Input as AInput } from 'antd';
import { CirclesWithBar } from 'react-loader-spinner'
import { Pagination } from 'antd';
import MyCalendar from './calendar-container';

var tzOffset = require("tz-offset")
class ReSellers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            open: false,
            modal: false,
            alertModal: false,
            obj: {},
            jsonData: [],
            originData: [],
            guardData: [],
            pastDutyData: [],
            midConfirmed: 5,
            midMissed: 5,
            startConfirmed: 5,
            startMissed: 5,
            endConfirmed: 5,
            endMissed: 5,
            data: data.makeData(),
            activeTab: '1',
            options: {},
            series: [44, 55, 41, 17, 15],
            labels: ['A', 'B', 'C', 'D', 'E'],
            guard: null,
            location: null,
            avatarUrl: '',
            loadingData: true,
            currentPage: 1,
            perPage: 5,
            totalCount: 500,
            showTable: true
        };
        this.toggle2 = this.toggle2.bind(this);
        this.toggle = this.toggle.bind(this);
        this.alertToggle = this.alertToggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        const { guard } = this.props.match.params
        var reqguard = {}
        reqguard.id = guard
        auth.getGuard(reqguard)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        guardData: res.data.message.users
                    })
                }
            })
        var req = {}
        if (guard != "null") {
            req.guard_id = guard
        }

        auth.getDuty(req)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        jsonData: res.data.message.users,
                        originData: res.data.message.users,
                        midConfirmed: res.data.message.mid_confirmed,
                        midMissed: res.data.message.mid_missed,
                        startConfirmed: res.data.message.start_confirmed,
                        startMissed: res.data.message.start_missed,
                        endConfirmed: res.data.message.end_confirmed,
                        endMissed: res.data.message.end_missed,
                        loadingData: false
                    })
                }
            })
        this.setState({
            guard: guard,
            alertModal: false,
        })
    }
    alertToggle() {
        this.setState({
            alertModal: !this.state.alertModal
        });
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }
    toggle2(tab) {
        if (this.state.activeTab !== tab) {
            if (tab == '2' && this.state.pastDutyData.length == 0) {
                this.setState({
                    loadingData: true
                })
                var req = {}
                req.is_past = true
                req.guard_id = this.state.guard
                req.current_page = 1
                req.per_page = 5
                auth.getDuty(req)
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.setState({
                                pastDutyData: res.data.message.users,
                                loadingData: false,
                                totalCount: res.data.message.totalCount
                            })
                        }
                    })
            } else {
                this.setState({
                    loadingData: false
                })
            }
            this.setState({
                'activeTab': tab
            });
        }
    }

    changePagination(page, pageSize) {
        this.getPastDutyOnePage(page, pageSize)
    }

    changePageSize(current, size) {
        this.setState({
            perPage: size,
            showTable: false
        })
        if (current == 0) {
            this.getPastDutyOnePage(1, size)
            this.setState({ currentPage: 1 })
        } else {
            this.getPastDutyOnePage(current, size)
        }
        setTimeout(() => this.setState({ showTable: true }), 0)
    }

    getPastDutyOnePage(current, size) {
        this.setState({
            loadingData: true
        })
        var req = {}
        req.is_past = true
        req.guard_id = this.state.guard
        req.current_page = current
        req.per_page = size
        auth.getDuty(req)
            .then((res) => {
                if (res.data.code == 200) {
                    this.setState({
                        pastDutyData: res.data.message.users,
                        loadingData: false,
                        currentPage: current,
                    })
                }
            })
    }
    handleChange(index) {
        let { jsonData } = this.state;
        jsonData[index].active = !jsonData[index].active
        this.setState({ jsonData });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let id = event.target.id.value;
        let name = event.target.name.value;
        let designation = event.target.designation.value;
        let location = event.target.location.value;
        let age = event.target.age.value;
        let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
        newObj[id] = [name, designation, location, age];
        this.setState({
            jsonData: newObj,
            modal: !this.state.modal
        })
    }

    displayDate(value) {
        var endtime = new Date(value)
        return endtime.getFullYear() + '-' + (endtime.getMonth() + 1) + '-' + endtime.getDate()
            + ' ' + (endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours())
            + ":" + (endtime.getMinutes() < 10 ? "0" + endtime.getMinutes() : endtime.getMinutes()) + ":" + "00";
    }

    deleteRow() {
        let { obj } = this.state;
        let req = {}
        req.id = obj.id
        var that = this
        auth.deleteDuty(obj)
            .then((res) => {
                that.componentDidMount()
            })
    }

    handleSearch = (value) => {
        var searchData = [];
        this.state.originData.map((prop, key) => {
            if (prop.firstname.toLowerCase().includes(value.toLowerCase())
                || prop.secondname.toLowerCase().includes(value.toLowerCase())) {
                searchData.push(prop)
            }
        })
        this.setState({ jsonData: searchData })
    };


    render() {
        const { obj, guardData,totalCount, currentPage, perPage, showTable
        } = this.state;
        const data2 = this.state.jsonData.map((prop, key) => {
            let date = new Date()
            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone: prop.timezone }));
            let locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
            let currentOffset = (new Date()).getTimezoneOffset()
            let targetOffset = currentOffset - locationOffset
            return {
                id: key,
                name: (
                    <a onClick={() => this.props.history.push('/duty-detail/' + prop.id)} style={{ cursor: "pointer" }}>{prop.name}</a>
                ),
                client_name: prop.client_name,
                location_name: prop.location_name,
                supervisor_name: prop.supervisor_name,
                guard_name: prop.guard_name,
                starttime: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime)) : prop.timezone}</div>,
                starttime_locationzone: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime + targetOffset * 60 * 1000)) : prop.timezone}</div>,
                duration: prop.duration,

                nomissedalert: prop.nomissedalert,
                statestart: prop.statestart,
                stateend: prop.stateend,
                actions: (
                    <div className="text-center" key={key}>
                        <Switch onChange={() => this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active==1?true:false} />
                    </div>
                ),
                edit: (
                    <div className="justify-content-center">
                        <Button className="btn-table-remove" onClick={() => {
                            let obj = data2.find(o => o.id === key);
                            this.setState({
                                alertModal: !this.state.alertModal,
                                obj: prop,
                            });
                        }}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
                    </div>
                ),
                linkPage: (
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h6 className="panel-title">
                                <a onClick={function () {
                                    this.setState({ open: !this.state.open })
                                }.bind(this)} ><i className="fas fa-plus"></i>View</a>
                            </h6>
                        </div>
                        <div className={this.state.open ? "panel-collapse" : "panel-collapse panel-close"}>
                            <ul className="list-group">
                                <li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
                                <li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
                                <li className="list-group-item"><i className="mr-2 mdi mdi-account-settings mr-10"></i>Districts Managers</li>
                                <li className="list-group-item"><i className="icon-shield mr-10"></i>Guards</li>
                                <li className="list-group-item"><i className="mr-2 mdi mdi-account-alert mr-10"></i>Supervisors</li>
                                <li className="list-group-item"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>Clients</li>
                                <li className="list-group-item"><i className="icon-bell mr-10"></i>Alerts Template</li>
                                <li className="list-group-item"><i className="ti-align-left mr-10"></i>Duties</li>
                                <li className="list-group-item"><i className="icon-chart mr-10"></i>Billing</li>
                            </ul>
                        </div>
                    </div>
                )
            };
        });
        const data3 = this.state.pastDutyData.map((prop, key) => {
            let date = new Date()
            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            let tzDate;
            let locationOffset;

            let currentOffset;

            let targetOffset;

            if (prop.timezone != null) {
                tzDate = new Date(date.toLocaleString('en-US', { timeZone: prop.timezone }));
                locationOffset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
                currentOffset = (new Date()).getTimezoneOffset()
                targetOffset = currentOffset - locationOffset
            }
            return {
                id: key,
                name: (
                    <a onClick={() => this.props.history.push('/duty-detail/' + prop.id)} style={{ cursor: "pointer" }}>{prop.name}</a>
                ),
                client_name: prop.client_name,
                location_name: prop.location_name,
                supervisor_name: prop.supervisor_name,
                guard_name: prop.guard_name,
                starttime: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime)) : prop.timezone}</div>,
                starttime_locationzone: <div>{prop.timezone != null ? this.displayDate(parseInt(prop.starttime + targetOffset * 60 * 1000)) : prop.timezone}</div>,
                duration: prop.duration,
                trackRate: (prop.online + prop.offline).toString() + '/' + (Math.ceil(prop.duration / 2)).toString(),
                online: prop.online,
                offline: prop.offline,
                nomissedalert: prop.nomissedalert,
                statestart: prop.statestart,
                stateend: prop.stateend,
                actions: (
                    <div className="text-center">
                        <Switch onChange={() => this.handleChange(key)} uncheckedIcon checkedIcon offColor="#ddd" checked={prop.active==1?true:false} />
                    </div>
                ),
                edit: (
                    <div className="justify-content-center">
                        <Button className="btn-table-remove ml-3" onClick={() => {
                            let obj = data2.find(o => o.id === key);
                            this.setState({
                                alertModal: !this.state.alertModal,
                                obj: prop,
                            });
                        }}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
                    </div>
                )
            };
        });

        return <div>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Action table*/}
            {/*--------------------------------------------------------------------------------*/}
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}><h3>Duty Edit</h3></ModalHeader>
                {
                    obj == {} ? <div></div> : <ModalBody>
                        <Form onSubmit={this.handleSubmit}>
                            {/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
                            <FormGroup>
                                <Label for="name">Duty Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={obj.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">StartTime</Label>
                                <Input type="text" name="name" id="name" defaultValue={obj.name} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Duration</Label>
                                <Input type="text" name="name" id="name" defaultValue={obj.name} />
                            </FormGroup>
                            <FormGroup style={{ textAlign: "center" }}>
                                <Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
                                <Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>}
            </Modal>
            <Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
                {/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
                <ModalBody className="alert-body">
                    <i className="mdi mdi-alert-circle ft-60"></i>
                    <h2>Are you sure you want to delete this Duty?</h2>
                    <div>You won't be able to revert this!</div>
                    <div className="height-10"></div>
                    <FormGroup>
                        <Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
                        <span>&nbsp;&nbsp;</span>
                        <Button color="primary" className="modal-btn mr-1" onClick={() => this.deleteRow()}>Yes, Delete It!</Button>
                    </FormGroup>
                </ModalBody>
            </Modal>
            <CompanyMenu></CompanyMenu>
            <div className="height-10"></div>
            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <Row className="toolbar up-card-title">
                        <div className="b-title"><i className="mdi mdi-buffer mr-10"></i>&nbsp;General Information</div>
                    </Row>
                    <Row className="d-flex mr-0 ml-0" style={{ alignItems: "center" }}>
                        {guardData.length != 0 ? <div className="toolbar up-card-box col-lg-6">
                            <div className="pt-3">
                                <img src={guardData[0].avatar != null ? guardData[0].avatar == "/storage/avatar.jpg" ? avatar 
                                : baseImgURL + guardData[0].avatar : avatar} className="img-company"></img></div>
                            <div>
                                <Row className="company-text-area">
                                    <div className="company-text-key">Name</div>
                                    <div className="company-text-value">{guardData[0].firstname + ' ' + guardData[0].secondname}</div>
                                </Row>
                                <Row className="company-text-area">
                                    <div className="company-text-key">Phone</div>
                                    <div className="company-text-value">{guardData[0].phone}</div>
                                </Row>

                                <Row className="company-text-area">
                                    <div className="company-text-key">Active Company</div>
                                    <div className="company-text-value">{guardData[0].company_name}</div>
                                </Row>
                                <Row className="company-text-area">
                                    <div className="company-text-key">CID</div>
                                    <div className="company-text-value">{guardData[0].CID}</div>
                                </Row>
                            </div>
                        </div> : <div className="toolbar up-card-box">
                            <div><img src={avatar} className="img-company"></img></div>
                            <div>
                                <Row className="company-text-area">
                                    <div className="company-text-key">Name</div>
                                    <div className="company-text-value">Trial Trial</div>
                                </Row>
                                <Row className="company-text-area">
                                    <div className="company-text-key">Phone</div>
                                    <div className="company-text-value">+1234567890</div>
                                </Row>

                                <Row className="company-text-area">
                                    <div className="company-text-key">Active Company</div>
                                    <div className="company-text-value">True</div>
                                </Row>
                                <Row className="company-text-area">
                                    <div className="company-text-key">CID</div>
                                    <div className="company-text-value">CID000</div>
                                </Row>
                            </div>
                        </div>}
                        <Row className="col-lg-6">
                            {/* <ApexPieA className="col-md-3" title="Confirmed/Missed Alert" confirmed={midConfirmed} missed={midMissed}></ApexPieA> */}
                            <MyCalendar guardId={this.state.guard}/>
                        </Row>
                    </Row>
                </CardTitle>
                <CardBody>
                    <Row className="table-header">
                        <div className="table-title center-center">
                            <i className="ti-align-left"></i>
                            <span> Guard Duties</span>{this.state.loadingData && <span className="table-title center-center" style={{ color: 'green' }}>&nbsp;Loading
                                <CirclesWithBar
                                    height="25"
                                    width="25"
                                    radius="3"
                                    color="green"
                                    ariaLabel="loading"
                                    wrapperStyle
                                    wrapperClass
                                /></span>}</div>

                        <div style={{ width: 300 }} className="auto-complete">
                            <AutoComplete
                                dropdownMatchSelectWidth={252}
                                style={{
                                    width: 300,
                                }}
                                options={[]}
                                onSearch={this.handleSearch}
                            >
                                <AInput size="large" placeholder="Search" />
                            </AutoComplete>
                        </div>
                    </Row>
                    <div className="nav-header-cus">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        'active': this.state.activeTab === '1'
                                    })}
                                    onClick={() => { this.toggle2('1'); }}>
                                    Current/Future Duties

                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        'active': this.state.activeTab === '2'
                                    })}
                                    onClick={() => { this.toggle2('2'); }}>
                                    Past Duties
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index + 1}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Client",
                                        accessor: "client_name"
                                    },
                                    {
                                        Header: "Location",
                                        accessor: "location_name"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "guard_name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "supervisor_name"
                                    },
                                    {
                                        Header: "Start DateTime",
                                        accessor: "starttime"
                                    },
                                    {
                                        Header: "Local Time",
                                        accessor: "starttime_locationzone"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "duration"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "nomissedalert"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "statestart"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "stateend"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        maxWidth: 70,
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor: "edit",
                                        id: "row",
                                        maxWidth: 70,
                                        filterable: false,

                                    },
                                ]}
                                defaultPageSize={5}
                                showPaginationBottom={false}
                                data={data2}
                            />
                        </TabPane>

                        <TabPane tabId="2">
                            {showTable && <ReactTable className="tab-Table"
                                columns={[
                                    {
                                        Header: "No",
                                        id: "row",
                                        maxWidth: 50,
                                        filterable: false,
                                        Cell: (row) => {
                                            return <div>{row.index + 1 + (currentPage - 1) * perPage}</div>;
                                        }
                                    },
                                    {
                                        Header: "Duty Alias",
                                        accessor: "name"
                                    },
                                    {
                                        Header: "Client",
                                        accessor: "client_name"
                                    },
                                    {
                                        Header: "Location",
                                        accessor: "location_name"
                                    },
                                    {
                                        Header: "Guard",
                                        accessor: "guard_name"
                                    },
                                    {
                                        Header: "Supervisor",
                                        accessor: "supervisor_name"
                                    },
                                    {
                                        Header: "Start Date & Time",
                                        accessor: "starttime"
                                    },
                                    {
                                        Header: "Local Time",
                                        accessor: "starttime_locationzone"
                                    },
                                    {
                                        Header: "Duration",
                                        accessor: "duration"
                                    },
                                    {
                                        Header: "Tracking Rate",
                                        accessor: "trackRate"
                                    },
                                    {
                                        Header: "Tracking Online",
                                        accessor: "online"
                                    },
                                    {
                                        Header: "Tracking Offline",
                                        accessor: "offline"
                                    },
                                    {
                                        Header: "Missed Alerts",
                                        accessor: "nomissedalert"
                                    },
                                    {
                                        Header: "Started In Location",
                                        accessor: "statestart"
                                    },
                                    {
                                        Header: "Ended in Location",
                                        accessor: "stateend"
                                    },
                                    {
                                        Header: "ACTIVE",
                                        accessor: "actions",
                                        maxWidth: 70,
                                        sortable: false,
                                        filterable: false
                                    },
                                    {
                                        Header: "",
                                        accessor: "edit",
                                        id: "row",
                                        maxWidth: 100,
                                        filterable: false,
                                    },
                                ]}
                                defaultPageSize={perPage}
                                showPaginationBottom={false}
                                data={data3}
                            // filterable
                            />}
                            <div className='center-center mt-2'>
                                <Pagination defaultCurrent={currentPage}
                                    defaultPageSize={perPage}
                                    total={totalCount}
                                    pageSizeOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30]}
                                    onChange={(page, pageSize) => this.changePagination(page, pageSize)}
                                    onShowSizeChange={(current, size) => this.changePageSize(current, size)}
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className="add-button">
                        <a onClick={() => this.props.history.push('/duty-edit/g' + this.state.guard)}><Button className="btn" color="primary" size="lg" active>
                            <i className="fas fa-user-plus"></i> Add New Duty
                        </Button></a>
                    </div>
                </CardBody>
            </Card>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Action table*/}
            {/*--------------------------------------------------------------------------------*/}
        </div>
    }
}
export default ReSellers;
