import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import {auth} from "../../Api";


const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			data: data.makeData(),
			showAlert:false,
			showResult:false,
			alertMessage:"",
			companyName:''
		};
		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.toggleEdit = this.toggleEdit.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')
		var req={}
		req.company_id = companyId 
		this.setState({
			company_id: companyId
		})
		auth.getAlertTemplate(req)
			.then((res)=>{
				if(res.data.code==200){
					console.log("supervisor:",res.data.message.users)
					this.setState({
						companyName:companyName,
						jsonData:res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	toggle2() {
		this.setState({
		  showAlert: !this.state.showAlert
		});
		this.componentDidMount()
	  }

	toggleEdit() {
		this.setState({
			modalEdit: !this.state.modalEdit
		});
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}
	
	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	  }

	handleEditSubmit = (event) => {
		let {company_id, modalEdit,obj} = this.state
		event.preventDefault();
				
		var req ={}
		req.id = obj.id;
		req.template_name = event.target.template_name.value;
		req.alert_time = event.target.alert_time.value;
		req.company_id = company_id

		auth.updateAlertTemplate(req)
			.then((res)=>{
				console.log("updated alerttemapltae")
			})
		this.setState({
			modalEdit:!modalEdit
		})
		this.componentDidMount()
	}

	handleSubmit = (event) => {
		let {company_id} = this.state
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let designation = event.target.designation.value;
		
		var req ={}
		req.template_name = name
		req.alert_time = designation
		req.company_id = company_id
		var that = this;
		let alertMessage = "";
		auth.registerAlertTemplate(req)
			.then((res)=>{
				if(res.data.code==400){
					if(res.data.message.template_name!=null)
						alertMessage+=res.data.message.template_name
					if(res.data.message.alert_time!=null)
						alertMessage+=res.data.message.alert_time
					that.setState({
						showAlert:true,
						showResult:false,
						alertMessage:alertMessage
					})
				}
				else if(res.data.code=201)   
				{
				  console.log(["res.data.message:",res.data.message]);
				  alertMessage='Created Successfully'
				  that.setState({
					showAlert:true,
					showResult:true,
					alertMessage:alertMessage
				})
			  }

			})
		this.setState({
			modal: !this.state.modal
		})
	}

	deleteRow () {
		let {obj} = this.state;
		auth.deleteAlertTemplate(obj)
			.then((res)=>{
				console.log("res after delete:",res)
			})
		this.setState({
			alertModal:!this.state.alertModal
		})
		this.componentDidMount()
	}

	render() {
		const { data, obj,companyName } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				template_name: (
					<a href="/company/company-view">{prop.template_name}</a>
					),
				alert_time: prop.alert_time,
				actions: (
					<div className="text-center">
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit:(
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modalEdit: !this.state.modalEdit,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"white"}}/></Button>
						<Button className="btn-table-remove"  onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{color:"white"}}/></Button>
					</div>
				),
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]
		
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		return <div>
			<Modal isOpen={this.state.modalEdit} toggle={this.toggleEdit}>
				<ModalHeader toggle={this.toggleEdit}>Edit alert-template</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleEditSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Alert Template Name</Label>
							<Input type="text" name="template_name" id="template_name" defaultValue={this.state.obj.template_name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Time Interval Between Alerts</Label>
							<Input type="text" name="alert_time" id="alert_time" defaultValue={this.state.obj.alert_time} />
							<span id="alert_time">min</span>
						</FormGroup>
						<FormGroup style={{textAlign:"center"}}>
							<Button color="primary" style={{width:'100px'}}  type="submit">Save</Button>
							<span>&nbsp;&nbsp;</span>
							<Button color="secondary" style={{width:'100px'}}  className="ml-1" onClick={this.toggleEdit}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>

			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this client?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary"  className="modal-btn mr-1" onClick={()=>this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>


			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>Create New template</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Alert Template Name</Label>
							<Input type="text" name="name" id="name" defaultValue={this.state.obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Time Interval Between Alerts</Label>
							<div class="container-alert">
								<Input type="text" name="designation" id="milliseconds" defaultValue={this.state.obj.designation} />
								<span id="alert_time" className="ms">min</span>
							</div>
							
						</FormGroup>
						<FormGroup style={{textAlign:"center"}}>
							<Button color="primary" style={{width:'100px'}}  type="submit">Save</Button>
							<span>&nbsp;&nbsp;</span>
							<Button color="secondary" style={{width:'100px'}}  className="ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>

			<Modal isOpen={this.state.showAlert} toggle={this.toggle2}>
			{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>{this.state.alertMessage}</h2>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" style={{width:'100px'}} className="ml-1" onClick={this.toggle2}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" style={{width:'100px'}} onClick={this.toggle2}>Ok</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
                <div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
					<div className="table-title"><i className="fas fa-users"></i><span> Alert Templates - {companyName}</span></div>
						<div style={{ width: 400 }} className="auto-complete">
					<ReactSearchAutocomplete
						items={items}
						onSearch={handleOnSearch}
						onSelect={handleOnSelect}
						onFocus={handleOnFocus}
						autoFocus
					/>
					</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "Template Name",
								accessor: "template_name"
							},
							{
								Header: "Alert Time",
								accessor: "alert_time"
							},
							
							{
								Header: "",
								accessor:"edit",
								id: "row",
								maxWidth: 100,
								filterable: false,
								
							},							
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					<div className="add-button">
						<Button onClick={this.toggle} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Template
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
