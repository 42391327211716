import React, { useEffect, useState, useRef } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
    ButtonGroup,
} from 'reactstrap';
import ReactTable from "react-table";
import { auth } from '../../Api'

function EditTrackStatus({ toggleTrackStatus, changeStatus, alertData }) {
    const basicStatus = alertData.HasBeenValidated == 1 ? true : false
    const [activeStatus, setActiveStatus] = useState(basicStatus)
    const [editHistory, setEditHistory] = useState([])
    const [reason, setReason] = useState("")
    const [showAlert, setShowAlert] = useState(false)
    const [alertContent, setAlertContent] = useState("")
    const [tableKey, setTableKey] = useState("table")

    useEffect(() => {
        var req = {}
        req.alert_id = alertData.id
        req.admin_id = localStorage.getItem('admin_id')
        auth.getAlertEditHistory(req)
            .then((res) => {
                if (res.data.code == 201) {
                    setTableKey(tableKey+"a")
                    setEditHistory(res.data.message.histories.slice().reverse())
                }
            })

    }, [alertData])

    const handleReasonInput = (e) => {
        if (e.target.value == "") {
            setShowAlert(true)
        } else {
            setShowAlert(false)
        }
        setReason(e.target.value)
    }

    const handleRadioChange = (e) => {
        setActiveStatus(!activeStatus)
        console.log("activeStatus====:", activeStatus, basicStatus)
        if (activeStatus != basicStatus) {
            setShowAlert(true)
            setAlertContent("Same status can't be saved")
        } else {
            setShowAlert(false)
        }
    }

    const changeAlertStatus = () => {
        console.log("alertdata====:", alertData, activeStatus, basicStatus)
        if (activeStatus == basicStatus) {
            setShowAlert(true)
            setAlertContent("Same status can't be saved")
            return
        }
        if (reason == "") {
            setShowAlert(true)
            setAlertContent("Please input the reason")
            return
        }
        var req = {}
        req.alert_id = alertData.id
        req.hasBeenValidated = (alertData.HasBeenValidated == 2 || alertData.HasBeenValidated == 0) ? 1 : 2
        req.outOfBounds = alertData.outOfBounds
        req.reason = reason
        req.user_id = localStorage.getItem('admin_id')
        auth.validateAlertAdmin(req)
            .then((res) => {
                if (res.data.code == 201) {
                    changeStatus(activeStatus ? 1 : 2)
                    toggleTrackStatus(activeStatus)
                }
            })
    }

    console.log("alertdata====reason:", reason)

    return <>
        <div className='center-start'>
            <h3>Activity Edit:</h3></div>
        <div className='center-start edit-input-label'>
            <div className='edit-key'>
                Activity Status:
            </div>
            <div className='edit-input-label center-evenly'>

                <div className='center-center'>
                    <input
                        type="radio"
                        id="option1"
                        value="option1"
                        checked={activeStatus}
                        onChange={() => handleRadioChange()}
                    />
                    <span
                        htmlFor="option1"
                    >
                        Clicked Alert
                    </span>
                </div>
                <div className='center-center'>
                    <input
                        type="radio"
                        id="option1"
                        value="option2"
                        checked={!activeStatus}
                        onChange={() => handleRadioChange()}
                    />
                    <span
                        htmlFor="option1"
                    >
                        Missed Alert
                    </span>
                </div>
            </div>
        </div>
        <div className='center-start edit-input-label'>
            <div className='edit-key'>
                Reason:
            </div>
            <textarea rows={2} onChange={handleReasonInput}></textarea>
        </div>
        {showAlert && <div className='center-start edit-input-label'>
            <div className='edit-key'>
            </div>
            <div className='edit-reason-alert'>
                {alertContent}
            </div>
        </div>}
        <div>
            <Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={toggleTrackStatus}>Cancel</Button>
            <span>&nbsp;&nbsp;</span>
            <Button color="primary" style={{ width: '100px' }} onClick={changeAlertStatus}>Submit</Button>
        </div>
        {
            editHistory.length > 0 && <div>
                <div className='center-start pt-3'>
                    <h4>Activity Editing History:</h4></div>
                <div>
                    <ReactTable className="tab-Table"
                    key={tableKey}
                        columns={[
                            {
                                Header: "No",
                                id: "row",
                                maxWidth: 50,
                                filterable: false,
                                Cell: (row) => {
                                    return <div>{row.index + 1}</div>;
                                }
                            },
                            {
                                Header: "Reason",
                                accessor: "reason",
                                minWidth: 150
                            },
                            {
                                Header: "Edit time",
                                accessor: "created_at",
                                minWidth:120
                            },
                            {
                                Header: "Editor",
                                accessor: "username"
                            },
                            {
                                Header: "Editor Email",
                                accessor: "useremail",
                                minWidth: 150
                            },
                        ]}
                        showPaginationBottom={false}
                        data={editHistory}
                        defaultPageSize={editHistory.length}
                    />
                </div>
            </div>
        }
    </>
}

export default React.memo(EditTrackStatus)