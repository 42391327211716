import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input,Row, InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const TreeTable = treeTableHOC(ReactTable);

class ReSellers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked:false,
			open: false,
			modal: false,
			obj: {},
			jsonData: data.dataTable,
			data: data.makeData(),
			treedata: data.treedata,
			contactType:"phone"
		};
		this.toggle = this.toggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	selectContactType=(value)=>{
		
		this.setState({
			contactType:value
		})
	}

	handleChange = (checked) => {
		console.log("checked:",checked)
		this.setState({ checked });
	  }

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
		let name = event.target.name.value;
		let location = event.target.location.value;
		let newObj = JSON.parse(JSON.stringify(this.state.jsonData));
		newObj[id] = [name,location];
		this.setState({
			jsonData: newObj,
			modal: !this.state.modal,
			
		})
	}

	render() {
		const { data,contactType } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				name: prop[0],
				designation: prop[1],
				location: prop[2],
				age: prop[3],
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
								console.log(["key:",key,obj.name]);
								this.setState({
									modal: !this.state.modal,
									obj: obj,
									
								});
							}}
							color="inverse"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-edit" />
						</Button> */}
						
						{/* use this button to remove the data row */}
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit:(
					<div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
							});
						}}><i className="mdi mdi-lead-pencil"  style={{color:"green"}}/></div>
						<div onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:",key,obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: obj,
							});
						}}><i className="fas fa-trash-alt" style={{color:"red"}}/></div>
					</div>
				),
				linkPage:(
					<div className="panel panel-default">
						<div className="panel-heading">
							<h6 className="panel-title">
							<a onClick={function(){
									console.log(this.state.open)
									this.setState({open:!this.state.open})}.bind(this)} ><i className="fas fa-plus"></i>View</a>
							</h6>
						</div>
						<div className={this.state.open? "panel-collapse": "panel-collapse panel-close"}>
							<ul className="list-group">
								<li className="list-group-item"><i className="mdi mdi-view-dashboard mr-10"></i>Dashboard</li>
								<li className="list-group-item"><i className="ti-map-alt mr-10"></i>Districts</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-settings mr-10"></i>Districts Managers</li>
								<li className="list-group-item"><i className="icon-shield mr-10"></i>Guards</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-alert mr-10"></i>Supervisors</li>
								<li className="list-group-item"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>Clients</li>
								<li className="list-group-item"><i className="icon-bell mr-10"></i>Alerts Template</li>
								<li className="list-group-item"><i className="ti-align-left mr-10"></i>Duties</li>
								<li className="list-group-item"><i className="icon-chart mr-10"></i>Billing</li>
							</ul>
						</div>
					</div>
				)
			};
		});
		const items = [
			{
			  id: 0,
			  name: 'Cobol'
			},
			{
			  id: 1,
			  name: 'JavaScript'
			},
			{
			  id: 2,
			  name: 'Basic'
			},
			{
			  id: 3,
			  name: 'PHP'
			},
			{
			  id: 4,
			  name: 'Java'
			}
		  ]
		
		  const handleOnSearch = (string, results) => {
			// onSearch will have as the first callback parameter
			// the string searched and for the second the results.
			console.log(string, results)
		  }
		
		  const handleOnSelect = (item) => {
			// the item selected
			console.log(item)
		  }
		
		  const handleOnFocus = () => {
			console.log('Focused')
		  }
		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>Add a New Contact</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id} />
						<FormGroup>
							<Label for="name">Name</Label>
							<Input type="text" name="name" id="name" defaultValue={this.state.obj.name} />
						</FormGroup>
						<FormGroup>
							<Label for="designation">Contact Type</Label>
							<InputGroup>
								{/* <InputGroupAddon addonType="prepend">
									<InputGroupText>Options</InputGroupText>
								</InputGroupAddon> */}
								<Input type="select" className="custom-select" onChange={this.selectContactType}>
									<option value="phone">PHONE</option>
									<option value="email">EMAIL</option>
								</Input>
							</InputGroup>
						</FormGroup>
						<FormGroup>
							<Label for="location">{contactType=="phone"?"Contact Phone":"Contact Email"}</Label>
							<Input type="text" name="location" id="location" defaultValue={this.state.obj.location} />
						</FormGroup>
						<FormGroup>
							<Button color="primary" type="submit">Save</Button>
							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
					
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
					<div className="table-title"><i className="mdi mdi-contact-mail"></i><span> Manage Support Contacts</span></div>
						<div style={{ width: 400 }} className="auto-complete">
					<ReactSearchAutocomplete
						items={items}
						onSearch={handleOnSearch}
						onSelect={handleOnSelect}
						onFocus={handleOnFocus}
						autoFocus
					/>
					</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index+1}</div>;
								}
							},
							{
								Header: "CONTACT LABEL",
								accessor: "name"
							},
							{
								Header: "CONTACT VALUE",
								accessor: "designation"
							},
							{
								Header: "CONTACT TYPE",
								accessor: "location"
							},
							
							// {
							// 	Header: "RELATED PAGES",
							// 	accessor:"linkPage",
							// 	// id: "row",
							// 	maxWidth: 150,
							// 	filterable: false,
								
							// },		
							{
								Header: "",
								accessor:"edit",
								id: "row",
								maxWidth: 50,
								filterable: false,
								
							},							
						]}
						defaultPageSize={5}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
						// filterable
					/>
					<div className="add-button">
						<Button onClick={this.toggle} className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Contact
						</Button>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default ReSellers;
