import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Button,Row, Col,
} from 'reactstrap';
import "react-table/react-table.css";
import {withRouter, Link} from "react-router-dom";

class CompanyMenu extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            companyId :props.companyId,
        }
    }

    componentDidMount(){
        let companyId = localStorage.getItem('companyId')
        this.setState({
            companyId: companyId
        })
    }
    render(){
        let {companyId} = this.state;
        return <div>
        <Row className="toolbar toolbar-cover">
            {/* <Row className="toolbar col-md-6"> */}
                <Col className="pl-0 pr-0 show-7btn-a"><a onClick={()=>{this.props.history.push('/company-dashboard')}}><Button className="btn-sec-custom"><i className="mdi mdi-view-dashboard"></i><span className="btn-text">Dashboard</span></Button></a></Col>
                <Col className="pl-0 pr-0 show-7btn-a"><a onClick={()=>{this.props.history.push('/district')}}><Button className="btn-sec-custom"><i className="ti-map-alt"></i><span className="btn-text">Districts</span></Button></a></Col>
                <Col className="pl-0 pr-0 show-7btn-b"><a onClick={()=>{this.props.history.push('/district-manager')}}><Button className="btn-sec-custom"><i className="mr-2 mdi mdi-account-settings"></i><span className="btn-text">District Manager</span></Button></a></Col>
                <Col className="pl-0 pr-0 show-7btn-a"><a onClick={()=>{this.props.history.push('/supervisor')}}><Button className="btn-sec-custom"><i className="mr-2 mdi mdi-account-alert"></i><span className="btn-text">Supervisors</span></Button></a></Col>
            {/* </Row>
            <Row className="toolbar col-md-6"> */}
                <Col className="pl-0 pr-0 show-7btn-b"><a onClick={()=>{this.props.history.push('/clients')}}><Button className="btn-sec-custom"><i className="mr-2 mdi mdi-account-multiple-outline"></i><span className="btn-text">Clients</span></Button></a></Col>
                <Col className="pl-0 pr-0 show-7btn-a"><a onClick={()=>{this.props.history.push('/guards')}}><Button className="btn-sec-custom"><i className="icon-shield"></i><span className="btn-text">Guards</span></Button></a></Col>
                {/* <Col className="pl-0 pr-0 col-md-3"><a onClick={()=>{this.props.history.push('/alert-template')}}><Button className="btn-sec-custom"><i className="icon-bell"></i><span className="btn-text">Alert Templates</span></Button></a></Col> */}
                {/* <Col className="pl-0 pr-0 show-7btn-b"><a onClick={()=>{this.props.history.push('/billing')}}><Button className="btn-sec-custom"><i className="icon-chart"></i><span className="btn-text">Billing</span></Button></a></Col> */}
            {/* </Row> */}
        </Row>
        </div>
    }
}
export default withRouter(CompanyMenu)



