import React from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
	Card,
	CardBody,
	CardTitle,
	Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import { auth } from "../../Api";
import { AutoComplete, Input as AInput } from 'antd';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { getCode, getName } = require('country-list');

const TreeTable = treeTableHOC(ReactTable);

class Supervisor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
			open: false,
			modal: false,
			obj: {},
			jsonData: [],
			originData: [],
			data: data.makeData(),
			treedata: data.treedata,
			companyName: ''
		};
		this.toggle = this.toggle.bind(this);
		this.alertToggle = this.alertToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		let companyId = localStorage.getItem('companyId')
		let companyName = localStorage.getItem('companyName')
		var req = {}
		req.company_id = companyId
		auth.getSupervisor(req)
			.then((res) => {
				if (res.data.code == 200) {
					this.setState({
						companyName: companyName,
						jsonData: res.data.message.users,
						originData: res.data.message.users
					})
				}
			});
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	alertToggle() {
		this.setState({
			alertModal: !this.state.alertModal
		});
	}

	handleChange = (checked) => {
		console.log("checked:", checked)
		this.setState({ checked });
	}

	setPhoneNumber = (event) => {
		console.log("event of phone:", event)
		let { obj } = this.state;
		obj.phone = event
	}

	handleSubmit = (event) => {
		let { obj } = this.state;
		event.preventDefault();
		obj.firstname = event.target.firstname.value;
		obj.secondname = event.target.secondname.value;
		obj.countrycode = 'CN';
		obj.phone = obj.phone;
		auth.updateSupervisor(obj)
			.then((res) => {
				console.log("res after update:", res)
			})
		this.setState({
			modal: !this.state.modal
		})
		this.componentDidMount()
	}

	deleteRow() {
		let { obj } = this.state;
		auth.deleteSupervisor(obj)
			.then((res) => {
				console.log("res after delete:", res)
			})
		this.setState({
			alertModal: !this.state.alertModal
		})
		this.componentDidMount()
	}

	handleSearch = (value) => {
		console.log(["searchData.length==value:", value])
		var searchData = [];
		this.state.originData.map((prop, key) => {
			if (prop.firstname.toLowerCase().includes(value.toLowerCase())
				|| prop.secondname.toLowerCase().includes(value.toLowerCase())) {
				searchData.push(prop)
			}
		})
		console.log(["searchData.length==00:", searchData.length])
		this.setState({ jsonData: searchData })
	};

	render() {
		const { data, obj, companyName } = this.state;
		const data2 = this.state.jsonData.map((prop, key) => {
			return {
				id: key,
				firstname: (
					<a onClick={() => { this.props.history.push('/supervisor-info/' + prop.id) }} style={{ cursor: "pointer" }}>{prop.firstname}</a>
				),
				secondname: prop.secondname,
				CID: prop.CID,
				phone: prop.phone,
				district: prop.district,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Switch onChange={this.handleChange} uncheckedIcon checkedIcon offColor="#ddd" checked={this.state.checked} />
					</div>
				),
				edit: (
					<div className="justify-content-center">
						<Button className="btn-table-edit ml-1" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								modal: !this.state.modal,
								obj: prop,
							});
						}}><i className="mdi mdi-lead-pencil" style={{ color: "white" }} /></Button>
						<Button className="btn-table-remove" onClick={() => {
							let obj = data2.find(o => o.id === key);
							console.log(["key:", key, obj.name]);
							this.setState({
								alertModal: !this.state.alertModal,
								obj: prop,
							});
						}}><i className="fas fa-trash-alt" style={{ color: "white" }} /></Button>
					</div>
				)
			};
		});


		return <div>
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}><h3>Supervisor Edit</h3></ModalHeader>
				{
					obj == {} ? <div></div> : <ModalBody>
						<Form onSubmit={this.handleSubmit}>
							{/* <Input type="hidden" name="id" id="id" defaultValue={obj.id} /> */}
							<FormGroup>
								<Label for="name">First Name</Label>
								<Input type="text" name="firstname" id="name" defaultValue={obj.firstname} />
							</FormGroup>
							<FormGroup>
								<Label for="name">Second Name</Label>
								<Input type="text" name="secondname" id="name" defaultValue={obj.secondname} />
							</FormGroup>
							<FormGroup>
								<Label for="age">Phone</Label>
								<PhoneInput
									placeholder="Enter phone number"
									value={obj.phone}
									className='phonenumber-input'
									onChange={this.setPhoneNumber} />
							</FormGroup>
							<FormGroup style={{ textAlign: "center" }}>
								<Button color="primary" className="modal-btn mr-1" type="submit">Save</Button>
								<Button color="secondary" className="modal-btn ml-1" onClick={this.toggle}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>}
			</Modal>
			<Modal isOpen={this.state.alertModal} toggle={this.alertToggle}>
				{/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
				<ModalBody className="alert-body">
					<i className="mdi mdi-alert-circle ft-60"></i>
					<h2>Are you sure you want to delete this Supervisor?</h2>
					<div>You won't be able to revert this!</div>
					<div className="height-10"></div>
					<FormGroup>
						<Button color="secondary" className="modal-btn ml-1" onClick={this.alertToggle}>Cancel</Button>
						<span>&nbsp;&nbsp;</span>
						<Button color="primary" className="modal-btn mr-1" onClick={() => this.deleteRow()}>Yes, Delete It!</Button>
					</FormGroup>
				</ModalBody>
			</Modal>
			{/*--------------------------------------------------------------------------------*/}
			{/* Start Action table*/}
			{/*--------------------------------------------------------------------------------*/}
			<CompanyMenu></CompanyMenu>
			<div className="height-10"></div>
			<Card>
				{/* <CardTitle className="mb-0 p-3 border-bottom bg-light">
				</CardTitle> */}
				<CardBody>
					<Row className="table-header">
						<div className="table-title"><i className="fas fa-users"></i><span> Supervisors - {companyName}</span></div>
						<div style={{ width: 300 }} className="auto-complete">
							<AutoComplete
								dropdownMatchSelectWidth={252}
								style={{
									width: 300,
								}}
								options={[]}
								onSearch={this.handleSearch}
							>
								<AInput size="large" placeholder="Search" />
							</AutoComplete>
						</div>
					</Row>
					<ReactTable
						columns={[
							{
								Header: "No",
								id: "row",
								maxWidth: 50,
								filterable: false,
								Cell: (row) => {
									return <div>{row.index + 1}</div>;
								}
							},
							{
								Header: "First Name",
								accessor: "firstname"
							},
							{
								Header: "Last Name",
								accessor: "secondname"
							},
							{
								Header: "CID",
								accessor: "CID"
							},
							{
								Header: "PHONE",
								accessor: "phone"
							},
							{
								Header: "District",
								accessor: "district"
							},
							{
								Header: "",
								accessor: "edit",
								id: "row",
								maxWidth: 100,
								filterable: false,
							},
						]}
						defaultPageSize={10}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data2}
					// filterable
					/>
					<div className="add-button">
						<a onClick={() => { this.props.history.push('/supervisor-edit/null') }}><Button className="btn" color="primary" size="lg" active>
							<i className="fas fa-user-plus"></i> Add New Supervisor
						</Button></a>
					</div>
				</CardBody>
			</Card>
			{/*--------------------------------------------------------------------------------*/}
			{/* End Action table*/}
			{/*--------------------------------------------------------------------------------*/}
		</div>
	}
}

export default Supervisor;
