import React, { useState } from 'react';
import ReactTable from "react-table";
import treeTableHOC from "react-table/lib/hoc/treeTable";
import {
  Card,
  CardBody,
  CardTitle,
  Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Row, Col,
  ButtonGroup,
} from 'reactstrap';
import "react-table/react-table.css";
import * as data from "../tables/reactable-data";
import Switch from "react-switch";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import CompanyMenu from '../companies/company-menu'
import avatar from '../../assets/images/users/3.jpg'
import Select, { components } from 'react-select'
import countryList from 'react-select-country-list'
// import { SelectTimezone } from 'react-select-timezone';
import TimezoneSelect from 'react-timezone-select'
import { auth } from '../../Api'
import ImageUploader from 'react-images-upload';
import { baseURL, baseImgURL } from '../../Api/BaseApi';
import { getName } from 'country-list';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const TreeTable = treeTableHOC(ReactTable);

export function CountryFlag(props) {
  return (
    <span
      className={"flag-icon flag-icon-" + props.code}
      style={{ fontSize: props.size || "30px" }}
    />
  );
}

export const CountryFlagSelectOption = props => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
        {props.label}
      </div>
    </components.Option>
  );
};

export const CountryFlagValueContainer = ({ children, ...props }) => {
  const code = (props.hasValue && props.getValue()[0].value) || false;

  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      {(code && <CountryFlag code={code.toLowerCase()} />) || null}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );
};

const styles = {
  valueContainer: (base, state) => {
    const height = "35px";
    return { ...base, height };
  }
};

class CompanyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      open: false,
      modal: false,
      showAlert: false,
      showResult: false,
      alertMessage: '',
      obj: {},
      jsonData: data.dataTable,
      data: data.makeData(),
      treedata: data.treedata,
      options: countryList().getData(),
      nameValue: null,
      emailValue: null,
      phoneValue: null,
      countryValue: null,
      password: '123456',
      center: {
        lat: 59.95,
        lng: 30.33
      },
      showAlert: false,
      showResult: false,
      zoom: 11,
      pictures: [],
      avatarUrl: '',
      resellerId: null
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    let resellerId = localStorage.getItem('resellerId')
    console.log("resellerId:", resellerId)
    this.setState({
      resellerId: resellerId
    })
    if (resellerId != null)
      auth.getReseller(resellerId)
        .then((res) => {
          if (res.data.code == 200) {
            let companyData = res.data.message.users[0];
            console.log("compmnayData:", companyData)
            this.setState({
              nameValue: companyData.name,
              emailValue: companyData.email,
              phoneValue: companyData.phonenumber,
              avatarUrl: companyData.avatar,
              countryValue: { value: companyData.countrycode, label: getName(companyData.countrycode) }
              // locationData:res.data.message.locations
            })
          }
        });
  }

  onDrop(picture) {
    var req = {}
    req.file = picture[0]
    console.log("req:", req);
    var that = this;
    auth.upload(req)
      .then((res) => {
        console.log("upload result:", res)
        if (res.data.code == 201) {
          console.log("upload result---01:", res)
          that.setState({

            avatarUrl: res.data.url
          })
          console.log("upload result---02:", that.state.avatar)
        }
      })
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  toggle() {
    this.setState({
      showAlert: !this.state.showAlert
    });
  }
  changeCountry = value => {
    this.setState({ countryValue: value });
  };
  changeTimezone = value => {
    this.setState({ timeValue: value });
  }
  onSave = (event) => {

    let { emailValue, phoneValue, countryValue, nameValue,
      password, avatarUrl, resellerId } = this.state
    this.setState({ showResult: false });
    console.log("emailValue", emailValue,
      "phoneValue", phoneValue,
      "countryValue", countryValue,
      "nameValue", nameValue,
      "password", password)
    var req = {}
    req.name = nameValue
    req.phonenumber = phoneValue
    req.email = emailValue
    req.password = password
    req.password_confirmation = password
    if (avatarUrl != "")
      req.avatar = avatarUrl
    if (countryValue != null) {
      req.countrycode = countryValue.value
    }
    req.level = "b"
    var that = this;
    if (resellerId != null) {
      req.id = resellerId
      auth.updateCompany(req)
        .then((res) => {
          console.log("auth.register's res:", res);
          let alertMessage = "";
          if (res.data.message != null) {
            if (res.data.code = 201) {
              console.log(["res.data.message:", res.data.message]);
              alertMessage = 'Updated Successfully'
              that.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
              })
            }

          }
        });
    }
    else {
      auth.register(req)
        .then((res) => {
          console.log("auth.register's res:", res);
          let alertMessage = "";
          if (res.data.message != null) {
            if (res.data.code == 400) {
              console.log("res.data.message.email:", res.data.message.email, res.data.message.email != null);
              if (res.data.message.email != null) {
                alertMessage += 'The email already exists.\n'
              }
              if (res.data.message.phonenumber != null) {
                alertMessage += 'This phone number already exists\n'
              }
              console.log(["res.data.message:", res.data.message, alertMessage]);
              that.setState({
                showAlert: true,
                showResult: false,
                alertMessage: alertMessage
              })
              console.log(["res.data.message:", alertMessage, that.state.alertMessage]);
            }
            else if (res.data.code = 201) {
              console.log(["res.data.message:", res.data.message]);
              alertMessage = 'Created Successfully'
              that.setState({
                showAlert: true,
                showResult: true,
                alertMessage: alertMessage
              })
            }

          }
        });
    }
    event.preventDefault();
  }

  setPhoneNumber = (event) => {
    console.log("event of phone:", event)
    this.setState({
      phoneValue: event
    })
  }

  handleChange = (event) => {
    console.log(["event.valu:", event.target.value, event.target.name])
    switch (event.target.name) {
      case "name":
        this.setState({
          nameValue: event.target.value
        })
        break;
      case "email":
        this.setState({
          emailValue: event.target.value
        })
        break;
      case "phone":
        this.setState({
          phoneValue: event.target.value
        })
        break;
      case "password":
        this.setState({
          password: event.target.value
        })
        break;
    }
  }

  render() {
    let { avatarUrl, resellerId } = this.state
    console.log("rese;;erId:", resellerId)
    return <div>
      <Card>
        {/* <CompanyMenu></CompanyMenu> */}
        <Row className="toolbar up-card-title">
          <div className="b-title"><i className="mr-2 mdi mdi-account-multiple-outline mr-10"></i>&nbsp;ReSeller Information</div>
        </Row>
        <Row className="toolbar up-card-box justify-content-center">
          <div className="col-md-3" style={{ textAlign: "center" }}>
            <img src={avatarUrl != "" ? baseImgURL + avatarUrl : avatar} className="img-company"></img>
            <Form>
              <FormGroup>
                {/* <Input type="file" name="file" id="exampleFile" onChange={this.onDrop} /> */}
                <ImageUploader
                  withIcon={false}
                  buttonText='Choose images'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  singleImage={true}
                />
              </FormGroup>
            </Form>
          </div>
          <form className="col-md-7" onSubmit={this.onSave}>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Name</label>
              <div className="col-md-9">
                <input className="location-text-input" name="name" type="text" value={this.state.nameValue} onChange={this.handleChange} required></input>
              </div>
            </Row>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Country</label>
              <div className="col-md-9">
                <Select
                  className="select-country"
                  styles={styles}
                  options={this.state.options}
                  value={this.state.countryValue}
                  onChange={this.changeCountry}
                  components={{
                    Option: CountryFlagSelectOption,
                    ValueContainer: CountryFlagValueContainer
                  }}
                />
              </div>
            </Row>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Email</label>
              <div className="col-md-9">
                <input className="location-text-input" name="email" type="text" value={this.state.emailValue} onChange={this.handleChange} required></input>
              </div>
            </Row>
            <Row className="company-text-area">
              <label className="col-md-3 control-label">Phone</label>
              <div className="col-md-9">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={this.state.phoneValue}
                  className='phonenumber-input'
                  onChange={this.setPhoneNumber} />
              </div>
            </Row>
            {resellerId != null ? <div></div> : <Row className="company-text-area">
              <label className="col-md-3 control-label">Password</label>
              <div className="col-md-9">
                <input className="location-text-input" name="password" type="text" value={this.state.password} onChange={this.handleChange} required></input>
              </div>
            </Row>}
            <div className="height-10"></div>
            <Row className="d-flex justify-content-center">
              <Button type="submit" value="Submit" className="saveButton" >Submit</Button>
            </Row>
            <div className="height-10"></div>
          </form>
        </Row>
      </Card>
      <Modal isOpen={this.state.showAlert} toggle={this.toggle}>
        {/* <ModalHeader toggle={this.alertToggle}>Alert Modal</ModalHeader> */}
        <ModalBody className="alert-body">
          <i className="mdi mdi-alert-circle ft-60"></i>
          <h2>{this.state.alertMessage}</h2>
          <div className="height-10"></div>
          <FormGroup>
            <Button color="secondary" style={{ width: '100px' }} className="ml-1" onClick={this.toggle}>Cancel</Button>
            <span>&nbsp;&nbsp;</span>
            <Button color="primary" style={{ width: '100px' }} onClick={this.toggle}>Ok</Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  }
}


export default CompanyView;
